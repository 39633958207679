import React from "react";
import faqBooks from "../../assets/faqBooks.png";
import bookWorm from "../../assets/bookWorm.png";
import { Accordion } from "@mantine/core";
import { IconPlus } from "@tabler/icons-react";
import { FaqsData } from "../../data/data";
import { useFaqStore } from "../../zustand/store/FaqsStore";
import { useShallow } from "zustand/react/shallow";
import { motion } from "framer-motion";

const FAQs: React.FC = () => {
  const [faqs] = useFaqStore(useShallow((state) => [state.faqs]));

  return (
    <motion.div
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      transition={{ duration: 1 }}
      id="faqs"
      className="container  mt-5 pt-5"
    >
      <motion.div
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{ duration: 1.5 }}
      >
        <img className="faq-image" src={faqBooks} alt="img" />
      </motion.div>
      <div className="faqs-heading my-4 mt-5  d-flex justify-content-center">
        <p className="py-3  px-5" id="faqs">
          FAQs
        </p>
      </div>
      <p className="text-center faqs-text">Preguntas frecuentes</p>
      <div className="faqs-box mb-5 pb-5">
        <Accordion
          chevron={<IconPlus size="1rem" />}
          // Mantine's Accordion uses `icon` instead of `chevron`
        >
          {faqs?.length > 0 &&
            faqs?.map((value: any, i: number) => {
              return (
                value?.isActive && (
                  <motion.div
                    initial={{ opacity: 0, x: -1 }}
                    whileInView={{ opacity: 1, x: 0 }}
                    transition={{ duration: 1 }}
                    key={i}
                  >
                    <Accordion.Item value={value?.question}>
                      <Accordion.Control>{value?.question}</Accordion.Control>
                      <Accordion.Panel>
                        <p>{value?.answer}</p>
                      </Accordion.Panel>
                    </Accordion.Item>
                  </motion.div>
                )
              );
            })}
        </Accordion>
      </div>

      {/* <div className="topper-girl d-flex justify-content-end">
        <img src={bookWorm} alt="img" />
      </div> */}
    </motion.div>
  );
};

export default FAQs;
