import { ReactNode } from "react";
import harryimg from "../assets/first-winner.png";
import SilverMedal from "../assets/medals/SilverMedal";
import hermioneimg from "../assets/second-winner.png";
import ronimg from "../assets/third-winner.png";
import question from "../assets/question.png";
import GoldMedal from "../assets/medals/GoldMedal";
import BronzeMedal from "../assets/medals/BronzeMedal";
import {
  ChatIcon,
  DashboardIcon,
  DollarSign,
  FaqIcon,
  LeaderboardIcon,
  QuizManagementIcon,
  SettingIcon,
} from "../assets/icons/Icons";
import { MdMessage } from "react-icons/md";

// Any static data you're going to use will be coded here...
export interface topWinners {
  name: string;
  img: string;
  medal: ReactNode;
  position: number;
}

export const topWinners: topWinners[] = [
  {
    name: "Dolores June",
    img: question,
    medal: <SilverMedal />,
    position: 2,
  },
  {
    name: "Airam Jonay",
    img: question,
    medal: <GoldMedal />,
    position: 1,
  },
  {
    name: "Beneharo Rayco",
    img: question,
    medal: <BronzeMedal />,
    position: 3,
  },
];

export interface FaqItem {
  question: string;
  answer: string;
}

export const FaqsData: FaqItem[] = [
  {
    question: "Q: ¿Cómo participo en los cuestionarios mensuales?",
    answer:
      "Para unirse a los cuestionarios mensuales, simplemente regístrese para obtener una cuenta gratuita. Una vez registrado, puede acceder y participar en los cuestionarios desde su panel de control.",
  },
  {
    question: "Q: ¿De qué tipo de comunidad estás hablando?",
    answer:
      'La "comunidad" a la que nos referimos es una comunidad en línea que proporciona recursos y herramientas educativos relacionados con las finanzas. Es un espacio accesible a personas de todos los ámbitos de la vida, desde quienes recién aprenden los conceptos básicos de finanzas hasta quienes tienen experiencia financiera.',
  },
  {
    question:
      "Q:¿Cómo aborda el proyecto las barreras para acceder a la educación financiera?",
    answer:
      "El proyecto se centra en llegar a quienes tradicionalmente han tenido menor acceso a los servicios financieros y a la educación financiera. Esto incluye personas de bajos ingresos, jóvenes, mujeres y comunidades marginadas. La plataforma ofrece recursos educativos gratuitos y herramientas de seguimiento para superar estas barreras y fomentar la inclusión financiera.",
  },
  {
    question: "Q:¿Cuáles son los recursos educativos que ofrece la plataforma?",
    answer:
      "La plataforma proporciona recursos educativos que cubren una amplia gama de temas financieros, desde conceptos básicos hasta temas más avanzados. Estos pueden incluir tutoriales, artículos, videos y otras formas de contenido que ayuden a las personas a comprender y aplicar los principios financieros en su vida diaria.",
  },
  {
    question:
      "Q: ¿Cuáles son los recursos educativos que ofrece la plataforma?",
    answer:
      "La plataforma proporciona recursos educativos que cubren una amplia gama de temas financieros, desde conceptos básicos hasta temas más avanzados. Estos pueden incluir tutoriales, artículos, videos y otras formas de contenido que ayuden a las personas a comprender y aplicar los principios financieros en su vida diaria.",
  },
];

export interface TopTenItem {
  position: number;
  name: string;
}

export const TopTen: TopTenItem[] = [
  {
    position: 1,
    name: "John Doe",
  },
  {
    position: 2,
    name: "John Doe",
  },
  {
    position: 3,
    name: "John Doe",
  },
  {
    position: 4,
    name: "John Doe",
  },
  {
    position: 5,
    name: "John Doe",
  },
  {
    position: 6,
    name: "John Doe",
  },
  {
    position: 7,
    name: "John Doe",
  },
  {
    position: 8,
    name: "John Doe",
  },
  {
    position: 9,
    name: "John Doe",
  },
  {
    position: 10,
    name: "John Doe",
  },
];

export interface QuizRecord {
  id: string;
  name: string;
  quiz: string;
  date: string;
  position: string;
}

export const MyQuizRecord: QuizRecord[] = [
  {
    id: "1",
    name: "John Doe",
    quiz: "Breeze Sodium",
    date: "11/12/2023",
    position: "1st",
  },
  {
    id: "2",
    name: "John Doe",
    quiz: "Breeze Sodium",
    date: "11/12/2023",
    position: "1st",
  },
  {
    id: "3",
    name: "John Doe",
    quiz: "Breeze Sodium",
    date: "11/12/2023",
    position: "1st",
  },
  {
    id: "4",
    name: "John Doe",
    quiz: "Breeze Sodium",
    date: "11/12/2023",
    position: "1st",
  },
  {
    id: "5",
    name: "John Doe",
    quiz: "Breeze Sodium",
    date: "11/12/2023",
    position: "1st",
  },
  {
    id: "6",
    name: "John Doe",
    quiz: "Breeze Sodium",
    date: "11/12/2023",
    position: "1st",
  },
  {
    id: "7",
    name: "John Doe",
    quiz: "Breeze Sodium",
    date: "11/12/2023",
    position: "1st",
  },
  {
    id: "8",
    name: "John Doe",
    quiz: "Breeze Sodium",
    date: "11/12/2023",
    position: "1st",
  },
  {
    id: "9",
    name: "John Doe",
    quiz: "Breeze Sodium",
    date: "11/12/2023",
    position: "1st",
  },
  {
    id: "10",
    name: "John Doe",
    quiz: "Breeze Sodium",
    date: "11/12/2023",
    position: "1st",
  },
];

export interface DashboardLink {
  id: string;
  label: string;
  path: string;
  icon: any;
}
export const dashboardLinks: DashboardLink[] = [
  {
    id: "01",
    label: "Panel",
    path: "/dashboard",
    icon: <DashboardIcon />,
  },
  {
    id: "02",
    label: "Gestión de cursos",
    path: "/dashboard/course-management",
    icon: <QuizManagementIcon />,
  },
  {
    id: "03",
    label: "Gestión de liderazgo",
    path: "/dashboard/leaderboard-management",
    icon: <LeaderboardIcon />,
  },
  {
    id: "04",
    label: "Gestión de transacciones",
    path: "/dashboard/transaction-history",
    icon: <DollarSign />,
  },
  {
    id: "05",
    label: "FAQs",
    path: "/dashboard/faqs",
    icon: <FaqIcon />,
  },
  {
    id: "06",
    label: "Chat History",
    path: "/dashboard/chat-history",
    icon: <ChatIcon />,
  },
  {
    id: "07",
    label: "Configuración del sitio",
    path: "/dashboard/website-setting",
    icon: <SettingIcon />,
  },
];

export interface DashboardCard {
  id: string;
  topHeading: string;
  mainText: string;
  subMainText: string;
  bottomDesc: string;
}
export const dashboardCard: DashboardCard[] = [
  {
    id: "01",
    topHeading: "Courses Completed",
    mainText: "67",
    subMainText: "%",
    bottomDesc: "Mastering courses: 67% done",
  },
  {
    id: "02",
    topHeading: "Quiz Mastery",
    mainText: "82",
    subMainText: "%",
    bottomDesc: "Quiz brilliance: Averaging 82%",
  },
  {
    id: "03",
    topHeading: "Learning Hours",
    mainText: "15",
    subMainText: "Hrs",
    bottomDesc: "Dedicated learning: 15 hours invested",
  },
  {
    id: "04",
    topHeading: "Leaderboard Rank",
    mainText: "5",
    subMainText: "Th",
    bottomDesc: "Mastering courses: 67% done",
  },
];

export interface QuizQuestion {
  id: number;
  question: string;
  answer?: string;
}
export const quizQuestion: QuizQuestion[] = [
  {
    id: 1,
    question: "Name the Nitrogenous bases of (Deoxy-ribos nucleic acid) DNA?",
  },
  {
    id: 2,
    question: "Write the symptoms of osteoporosis?",
  },
  {
    id: 3,
    question: "Write the function of haemoglobin?",
  },
  {
    id: 4,
    question: "Why liver is regarded as metabolic factory?",
  },
  {
    id: 5,
    question: "Write the function of the umbilical cord??",
  },
];

export const pageContentData: any[] = [
  {
    home: {
      tu_destino:
        "Tu destino. ¡Únase a nosotros en esta emocionante aventura hacia una educación financiera más amigable para toda nuestra comunidad!",
      listo_para: "¿Listo para mejorar tus conocimientos financieros?",
      empiece_el: "¡Empiece el cuestionario ahora!",
      home_bg: "/uploads/homeBG.png",
      about_us:
        "En 'Your Way', estamos cambiando la forma en que aprendes finanzas. Únase a nosotros para una viaje emocionante, desafíos y recompensas. No más horas aburridas, solo una experiencia inmersiva. Manténgase actualizado sobre tendencias, consejos criptográficos y más. No somos sólo una plataforma; somos un movimiento por una vida más amigable educación financiera. Únase a 'Your Way' ahora y adopte una forma más inteligente para administrar tu dinero! Tu empoderamiento financiero comienza aquí.",
      about_us_img: "/uploads/homeBG.png",
    },
    leaderBoard: {
      explore_la:
        "Explore la tabla de clasificación y vea dónde se ubica entre los financieros entusiastas. Eleva tu posición y apunta a la cima",
      home_bg: "/uploads/leaderboardBg.png",
      leader_heading1: "LÍDER",
      leader_heading2: "JUNTA",
      no_result_heading: "No Se Han Encontrado Resultados",
      no_result_text:
        "¡Aún No Hay Ningún Récord En La Tabla De Clasificación En Este Cuestionario!",
    },
    myQuiz: {
      my_quiz11: "MI",
      my_quiz12: "MI",
      my_quiz2: "PRUEBA",
      no_result_heading: "No Se Han Encontrado Resultados",
      no_result_text:
        "¡Aún No Hay Ningún Récord En La Tabla De Clasificación En Este Cuestionario!",
    },
    quiz_detail: {
      no_result_heading: "No Se Han Encontrado Resultados",
      no_result_text:
        "¡Aún No Hay Ningún Récord En La Tabla De Clasificación En Este Cuestionario!",
    },
    usdt_exchange: {
      header_bg: "No Se Han Encontrado Resultados",
      header_text:
        "¡Aún No Hay Ningún Récord En La Tabla De Clasificación En Este Cuestionario!",
      buy_usdt: "buy it",
      ref_price: "hello :123,878,3988787usdt",
      explore_usdt: "explore",
      explore_text: "hjhjkj",
      explore_img: "jkjhk",
      what_the_say: "kjhkj",
      what_the_say_bold_text: "kjhkj",
      what_the_say_text: "kjhkj",
      quote: "jhkhjhk",
    },
    profileSetting: {
      footer_about:
        "Transformar la educación financiera en un viaje inmersivo. Aprender, juegue y gane con nuestro enfoque gamificado para construir finanzas literatura. Únase a nosotros para remodelar su forma de ver y comprender finanzas para un futuro financiero más brillante.",
      footer_email: "contact@yourway.com",
      footer_number: "+000 123 1456 789",
    },
    footer: {
      footer_about:
        "Transformar la educación financiera en un viaje inmersivo. Aprender, juegue y gane con nuestro enfoque gamificado para construir finanzas literatura. Únase a nosotros para remodelar su forma de ver y comprender finanzas para un futuro financiero más brillante.",
      footer_email: "contact@yourway.com",
      footer_number: "+000 123 1456 789",
    },
    websiteSetting: {
      logo: "/uploads/leaderboardBg.png",
      favicon: "/uploads/leaderboardBg.png",
      websiteName: "your way",
      websitedesc: "your way",
      instagram: "your way",
      linkedin: "your way",
      twitter: "your way",
    },
  },
];
