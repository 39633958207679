import React, { useEffect } from "react";
import io from "socket.io-client";
import { baseURL } from "../../configs/axiosConfig";
import { getUserData, useAuthentication } from "../../services/hooks";

const CryptoCurrencyTable: React.FC = () => {
  const isAuthentication = useAuthentication();
  const user = getUserData();
  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "https://s3.tradingview.com/external-embedding/embed-widget-screener.js";
    script.async = true;
    script.innerHTML = `
          {
            
            "width": "100%",
            "height": "100%",
            "defaultColumn": "overview",
            "screener_type": "crypto_mkt",
            "displayCurrency": "BTC",
            "showToolbar":true,
            "colorTheme": "light",
            "locale": "es"
          }
        `;

    document.getElementById("tradingview-widget-script")?.appendChild(script);
  }, []);

  // useEffect(() => {
  //   const socket = io(baseURL);
  //   const widgetHtml = document.getElementById("crypto-widget");
  //   // alert(isAuthentication);
  //   const myFunction = () => {
  //     if (isAuthentication === true) {
  //       socket.emit("join", user?._id);
  //       alert("join hai");
  //     }
  //     socket.on("reconnect", () => {
  //       if (isAuthentication) {
  //         socket.emit("join", user?._id);
  //         alert("join hai");
  //         // console.log(".......join hai");
  //       } else {
  //         alert("join nahi hai");
  //         console.log("join nahi hai");
  //       }
  //     });
  //     socket.emit("clientMessage", widgetHtml);
  //   };

  //   const scheduleFunctionAtSpecificTime = (
  //     hour: number,
  //     minute: number,
  //     second: number,
  //     func: () => void
  //   ) => {
  //     const now = new Date();
  //     const scheduledTime = new Date(
  //       now.getFullYear(),
  //       now.getMonth(),
  //       now.getDate(),
  //       hour,
  //       minute,
  //       second
  //     );

  //     let delay = scheduledTime.getTime() - now.getTime();
  //     if (delay < 0) {
  //       // If the scheduled time has already passed for today,
  //       // schedule it for tomorrow
  //       scheduledTime.setDate(scheduledTime.getDate() + 1); // Next day
  //       delay = scheduledTime.getTime() - now.getTime();
  //     }

  //     setTimeout(func, delay);
  //   };

  //   // Schedule the function to run at 10:00 AM
  //   scheduleFunctionAtSpecificTime(10, 0, 0, myFunction);

  //   // Event listener for receiving messages from the server
  //   socket.on("serverMessage", (message) => {
  //     console.log("Received message from server:", message);
  //   });

  //   // Clean up function
  //   return () => {
  //     socket.disconnect();
  //   };
  // }, []);

  return (
    <div className="container bg-white" id="crypto-widget">
      {/* TradingView Widget BEGIN */}
      <div className="tradingview-widget-container">
        <div className="tradingview-widget-container__widget"></div>
        <div id="tradingview-widget-script" style={{ height: "100vh" }}></div>
      </div>
      {/* TradingView Widget END */}
    </div>
  );
};

export default CryptoCurrencyTable;

export const TradingViewTickerTape: React.FC = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "https://s3.tradingview.com/external-embedding/embed-widget-ticker-tape.js";
    script.async = true;
    script.innerHTML = `
      {
        "symbols": [
          {
            "proName": "FOREXCOM:SPXUSD",
            "title": "S&P 500"
          },
          {
            "proName": "FOREXCOM:NSXUSD",
            "title": "US 100"
          },
          {
            "proName": "FX_IDC:EURUSD",
            "title": "EUR to USD"
          },
          {
            "proName": "BITSTAMP:BTCUSD",
            "title": "Bitcoin"
          },
          {
            "proName": "BITSTAMP:ETHUSD",
            "title": "Ethereum"
          }
        ],
        "showSymbolLogo": true,
        "isTransparent": false,
        "displayMode": "adaptive",
        "colorTheme": "light",
        "locale": "en"
      }
    `;
    document.getElementById("tradingview-ticker-tape")?.appendChild(script);
    // document.body.appendChild(script);

    // return () => {
    //   document.body.removeChild(script);
    // };
  }, []);

  return <div id="tradingview-ticker-tape" />;
};
