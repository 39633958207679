import { create } from "zustand";
import custAxios, { attachToken } from "../../configs/axiosConfig";
import { notifications } from "@mantine/notifications";

interface IUserStore {
  loading: boolean;
  sendNewsLetter: (value: any) => Promise<any>;
}

export const useNewsletterStore = create<IUserStore>((set) => ({
  loading: false,

  sendNewsLetter: async (value) => {
    try {
      set({
        loading: true,
      });

      attachToken();
      const response = await custAxios.post(`/newsletter/`, value);

      set({
        loading: false,
      });
      notifications.show({
        title: "suscrito exitosamente",
        message: "Recibirás las últimas noticias y actualizaciones.",
        autoClose: 5000,
        color: "yellow",
      });
      return "success";
    } catch (error) {
      set({
        loading: false,
      });
      notifications.show({
        title: "suscrito sin éxito",
        message: "Ya te has suscrito, usa otro correo electrónico.",
        autoClose: 5000,
        color: "red",
      });
      console.error("Error fetching data:", error);
    }
  },
}));
