import { Text, TextInput } from "@mantine/core";
import React, { useEffect, useRef, useState } from "react";
import { useShallow } from "zustand/react/shallow";
import { useAiChatStore } from "../../zustand/store/ChatStore";
import { errorMessage } from "../../services/helpers";

const SetChatContext: React.FC = () => {
  const { addTags, getTags, deleteTags, tags, loading, addTagLoader } =
    useAiChatStore(useShallow((state) => state));

  const inputRef = useRef<HTMLInputElement>(null);
  const [inputValue, setInputValue] = useState("");

  const handleAddTag = async (e: any, type: string) => {
    console.log(e.target);

    if (
      type === "enter" &&
      e.target.value?.trim() !== "" &&
      inputValue?.trim() !== ""
    ) {
      if (e.keyCode === 13) {
        await addTags(e.target.value);
        setInputValue("");
        await getTags();
      }
    } else if (
      type === "button" &&
      e.target.value?.trim() !== "" &&
      inputValue?.trim() !== ""
    ) {
      await addTags(e.target.value);
      setInputValue("");
      await getTags();
    } else {
      errorMessage("Please enter tag value");
    }
  };

  const handleDeleteTag = async (id: string) => {
    await deleteTags(id);
    await getTags();
  };

  useEffect(() => {
    getTags();
  }, []);
  console.log("input value...", inputValue);

  return (
    <div className="m-3">
      <div>
        <p className="context-title">Contexto del chat</p>
        <Text className="context-description ">
          ¡Agrega etiquetas relacionadas con tus temas de interés! Estas
          etiquetas ayudarán a personalizar la experiencia al limitar el enfoque
          de la conversación. por ejemplo (criptomoneda, viajes, comida, etc.)
        </Text>
      </div>

      <div className="input-box">
        <TextInput
          // ref={inputRef}
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          size="lg"
          type="text"
          placeholder="Introducir etiqueta"
          className=" input-tag"
          onKeyDown={(e) => handleAddTag(e, "enter")}
          rightSection={
            <div
              className="yellow-btn cursor-pointer"
              onClick={(e) => handleAddTag(e, "button")}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                className="icon icon-tabler icons-tabler-outline icon-tabler-plus"
                viewBox="0 0 24 24"
              >
                <path stroke="none" d="M0 0h24v24H0z"></path>
                <path d="M12 5v14M5 12h14"></path>
              </svg>
            </div>
          }
        />
      </div>
      <div className="tags-box">
        <p className="tag-heading">Todas las etiquetas:</p>
        <div className="all-tags">
          {tags?.map((val: any) => (
            <div className="single-tag">
              <p className="mb-0">{val?.keyword}</p>
              <svg
                onClick={() => handleDeleteTag(val?._id)}
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                className="cursor-pointer icon icon-tabler icons-tabler-outline icon-tabler-x"
                viewBox="0 0 24 24"
              >
                <path stroke="none" d="M0 0h24v24H0z"></path>
                <path d="M18 6L6 18M6 6l12 12"></path>
              </svg>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SetChatContext;
