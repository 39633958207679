import { create } from "zustand";
import custAxios from "../../configs/axiosConfig";
import { notifications } from "@mantine/notifications";
import { errorMessage, successMessage } from "../../services/helpers";

interface IAuthStore {
  user: any;
  isAuthenticated: boolean;
  loading: boolean;
  register: (values: any, set?: (value: object) => void) => Promise<any>;
  loginFunc: (values: any) => Promise<any>;
  logoutFunc: () => Promise<any>;
  forgotPasswordFunc: (values: any) => Promise<any>;
  resetPasswordFunc: (values: any) => Promise<any>;
}

const registerFunc = async (values: any, set: (value: object) => void) => {
  try {
    set({
      loading: true,
    });
    const response = await custAxios.post("/auth/register", values);

    set({
      loading: false,
    });
    notifications.show({
      title: "Su cuenta se registró exitosamente",
      message: "Ahora puede iniciar sesión con su cuenta registrada",
      autoClose: 5000,
      color: "yellow",
    });
    return response.data;
  } catch (error) {
    notifications.show({
      title: "Registro fallido",
      message: "correo ya existe",
      autoClose: 5000,
      color: "red",
    });
    set({
      loading: false,
    });
    console.error("Error fetching data:", error);
  }
};

export const useAuthStore = create<IAuthStore>((set) => {
  const userData: any = localStorage.getItem("user");
  const token = localStorage.getItem("token");
  return {
    user: token && userData ? { token: token, userData: userData } : null,
    isAuthenticated: token ? true : false,
    loading: false,

    register: (values) => registerFunc(values, set),

    loginFunc: async (values) => {
      try {
        set({
          loading: true,
        });
        const response = await custAxios.post("/auth/login", values);

        set({
          user: response?.data?.data?.userData,
          loading: false,
          isAuthenticated: true,
        });

        localStorage.setItem(
          "user",
          JSON.stringify(response?.data?.data?.userData)
        );
        localStorage.setItem("token", response?.data?.data?.token);
        return response.data;
      } catch (error) {
        notifications.show({
          title: "Credenciales no válidas",
          message:
            "Tu correo electrónico o contraseña pueden estar incorrectos",
          autoClose: 5000,
          color: "red",
        });
        set({
          loading: false,
        });
      }
    },
    forgotPasswordFunc: async (values) => {
      try {
        set({
          loading: true,
        });
        const res = await custAxios.post("/auth/forgotPassword", values);
        if (res?.data?.success) {
          set({
            loading: false,
            isAuthenticated: true,
          });
        }
        successMessage(res?.data?.data);
        return res;
      } catch (error: any) {
        set({
          loading: false,
        });
        errorMessage(error?.response?.data?.message);
      }
    },
    resetPasswordFunc: async (values) => {
      try {
        set({
          loading: true,
        });
        const res = await custAxios.put("/auth/resetPassword", values);
        if (res?.data?.success) {
          set({
            loading: false,
            isAuthenticated: true,
          });
        }
        successMessage(res?.data?.data);
        return res;
      } catch (error: any) {
        set({
          loading: false,
        });
        errorMessage(error?.response?.data?.message);
      }
    },

    logoutFunc: async () => {
      try {
        set({
          loading: true,
        });
        const response = await custAxios.get("/auth/logout");

        set({
          user: response?.data?.data?.userData,
          loading: false,
          isAuthenticated: false,
        });

        localStorage.removeItem("user");
        localStorage.removeItem("token");
        return "success";
      } catch (error) {
        set({
          loading: false,
        });
        console.error("Error fetching data:", error);
      }
    },
  };
});
