import React from "react";
import { Navigate } from "react-router-dom";
import { UseGetRole, useAuthentication } from "../services/hooks";

interface AdminRouteProps {
  Component: React.ComponentType<any>;
}

const AdminRoute: React.FC<AdminRouteProps> = ({
  Component,
}: AdminRouteProps) => {
  const isAuthenticated: boolean = useAuthentication();
  const role: string = UseGetRole();

  if (!isAuthenticated) {
    return <Navigate to="/" />;
  } else {
    if (role === "admin") {
      return <Component />;
    } else {
      return <Navigate to="/not-found" />;
    }
  }
};

export default AdminRoute;
