import { create } from "zustand";
import custAxios, {
  attachToken,
 
} from "../../configs/axiosConfig";

interface IUserStore {
  transcript: any;
  loading: boolean;
  getTranscript: (value: any) => Promise<any>;
}

export const useTranscriptStore = create<IUserStore>((set) => ({
  transcript: {},

  loading: false,

  getTranscript: async (value) => {
    try {
      set({
        loading: true,
      });

      attachToken();
      const response = await custAxios.post(
        "https://youtube-api-python-production-70b1.up.railway.app/",
        value
      );
     

      set({
        loading: false,
        transcript: response?.data,
      });
      return response.data;
    } catch (error) {
      set({
        loading: false,
        transcript: {},
      });
      console.error("Error fetching data:", error);
    }
  },
}));
