import { create } from "zustand";
import custAxios, { attachToken } from "../../configs/axiosConfig";
import { successMessage } from "../../services/helpers";
interface ISendMsg {
  chatId: string | null;
  prompt: string;
}

interface IPayload {
  page: number;
  limit: number;
  date?: Date | null;
  ip?: string;
}

interface ITags {
  keyword: string;
}

interface IChatStore {
  chatId: string | null;
  aiResponse: string;
  loading: boolean;
  addTagLoader: boolean;
  chatCount: number;
  MesssagesCount: number;
  pages: number;
  chats: [];
  tags: [];
  chat: any;
  sendChat: (values: ISendMsg) => Promise<any>;
  fetchChats: (values: IPayload) => Promise<any>;
  fetchMessages: (id: string) => Promise<any>;
  addTags: (value?: ITags) => Promise<any>;
  getTags: () => Promise<any>;
  deleteTags: (id: string) => Promise<any>;
}

export const useAiChatStore = create<IChatStore>((set) => ({
  chatId: null,
  chats: [],
  tags: [],
  chat: {},
  loading: false,
  addTagLoader: false,
  aiResponse: "",
  chatCount: 0,
  MesssagesCount: 0,
  pages: 0,

  sendChat: async (values) => {
    try {
      set({
        loading: true,
      });
      const res = await custAxios.post("/chat/add", values);

      if (res?.data?.success) {
        set({
          loading: false,
          chatId: res?.data?.data?.chatId,
          aiResponse: res?.data?.data?.aiResponse,
        });
        return res?.data?.data;
      }
    } catch (error) {
      set({
        loading: false,
      });
      console.error("Error fetching data:", error);
    }
  },

  fetchChats: async (values) => {
    try {
      set({
        loading: true,
      });
      attachToken();
      const res = await custAxios.get("/chat", { params: values });
      if (res?.data?.success) {
        const pages = Math.ceil(res.data?.data?.docCount / values.limit);
        set({
          loading: false,
          chats: res?.data?.data?.chats,
          chatCount: res?.data?.data?.docCount,
          pages: pages,
        });
        return true;
      }
    } catch (error) {
      set({
        loading: false,
      });
      console.error("Error fetching data:", error);
    }
  },

  fetchMessages: async (id) => {
    try {
      set({
        loading: true,
      });
      attachToken();
      const res = await custAxios.get("/chat/single", { params: { ip: id } });
      if (res?.data?.success) {
        set({
          loading: false,
          chat: res?.data?.data?.chat,
        });
        return true;
      }
    } catch (error) {
      set({
        loading: false,
      });
      console.error("Error fetching data:", error);
    }
  },

  addTags: async (value) => {
    try {
      set({
        addTagLoader: true,
      });
      attachToken();
      const res = await custAxios.post("/chat/add-tag", { keyword: value });
      if (res?.data?.success) {
        set({
          addTagLoader: false,
        });
        successMessage(res?.data?.data?.message);
        return true;
      }
    } catch (error) {
      set({
        addTagLoader: false,
      });
      console.error("Error fetching data:", error);
    }
  },

  getTags: async () => {
    try {
      set({
        loading: true,
      });
      attachToken();
      const res = await custAxios.get("/chat/get-tags");
      if (res?.data?.success) {
        set({
          loading: false,
          tags: res?.data?.data?.tags,
        });
        return true;
      }
    } catch (error) {
      set({
        loading: false,
      });
      console.error("Error fetching data:", error);
    }
  },

  deleteTags: async (id) => {
    try {
      set({
        loading: true,
      });
      attachToken();
      const res = await custAxios.delete(`/chat/remove-tag/${id}`);
      if (res?.data?.success) {
        set({
          loading: false,
        });
        successMessage(res?.data?.data?.message);
        return true;
      }
    } catch (error) {
      set({
        loading: false,
      });
      console.error("Error fetching data:", error);
    }
  },
}));
