import {
  Checkbox,
  Loader,
  PasswordInput,
  TextInput,
  Image,
} from "@mantine/core";
import React from "react";
import signIn from "../../assets/signin.png";
import logo from "../../assets/logo.png";
import registerImg from "../../assets/register.png";
import { useAuth } from "../../services/hooks";
import { useForm } from "@mantine/form";
import { useAuthStore } from "../../zustand/store/AurthStore";
import { useShallow } from "zustand/react/shallow";
import GeneralModal from "../modal/GeneralModal";
import { useDisclosure } from "@mantine/hooks";
import TermsAndConditions from "./TermsAndConditions";
import { motion } from "framer-motion";
import { useSettingStore } from "../../zustand/store/SettingStore";
import { baseURL } from "../../configs/axiosConfig";

const Register: React.FC = () => {
  const { switchForm } = useAuth();
  const [register, loading] = useAuthStore(
    useShallow((state) => [state.register, state.loading])
  );
  const [setting] = useSettingStore(useShallow((state) => [state.setting]));
  const [opened, { open, close }] = useDisclosure();

  const form = useForm({
    initialValues: {
      name: "",
      email: "",
      password: "",
      confirmPassword: "",
      emailSubscription: true,
    },

    // functions will be used to validate values at corresponding key
    validate: {
      name: (value) =>
        value.length < 3 ? "Name must have at least 3 letters" : null,
      email: (value) => (/^\S+@\S+$/.test(value) ? null : "Invalid email"),
      password: (value) => (!value ? "You must have 8 characters" : null),
      confirmPassword: (value, values) =>
        value !== values.password ? "Passwords did not match" : null,
    },
  });

  const handleSubmit = async (values: any) => {
    const formValue = {
      name: values?.name,
      email: values?.email?.toLowerCase(),
      password: values?.confirmPassword,
      emailSubscription: values?.emailSubscription,
    };

    const res = await register(formValue);

    if (res?.success === true) {
      form.reset();
      switchForm("login");
    }
  };
  return (
    <motion.div
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      transition={{ duration: 1 }}
      className="signup-conatiner "
    >
      <div className="row ">
        <div className="col-md-12 col-lg-6">
          <div className="register-img-box ">
            <Image
              
              src={baseURL + setting[0]?.websiteSetting?.logo || logo}
              w={100}
              h={100}
              alt="logo"
              
              fallbackSrc={logo}
            />
          </div>
        </div>
        <div className="col-md-12 col-lg-6">
          <form onSubmit={form.onSubmit(handleSubmit)}>
            <p className="register-title">
              FIRMAR <span>ARRIBA</span>
            </p>
            <div>
              <label className="form-label ">
                <p className="form-label-text mb-2">Nombre completo</p>
                <TextInput size="md" {...form.getInputProps("name")} />
              </label>
            </div>
            <div>
              <label className="form-label mt-2">
                <p className="form-label-text mb-2">Correo electrónico</p>
                <TextInput size="md" {...form.getInputProps("email")} />
              </label>
            </div>
            <div>
              <label className="form-label mt-2">
                <p className="form-label-text mb-2">Contraseña</p>
                <PasswordInput size="md" {...form.getInputProps("password")} />
              </label>
            </div>
            <div>
              <label className="form-label mt-2">
                <p className="form-label-text mb-2">
                  Vuelva a escribir la contraseña
                </p>
                <PasswordInput
                  size="md"
                  {...form.getInputProps("confirmPassword")}
                />
              </label>
            </div>
            {/* <div className="d-flex justify-content-center align-items-center mt-4">
              <Checkbox
                defaultChecked
                color="yellow"
                // variant="outline"
                size="md"
              />
              <p className="ms-2 mb-0 checkout-text">
                acepté todo <span> Términos y condiciones.</span>
              </p>
            </div> */}
            <div className="d-flex justify-content-center align-items-center my-2 ">
              <Checkbox
                checked={form.values.emailSubscription}
                color="yellow"
                size="md"
                {...form.getInputProps("emailSubscription")}
              />
              <p className="ms-2 mb-0 checkout-text">
                Acepta los{" "}
                <span className="cursor-pointer" onClick={open}>
                  términos y condiciones{" "}
                </span>{" "}
                y recibe actualizaciones y ofertas de vez en cuando. Usted puede
                optar por salir en cualquier momento.
                {/* </span> */}
              </p>
            </div>
            <button
              disabled={loading ? true : false}
              type="submit"
              className="py-2 create-account text-uppercase"
            >
              {loading ? (
                <Loader size={"sm"} color="white" />
              ) : (
                " Crea tu cuenta"
              )}
            </button>
            <p className="checkout-text text-center my-3 ">
              Ya tienes una cuenta{" "}
              <span
                className="cursor-pointer"
                onClick={() => switchForm("login")}
              >
                {" "}
                Iniciar sesión
              </span>
            </p>
          </form>
        </div>
      </div>
      <GeneralModal
        opened={opened}
        loading={true}
        title={"terms and conditions"}
        Component={TermsAndConditions}
        openModal={open}
        closeModal={close}
        size={"70%"}
      />
    </motion.div>
  );
};

export default Register;
