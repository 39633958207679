import { Loader, TextInput, Textarea } from "@mantine/core";

import { useForm } from "@mantine/form";
import React, { FC, useEffect } from "react";
import { useShallow } from "zustand/react/shallow";
import { useFaqStore } from "../../zustand/store/FaqsStore";
import { useLocation, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
const FaqForm: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [faqsCreate, editFaq, loading] = useFaqStore(
    useShallow((state) => [state.createFaq, state.editFaq, state.loading])
  );

  const form = useForm({
    initialValues: {
      question: "",
      answer: "",
    },
    validate: {
      question: (value) => (!value ? "Introduce el título" : null),
      answer: (value) => (!value ? "Introduzca la fecha de inicio" : null),
    },
  });
  const submitHandler = async (values: any) => {
    if (location?.state) {
      try {
        const res = await editFaq(values, location?.state?._id);
      } catch (error) {}
    } else {
      try {
        const res = await faqsCreate(values);
        if (res) {
          navigate("/dashboard/faqs");
        }
      } catch (error) {}
    }
  };

  useEffect(() => {
    if (location?.state) {
      form.setValues({
        question: location?.state?.question,
        answer: location?.state?.answer,
      });
    }
  }, []);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      transition={{ duration: 1 }}
      className="my-4 mx-3"
    >
      <form onSubmit={form.onSubmit(submitHandler)}>
        <div className="row mt-3"></div>
        <div className="course-field mt-3">
          <label className="w-100">
            <p className="course-form-lable mb-2">Pregunta</p>
            <TextInput
              size="sm"
              placeholder="Pregunta"
              {...form.getInputProps("question")}
            />
          </label>
        </div>

        <div className="course-field mt-3">
          <label className="w-100">
            <p className="course-form-lable mb-2">Respuesta</p>
            <Textarea
              size="sm"
              placeholder="Respuesta"
              autosize
              minRows={7}
              {...form.getInputProps("answer")}
            />
          </label>
        </div>

        <div className="mt-3 d-flex justify-content-end ">
          <button
            type="submit"
            className=" publish-btn text-uppercase yellow-btn px-4 py-2"
          >
            {loading ? <Loader size={"sm"} color="white" /> : "Publicar"}
          </button>
        </div>
      </form>
    </motion.div>
  );
};

export default FaqForm;
