import React from "react";
import profile from "../../assets/profile.png";
import { baseURL } from "../../configs/axiosConfig";
import { Image } from "@mantine/core";
import { motion } from "framer-motion";
const QuizDetailCard: React.FC<{ data: any; marks: any }> = ({
  data,
  marks,
}) => {
  // alert(data?.user?.name);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      transition={{ duration: 1 }}
      className=" row detail-box align-content-center mx-0"
    >
      <div className="col-md-4 col-lg-3 d-flex justify-content-center align-content-center">
        <div className="py-4 d-flex justify-content-center align-items-center flex-column">
          <Image
            style={{ width: "100px" }}
            className="profile-pic"
            width={"100px"}
            height={"100px"}
            src={
              data?.user?.profilePic
                ? baseURL + data?.user?.profilePic
                : profile
            }
            fallbackSrc={`https://placehold.co/400x400/orange/white?text=${data?.user?.name}`}
            alt="profile"
          />
          <p className="text-center profile-name mb-0 mt-2">
            {data?.user?.name}
          </p>
        </div>
      </div>
      <div className="col-12 col-md-4 col-lg-3 border-left ">
        <div className="py-4 info-box">
          <p className="label-text mb-2">Nombre del cuestionario</p>
          <p className="detail-text mb-0">{data?.quiz?.title}</p>
        </div>
      </div>
      <div className="col-12 col-md-4 col-lg-2 border-left ">
        <div className="py-4 info-box">
          <p className="label-text mb-2">Pregunta total</p>
          <p className="detail-text mb-0">{data?.answers?.length}</p>
        </div>
      </div>
      <div className="col-6 col-md-4 col-lg-1 border-left ">
        <div className="py-4 info-box">
          <p className="label-text mb-2">Correcta</p>
          <p className="detail-text mb-0">{marks.correct}</p>
        </div>
      </div>
      <div className="col-6 col-md-4 col-lg-1 border-left ">
        <div className="py-4 info-box">
          <p className="label-text mb-2">incorrecta</p>
          <p className="detail-text mb-0">{marks.incorrect}</p>
        </div>
      </div>

      <div className="col-12 col-md-4 col-lg-2 border-left ">
        <div className="py-4 info-box">
          <p className="label-text mb-2">Notas totales</p>
          <p className="detail-text mb-0">{marks.correct}</p>
        </div>
      </div>
    </motion.div>
  );
};
export default QuizDetailCard;
