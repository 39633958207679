import React, { useEffect, useState } from "react";
import { useShallow } from "zustand/react/shallow";
import { useDashboardStore } from "../../zustand/store/DashboardStore";
import { Image, Loader, Pagination } from "@mantine/core";
import GeneralTable from "../../components/table/GeneralTable";
import { Link } from "react-router-dom";
import moment from "moment";
import { baseURL } from "../../configs/axiosConfig";
import profile from "../../assets/profile.png";
import { motion } from "framer-motion";
const Dashboard: React.FC = () => {
  const [
    stats,
    dashboardStats,
    dashboardUnChecked,
    unChecked,
    dashboardQuizRank,
    quizRank,
    loading,
  ] = useDashboardStore(
    useShallow((state) => [
      state.stats,
      state.dashboardStats,
      state.dashboardUnChecked,
      state.unChecked,
      state.dashboardQuizRank,
      state.quizRank,
      state.loading,
    ])
  );

  const fetchData = async () => {
    await dashboardStats();
    await dashboardUnChecked();
    await dashboardQuizRank();
  };

  useEffect(() => {
    fetchData();
  }, []);

  const itemsPerPage1 = 1;
  const [currentPage1, setCurrentPage1] = useState<number>(1);

  const startIndex = (currentPage1 - 1) * itemsPerPage1;
  const endIndex = startIndex + itemsPerPage1;
  const currentItems = quizRank?.slice(startIndex, endIndex);

  const handlePageChange = (newPage: any) => {
    setCurrentPage1(newPage);
  };

  const itemsPerPage2 = 3;
  const [currentPage2, setCurrentPage2] = useState<number>(1);

  const startIndex2 = (currentPage2 - 1) * itemsPerPage2;
  const endIndex2 = startIndex2 + itemsPerPage2;
  const currentItems2 = unChecked?.slice(startIndex2, endIndex2);

  const handlePageChange2 = (newPage: any) => {
    setCurrentPage2(newPage);
  };

  const columns = [
    {
      accessor: "name",
      title: "Nombre de usuario",
      render: (row: any) => {
        return (
          <div className="d-flex align-items-center justify-content-center">
            <p className="ms-2 mb-0 student-name">{row?.user?.name || ""}</p>
          </div>
        );
      },
    },
    {
      accessor: "quiz",
      title: "Quiz Name",
      render: (row: any) => {
        return <p className="ms-3 mb-0 student-name">{row?.quiz?.title}</p>;
      },
    },
    {
      accessor: "date",
      title: "Fecha",
      render: (row: any) => {
        const formattedDate = moment(row?.createdAt).format("MM/DD/YYYY");
        return <p className=" mb-0 quiz-date"> {formattedDate}</p>;
      },
    },

    {
      accessor: "action",
      title: "Acción",
      render: (row: any) => (
        <Link to={`/dashboard/check-quiz/${row?.quiz?._id}/${row?.user?._id}`}>
          <button className="view-detail-btn py-2 px-3">VER DETALLES</button>
        </Link>
      ),
    },
  ];

  return (
    <>
      <div>
        <div className="row my-3 mx-2 ">
          <motion.div
            initial={{ opacity: 0, y: -100 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className="pb-0 col-sm-12 col-md-6 col-lg-3"
          >
            <div className={`p-4 ${1 % 2 === 0 ? "even" : "odd"}`}>
              <p className="top-heading mb-0 text-capitalize">
                Total de usuarias
              </p>
              <p className="main-text mb-0">{stats?.totalUsers ?? 0}</p>
            </div>
          </motion.div>
          <motion.div
            initial={{ opacity: 0, y: -100 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 1 }}
            className="col-sm-12 col-md-6 col-lg-3"
          >
            <div className={`p-4 ${2 % 2 === 0 ? "even" : "odd"}`}>
              <p className="top-heading mb-0 text-capitalize">
                cuestionario total
              </p>
              <p className="main-text mb-0">{stats?.totalQuizes ?? 0}</p>
            </div>
          </motion.div>
          <motion.div
            initial={{ opacity: 0, y: -100 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 1.5 }}
            className="col-sm-12 col-md-6 col-lg-3"
          >
            <div className={`p-4 ${3 % 2 === 0 ? "even" : "odd"}`}>
              <p className="top-heading mb-0 text-capitalize">
                cuestionario comprobado
              </p>
              <p className="main-text mb-0">{stats?.checkedSubmissions ?? 0}</p>
            </div>
          </motion.div>
          <motion.div
            initial={{ opacity: 0, y: -100 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 2 }}
            className="col-sm-12 col-md-6 col-lg-3"
          >
            <div className={`p-4 ${4 % 2 === 0 ? "even" : "odd"}`}>
              <p className="top-heading mb-0 text-capitalize">
                cuestionario sin marcar
              </p>
              <p className="main-text mb-0">
                {stats?.uncheckedSubmissions ?? 0}
              </p>
            </div>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, y: 100 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 1 }}
            className="my-3  col-sm-12 col-md-6 col-lg-8"
          >
            {unChecked?.length < 1 ? (
              <div className="result-not-found ">
                <p className="not-found-text mb-0">
                  No se han encontrado resultados
                </p>
                <p className="not-found-paragraph">
                  ¡Hurra! ¡No hay ningún registro de prueba sin verificar!
                </p>
              </div>
            ) : (
              <div className="unchecked-table">
                <p className="table-heading my-3  px-3 py-1">
                  Prueba no marcada
                </p>
                <GeneralTable
                  loading={loading}
                  columns={columns}
                  records={currentItems2}
                />
                <div className="d-flex justify-content-end p-2 pagination-box">
                  <Pagination
                    total={Math.ceil(unChecked.length / itemsPerPage2)}
                    value={currentPage2}
                    onChange={handlePageChange2}
                  />
                </div>
              </div>
            )}
          </motion.div>

          {loading ? (
            <div
              style={{ margin: "4rem 0" }}
              className="d-flex justify-content-center align-items-center"
            >
              <Loader color="black" />
            </div>
          ) : (
            <motion.div
              initial={{ opacity: 0, y: 100 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 1 }}
              className="my-3 col-sm-12 col-md-6 col-lg-4"
            >
              {quizRank?.length < 1 ? (
                <div className="result-not-found">
                  <p className="not-found-text mb-0">
                    No se han encontrado resultados
                  </p>
                  <p className="not-found-paragraph">
                    ¡No hay registro de prueba!
                  </p>
                </div>
              ) : (
                <div className="unchecked-table">
                  <p className="table-heading my-3  px-3 py-1">
                    rango de prueba
                  </p>

                  {currentItems?.map((value: any, i: number) => {
                    return (
                      <div key={i} className="p-2 m-2 result-box">
                        <p className="quiz-name">{value?.title}</p>
                        {value?.submissions?.map((value: any, j: number) => {
                          return (
                            <div
                              key={j}
                              className=" my-2 d-flex justify-content-between align-items-center"
                            >
                              <div className="d-flex align-items-center">
                                <Image
                                  className="profile-pic"
                                  style={{
                                    width: "40px",
                                    height: "40px",
                                  }}
                                  src={
                                    value?.user?.profilePic
                                      ? baseURL + value?.user?.profilePic
                                      : profile
                                  }
                                  fallbackSrc={`https://placehold.co/400x400/orange/white?text=${value?.user?.name}`}
                                  alt="img"
                                />{" "}
                                <p className="mb-0 ms-2 profile-name">
                                  {value?.user?.name}
                                </p>
                              </div>
                              <p className="mb-0 profile-name">
                                <span>Posición:</span>
                                {value?.rank ? value?.rank : "-"}
                              </p>
                            </div>
                          );
                        })}
                      </div>
                    );
                  })}
                  <div className="d-flex justify-content-end p-2 pagination-box">
                    <Pagination
                      total={Math.ceil(quizRank.length / itemsPerPage1)}
                      value={currentPage1}
                      onChange={handlePageChange}
                    />
                  </div>
                </div>
              )}
            </motion.div>
          )}
        </div>
      </div>
    </>
  );
};

export default Dashboard;
