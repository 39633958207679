import React from "react";
import logo from "../../assets/logo.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { dashboardLinks } from "../../data/data";
import { useAuthStore } from "../../zustand/store/AurthStore";
import { useShallow } from "zustand/react/shallow";
import { useSettingStore } from "../../zustand/store/SettingStore";
import { baseURL } from "../../configs/axiosConfig";
import { motion } from "framer-motion";
import { Image } from "@mantine/core";
interface SideBar {
  handleMenuBtn: () => void;
  opened: boolean;
  close: () => void;
  icon: any;
}

const Sidebar: React.FC<SideBar> = ({ handleMenuBtn, opened, close, icon }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [setting] = useSettingStore(useShallow((state) => [state?.setting]));

  const [logoutFunc] = useAuthStore(useShallow((state) => [state.logoutFunc]));

  const handleLogout = async () => {
    const res = await logoutFunc();

    if (res === "success") {
      navigate("/");
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      transition={{ duration: 1 }}
      className="sidebar-container"
    >
      <div className="d-flex justify-content-center">
        <Link to={"/"}>
          <Image
            width={100}
            height={100}
            radius="md"
            src={baseURL + setting[0]?.websiteSetting?.logo || logo}
            fallbackSrc={logo}
          />
        </Link>
      </div>
      <span className="toggle-btn">{icon}</span>
      <div>
        {dashboardLinks?.map((value, i) => {
          return (
            <Link
              key={i}
              to={value.path}
              className="text-decoration-none  dashboard-link "
            >
              <div
                className={
                  location.pathname === value.path
                    ? "active-link-box my-2 py-2 px-4"
                    : "link-box my-2 py-2 px-4"
                }
              >
                {value?.icon} <span className="ps-3">{value.label}</span>
              </div>
            </Link>
          );
        })}
        <div onClick={handleLogout} className={"logout-link my-2 py-2 px-4"}>
          <Link to={"/"} className="dashboard-link w-100">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="21"
              height="22"
              fill="none"
              viewBox="0 0 21 22"
            >
              <path
                fill="#fff"
                fillRule="evenodd"
                d="M17.6 0a3.3 3.3 0 013.3 3.3v15.4a3.3 3.3 0 01-3.3 3.3H11a3.3 3.3 0 01-3.3-3.3V3.3A3.3 3.3 0 0111 0h6.6zM6.277 5.822a1.1 1.1 0 00-1.555 0l-4.4 4.4a1.1 1.1 0 000 1.556l4.4 4.4a1.1 1.1 0 001.555-1.556L3.755 12.1H13.2a1.1 1.1 0 000-2.2H3.755l2.522-2.522a1.1 1.1 0 000-1.556z"
                clipRule="evenodd"
              ></path>
            </svg>{" "}
            <span className="ps-3">Cerrar sesión</span>
          </Link>
        </div>
      </div>
    </motion.div>
  );
};

export default Sidebar;
