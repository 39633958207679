import React, { useEffect, useState } from "react";
import DashboardQuizVideo from "../../components/cards/dashboard/dashboardQuizVideo";
import { Link } from "react-router-dom";
import { useShallow } from "zustand/react/shallow";
import { useQuizStore } from "../../zustand/store/QuizStore";
import { Loader } from "@mantine/core";
import { motion } from "framer-motion";

const CourseManagement: React.FC = () => {
  const [search, setSearch] = useState<string>("");
  const [quizArray, getQuizs, loading] = useQuizStore(
    useShallow((state) => [state.quiz, state.getQuizs, state.loading])
  );

  useEffect(() => {
    getQuizs();
  }, []);

  const handleSearch = (e: any) => {
    if (e.key === "Enter") {
      getQuizs({ searchFilter: search });
    }
  };

  return (
    <>
      <div className="m-3">
        <div className="row align-items-center">
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 1 }}
            className="col-sm-12 col-md-8 col-lg-6 "
          >
            <div className=" px-2 py-1 search-box">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="21"
                fill="none"
                viewBox="0 0 23 24"
              >
                <path
                  fill="#94A3B8"
                  d="M22.49 22.892a.924.924 0 01-.646.265.958.958 0 01-.658-.265l-3.113-3.126A10.648 10.648 0 01.58 11.383 10.645 10.645 0 0110.994.971a10.648 10.648 0 0110.855 9.951 10.645 10.645 0 01-2.471 7.54l3.113 3.113a.927.927 0 010 1.317zm-11.242-2.48a8.778 8.778 0 008.608-10.487A8.776 8.776 0 002.47 11.637a8.787 8.787 0 008.777 8.776z"
                ></path>
              </svg>
              <input
                placeholder="Busca aquí"
                className="ms-2 search-input"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                onKeyDown={handleSearch}
              />
            </div>
          </motion.div>
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 1 }}
            className="col-sm-12 col-md-4 col-lg-6 d-flex justify-content-end"
          >
            <Link to={"/dashboard/add-course"}>
              <button className="px-4 py-2 add-course">Agregar curso</button>
            </Link>
          </motion.div>
        </div>

        {loading ? (
          <div
            style={{ margin: "4rem 0", height: "100px" }}
            className="d-flex h-100 justify-content-center align-items-center"
          >
            <Loader color="black" />
          </div>
        ) : (
          <>
            {quizArray?.length > 0 ? (
              <div className="row ">
                {quizArray.map((value: any, i?: number) => {
                  return (
                    <motion.div
                      initial={{ opacity: 0, y: 100 }}
                      whileInView={{ opacity: 1, y: 0 }}
                      transition={{ duration: 1 }}
                      key={i}
                      className="col-12 col-sm-6 col-md-4 col-lg-3 col-lg-3 "
                    >
                      <DashboardQuizVideo data={value} />
                    </motion.div>
                  );
                })}
              </div>
            ) : (
              <div className="h-100 mt-5 pt-5 d-flex justify-content-center align-items-center">
                <div className="result-not-found mt-5">
                  <p className="not-found-text mb-0">
                    No se han encontrado resultados
                  </p>
                  <p className="not-found-paragraph">
                    ¡No hay ningún cuestionario basado en tu búsqueda!
                  </p>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default CourseManagement;
