// import { useSelector } from "react-redux";
import { useContext } from "react";
import { AuthContext } from "../../App";

import { useShallow } from "zustand/react/shallow";
import { useUserStore } from "../../zustand/store/UserStore";
import { useAuthStore } from "../../zustand/store/AurthStore";

export const useAuthentication = () => {
  const [isAuthenticated] = useAuthStore(
    useShallow((state) => [state.isAuthenticated, state.loading])
  );
  if (isAuthenticated) {
    return true;
  } else {
    return false;
  }
};

export const getToken = () => {
  const token = localStorage.getItem("token");
  if (token) {
    return token;
  } else {
    return false;
  }
};

export const getUserData = () => {
  const userString = localStorage.getItem("user");
  const user = userString ? JSON.parse(userString) : null;
  if (user) {
    return user;
  } else {
    return false;
  }
};

export const UseGetRole = () => {
  const userString = localStorage.getItem("user");
  const user = userString ? JSON.parse(userString) : null;
  if (user) {
    return user?.role;
  } else {
    return false;
  }
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
