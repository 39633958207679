import { create } from "zustand";
import custAxios, {
  attachToken,
  attachTokenWithFormAxios,
  formAxios,
} from "../../configs/axiosConfig";

interface IUserStore {
  setting: any;
  settingImg: any;

  loading: boolean;
  settingAddAndEdit: (value: any) => Promise<any>;
  settingGet: () => Promise<any>;
  settingImage: (value: any) => Promise<any>;

  //   loginFunc: (values: any) => Promise<any>;
}

export const useSettingStore = create<IUserStore>((set) => ({
  setting: {},
  settingImg: null,
  loading: false,
  settingAddAndEdit: async (value) => {
    try {
      set({
        loading: true,
      });

      attachToken();
      const response = await custAxios.post("/settings/", value);

      set({
        loading: false,
        // setting: response?.data?.data?.settings.content,
      });

      return response?.data;
    } catch (error) {
      set({
        loading: false,
      });
      console.error("Error fetching data:", error);
    }
  },
  settingGet: async () => {
    try {
      set({
        loading: true,
      });

      attachToken();
      const response = await custAxios.get("/settings/");

      set({
        loading: false,
        setting: response?.data?.data?.settings.content,
      });

      return response.data;
    } catch (error) {
      set({
        loading: false,
      });
      console.error("Error fetching data:", error);
    }
  },
  settingImage: async (value) => {
    try {
      set({
        loading: true,
      });

      attachTokenWithFormAxios();
      const response = await formAxios.post("/settings/image-uploader", value);

      set({
        loading: false,
        settingImg: response?.data?.data?.image,
      });

      return response?.data;
    } catch (error) {
      set({
        loading: false,
      });
      console.error("Error fetching data:", error);
    }
  },
}));
