import React, { useEffect, useState } from "react";
import HeroSectionImage from "../../assets/heroSection.png";
import GeneralModal from "../modal/GeneralModal";
import { useDisclosure } from "@mantine/hooks";
import { getToken, useAuth } from "../../services/hooks";
import Login from "../auth/Login";
import Register from "../auth/Register";
import ForgotPassword from "../auth/ForgotPassword";
import EditButton from "../layout/EditButton";
import { baseURL } from "../../configs/axiosConfig";
import { motion } from "framer-motion";
import ResetPassword from "./../auth/ResetPassword";
interface HeroSectionProps {
  show: boolean;
  dataContent: any;
}
const HeroSection: React.FC<HeroSectionProps> = ({
  show,
  dataContent,
}: HeroSectionProps) => {
  const token = getToken();
  const [opened, { open, close }] = useDisclosure();

  const { currentForm, switchForm } = useAuth();

  useEffect(() => {
    close();
  }, [token]);

  const handleModalClose = () => {
    switchForm("login");
    close();
  };

  return (
    <motion.div
      // initial={{ opacity: 0 }}
      // whileInView={{ opacity: 1 ,x:0}}
      // transition={{ duration: 1 ,x:0 }}
      className="home-bg "
      style={{
        backgroundImage: `url("${
          dataContent && baseURL + dataContent[0]?.home?.home_bg
        }")`,
      }}
    >
      <div className="d-flex justify-content-end p-3">
        <EditButton
          dataKey={"home_bg"}
          pageName="home"
          inputType="input"
          // colour="#000"
        />
      </div>
      <div className="container h-100">
        <div className="row align-items-center ">
          <div className="h-100 col-md-5 col-lg-5">
            <div className="glass-box "></div>
            <div className="hero-section-paraa-box ">
              {/* <p className="herosection-heading text-uppercase mb-0"></p> */}
              <p className="herosection-paragraph">
                {/* Tu destino. ¡Únase a nosotros en esta emocionante aventura hacia
                una educación financiera más amigable para toda nuestra
                comunidad! */}
                {(dataContent && dataContent[0]?.home?.tu_destino) || ""}
                <EditButton
                  dataKey={"tu_destino"}
                  pageName="home"
                  inputType="text"
                />
              </p>
              {!show ? (
                <button
                  onClick={open}
                  className="get-started my-2 px-4 py-2 text-uppercase"
                >
                  Empezar
                </button>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      <GeneralModal
        opened={opened}
        loading={true}
        Component={
          currentForm === "login"
            ? Login
            : currentForm === "signup"
            ? Register
            : currentForm === "forgotPassword"
            ? ForgotPassword
            : currentForm === "resetPassword"
            ? ResetPassword
            : Login
        }
        openModal={open}
        closeModal={handleModalClose}
        size={"80%"}
      />
    </motion.div>
  );
};

export default HeroSection;
