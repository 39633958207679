import React, { useEffect, useState } from "react";
import QuizDetailCard from "../../components/cards/quizDetailCard";
import { Accordion, Loader, Radio } from "@mantine/core";
import { useNavigate, useParams } from "react-router-dom";
import { useShallow } from "zustand/react/shallow";
import { useQuizStore } from "../../zustand/store/QuizStore";
import { motion } from "framer-motion";
const CheckQuiz: React.FC = () => {
  const { id, userid } = useParams();

  const navigate = useNavigate();
  const [checkQuiz, setCheckQuiz] = useState<any>([]);
  const [userQuizDetail, setUserQuizDetail] = useState<any>({
    correct: 0,
    incorrect: 0,
  });

  const [
    getSingleQuizById,
    singleQuizByUser,
    getSingleQuizByUser,
    singleQuizById,
    checkSingleQuiz,
    loading,
  ] = useQuizStore(
    useShallow((state) => [
      state.getSingleQuizById,
      state.singleQuizByUser,
      state.getSingleQuizByUser,
      state.singleQuizById,
      state.checkSingleQuiz,
      state.loading,
    ])
  );

  const fetchData = async () => {
    const [res, res2] = await Promise.all([
      getSingleQuizByUser(id, userid),
      getSingleQuizById(id),
    ]);
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (singleQuizByUser) {
      setCheckQuiz(singleQuizByUser?.answers);
    }
  }, [singleQuizByUser, loading]);

  const handleRadioChange = (value: any, index: number) => {
    const updatedCheckQuiz = [...checkQuiz]; // Create a shallow copy of the array
    updatedCheckQuiz[index] = {
      ...updatedCheckQuiz[index],
      isCorrect: value === "correct" ? true : false,
    };
    setCheckQuiz(updatedCheckQuiz); // Update the state with the modified array
  };

  useEffect(() => {
    if (checkQuiz) {
      const { correct, incorrect } = checkQuiz?.reduce(
        (acc: any, item: any) => {
          if (item?.isCorrect === true) {
            return {
              correct: acc.correct + 1,
              incorrect: acc.incorrect,
            };
          } else if (item.isCorrect === false) {
            return {
              correct: acc.correct,
              incorrect: acc.incorrect + 1,
            };
          } else {
            return acc;
          }
        },
        { correct: 0, incorrect: 0 }
      );

      setUserQuizDetail({ correct, incorrect });
    }
  }, [checkQuiz]);

  const handleCheckQuiz = async (e: any) => {
    e.preventDefault();
    const res = await checkSingleQuiz(singleQuizByUser?._id, checkQuiz);
    if (res?.success === true) {
      navigate(-1);
    }
  };

  return (
    <>
      {loading ? (
        <div className=" h-100 d-flex justify-content-center align-items-center">
          <Loader color="black" />
        </div>
      ) : (
        <div>
          <QuizDetailCard data={singleQuizByUser} marks={userQuizDetail} />
          {singleQuizById?.questions?.length > 0 && (
            <div className="quiz-answers">
              <Accordion chevronPosition="right" defaultValue="hello">
                {singleQuizByUser?.answers?.map((item: any, i: number) => {
                  return (
                    <motion.div
                      initial={{ opacity: 0, y: 100 }}
                      whileInView={{ opacity: 1, y: 0 }}
                      transition={{ duration: 1 }}
                    >
                      <Accordion.Item key={i} value={item?.key}>
                        <Accordion.Control>
                          Q{i + 1}:{" "}
                          {singleQuizByUser?.quiz?.questions[i]?.key ===
                          item?.key
                            ? singleQuizByUser?.quiz?.questions[i]?.que
                            : ""}
                        </Accordion.Control>
                        <Accordion.Panel>
                          <div className="answer p-4 ">
                            <p className="text-capitalize">
                              {" "}
                              {singleQuizById?.questions[i]?.key === item?.key
                                ? item?.ans
                                : ""}
                            </p>
                          </div>
                          <div className="d-flex justify-content-between flex-wrap mx-3">
                            <p className="mantine-Accordion-label mb-0">
                              {" "}
                              Estado de respuesta
                            </p>

                            <div className="d-flex justify-content-end flex-wrap w-100 mt-2">
                              <label className="ms-2 mt-1 d-flex align-items-center">
                                <p className="mb-0 me-3 status-opt">
                                  Correcta{" "}
                                  <svg
                                    className="ms-2"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="12"
                                    fill="none"
                                    viewBox="0 0 16 12"
                                  >
                                    <path
                                      stroke="#000"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeOpacity="0.59"
                                      strokeWidth="2"
                                      d="M1 7l4 4L15 1"
                                    ></path>
                                  </svg>
                                </p>
                                <Radio
                                  name={`status${i}`}
                                  color="black"
                                  checked={
                                    checkQuiz ? checkQuiz[i]?.isCorrect : false
                                  }
                                  onChange={() =>
                                    handleRadioChange("correct", i)
                                  }
                                />
                              </label>
                              <label className="ms-2 mt-1 d-flex align-items-center">
                                <p className="mb-0 me-3 status-opt">
                                  Incorrecta{" "}
                                  <svg
                                    className="ms-2"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="14"
                                    height="14"
                                    fill="none"
                                    viewBox="0 0 14 14"
                                  >
                                    <path
                                      stroke="#000"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeOpacity="0.59"
                                      strokeWidth="2"
                                      d="M1 13L13 1M1 1l12 12"
                                    ></path>
                                  </svg>
                                </p>
                                <Radio
                                  name={`status${i}`}
                                  color="black"
                                  checked={
                                    (checkQuiz && checkQuiz[i]?.isCorrect) ===
                                    false
                                      ? true
                                      : false
                                  }
                                  onChange={() =>
                                    handleRadioChange("incorrect", i)
                                  }
                                />
                              </label>
                            </div>
                          </div>
                        </Accordion.Panel>
                      </Accordion.Item>
                    </motion.div>
                  );
                })}
              </Accordion>

              <motion.div
                initial={{ opacity: 0, x: 0 }}
                whileInView={{ opacity: 1, x: 0 }}
                transition={{ duration: 1 }}
                className="d-flex justify-content-end text-uppercase pt-4"
              >
                <button
                  onClick={handleCheckQuiz}
                  className="yellow-btn px-5 py-2 mt-4"
                >
                  Entregar
                </button>
              </motion.div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default CheckQuiz;
