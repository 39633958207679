import {
  Divider,
  Stepper,
  Text,
  TextInput,
  Loader,
  NumberInput,
  CheckIcon,
  Radio,
  Stack,
  RadioGroup,
} from "@mantine/core";

import React, { useState, useRef, useEffect } from "react";
import { useForm } from "@mantine/form";
import { useLocation } from "react-router-dom";
import { useCryptoStore } from "./../../zustand/store/CryptoStore";
import { useShallow } from "zustand/react/shallow";
import { useNavigate } from "react-router-dom";
import { UseGetRole } from "../../services/hooks";
import { motion } from "framer-motion";
import ModalAlert from "../modal/ModalAlert";
import { useDisclosure } from "@mantine/hooks";
import { warningMessage } from "../../services/helpers";
import axios from "axios";

interface UsdtFormProps {
  backFunc: (value: boolean) => void;
}

const UsdtForm: React.FC<UsdtFormProps> = ({ backFunc }) => {
  const role = UseGetRole();
  const location = useLocation();
  const navigate = useNavigate();
  const secondFormRef = useRef<HTMLFormElement>(null);
  const { createOrder, loading, createBankOrder, usdtConversion, order } =
    useCryptoStore(useShallow((state) => state));
  let usdAmount = Number(localStorage.getItem("usdAmount") ?? 0);
  let usdtAmount = Number(localStorage.getItem("usdtAmount") ?? 0);
  const currency = localStorage.getItem("currency");
  const [active, setActive] = useState<number>(2);
  const [transactionId, setTransactionId] = useState<string | null>("");
  const [isOrderSuccess, setOrderSuccess] = useState<boolean>(false);
  const urlParams = new URLSearchParams(location.search);
  const paymentId = urlParams.get("paymentId");
  const payerId = urlParams.get("PayerID");

  const [opened, { open, close }] = useDisclosure(false);

  const form = useForm({
    initialValues: {
      walletAddress: "",
      walletName: "",
      reference: "",
      bankName: null,
    },

    validate: {
      walletAddress: (value) =>
        value?.trim() === "" ? "Campo requerido" : null,
      walletName: (value) => (value?.trim() === "" ? "Campo requerido" : null),
      bankName: (value) => (value === null ? "Campo requerido" : null),
    },
  });

  useEffect(() => {
    if (secondFormRef.current) {
      secondFormRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, []);
  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       window.scrollTo(0, 0);
  //       // @ts-ignore
  //       const values: any = JSON.parse(localStorage.getItem("order"));
  //       const data = {
  //         ...values,
  //         type: values.type.toLowerCase(),
  //         paymentIntentId: paymentId,
  //         usdAmount: usdAmount,
  //         usdtAmount: usdtAmount,
  //         cardToken: payerId,
  //       };

  //       const res = await createOrder(data);
  //       if (res) {
  //         setOrderSuccess(true);
  //         setActive(3);
  //         setTransactionId(paymentId);
  //         urlParams.delete("paymentId");
  //         urlParams.delete("PayerID");
  //         urlParams.delete("token");
  //         const newUrl = `${window.location.pathname}?${urlParams.toString()}`;
  //         window.history.replaceState({}, "", newUrl);
  //       }
  //       if (!res) {
  //         setOrderSuccess(false);
  //         navigate("/usdt-exchange");
  //         window.scrollTo(0, 0);
  //       }
  //     } catch (error) {
  //       setOrderSuccess(false);
  //       navigate("/usdt-exchange");
  //     }
  //     localStorage.removeItem("order");
  //     localStorage.removeItem("paymentDetail");
  //     localStorage.removeItem("usdtAmount");
  //     localStorage.removeItem("usdAmount");
  //   };

  //   if (payerId !== "" && paymentId !== "") {
  //     fetchData();
  //   }
  // }, []);

  // const handleSubmit = async (values: any) => {
  //   // @ts-ignore
  //   if (form.isValid) {
  //     console.log("values.type.......", values.type);

  //     if (values.type === "paypal") {
  //       const redirectUrlString = localStorage.getItem("paymentDetail");

  //       if (redirectUrlString) {
  //         const redirectUrl = JSON.parse(redirectUrlString);

  //         localStorage.setItem("order", JSON.stringify(values));
  //         window.location.href = redirectUrl.paymentRedirectUrl;
  //       } else {
  //         console.error("Payment detail not found in localStorage");
  //       }
  //     } else if (values.type === "transbank" && currency == "USD") {
  //       const res = await axios.get(
  //         `https://api.coingecko.com/api/v3/coins/tether`
  //       );
  //       console.log("res.........", res);
  //     } else {
  //       open();
  //     }
  //   }
  // };
  const handleSubmit = async (values: any) => {
    // @ts-ignore
    if (form.isValid) {
      if (currency === "CLP") {
        open();
      } else {
        console.error("Payment detail not found");
      }
    }
  };
  // const handleSubmit = async (values: any) => {
  //   // @ts-ignore
  //   if (form.isValid) {
  //     if (currency === "USD") {
  //       const redirectUrlString = localStorage.getItem("paymentDetail");

  //       if (redirectUrlString) {
  //         const redirectUrl = JSON.parse(redirectUrlString);

  //         localStorage.setItem("order", JSON.stringify(values));
  //         window.location.href = redirectUrl.paymentRedirectUrl;
  //       } else {
  //         console.error("Payment detail not found in localStorage");
  //       }
  //     } else if (currency === "CLP") {
  //       open();
  //     }
  //   }
  // };

  console.log(form.values);

  const transbankSubmitHandler = async () => {
    try {
      // alert('sibutton');
      // @ts-ignore

      const values: any = JSON.parse(localStorage.getItem("order"));
      const data = {
        ...form.values,
        usdAmount: usdAmount,
        usdtAmount: usdtAmount,
      };

      const res = await createBankOrder(data);
      if (res?.data?.success) {
        setTransactionId(res?.data?.data?.order?._id);
        form.reset();

        close();
        setActive(3);
        // window.open(
        //   "https://www.webpay.cl/company/129322?utm_source=transbank&utm_medium=portal3.0&utm_campaign=link_portal",
        //   "_blank"
        // );
      } else {
        close();
        navigate("/usdt-exchange");
        window.scrollTo(0, 0);
      }
    } catch (error: any) {
      close();
      console.error(error);
    }
  };

  return (
    <>
      <motion.div
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{ duration: 1 }}
        className="container"
      >
        {loading ? (
          <div
            style={{ minHeight: "50vh" }}
            className="d-flex justify-content-center align-items-center"
          >
            <Loader color="black" />
          </div>
        ) : (
          <div>
            <div className="about-heading my-4">
              <p className="py-3 px-5" id="about-us">
                Comprar <span>USDT</span>
              </p>
            </div>
            {active < 3 ? (
              <div className=" black-container margin-x5 my-5 py-4">
                <div className="stepper-header  mb-4">
                  <Stepper active={active} allowNextStepsSelect={false}>
                    <Stepper.Step
                      allowStepSelect={false}
                      label="Seleccionar moneda"
                    ></Stepper.Step>
                    <Stepper.Step
                      allowStepSelect={false}
                      label="Confirmar pago"
                    ></Stepper.Step>
                    <Stepper.Step
                      allowStepSelect={false}
                      label="Detalles del pago"
                    ></Stepper.Step>
                  </Stepper>
                </div>
                <Divider />
                <motion.div
                  initial={{ opacity: 0, y: 100 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ duration: 1 }}
                  className="step-1"
                >
                  <p className="buy-text text-uppercase mb-0">
                    Confirmar <span>Información</span>
                  </p>
                  <p className="ref-text">
                    Estás a punto de recibir {usdtAmount?.toFixed(4) || 0} USDT
                  </p>
                  <div className="d-flex flex-wrap mt-5 pt-4 ">
                    <div className="my-1 d-flex align-items-center me-5 pe-5">
                      <div className="me-3 get-icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 320 512"
                          width="35"
                          height="35"
                        >
                          <path
                            fill="#fff"
                            d="M302.1 358.1c-8.9 49.7-50.7 81.3-110.1 88.3V480c0 17.67-14.28 32-31.96 32S128 497.7 128 480v-34.96c-.443-.065-.836-.031-1.281-.098-26.19-3.766-53.69-13.2-77.94-21.53l-11.03-3.766C21.03 414 12.03 395.8 17.69 379.1s23.88-25.73 40.56-20.08l11.31 3.859c21.59 7.406 46.03 15.81 66.41 18.73 47.09 6.953 97.06-.844 103.1-34.09 5.188-28.55-11.16-39.89-87.53-60.7L136.5 282.7C92.59 270.4 1.25 244.9 17.97 153 26.82 104.1 68.44 72.48 128 65.51V32c0-17.67 14.33-32 32.02-32S192 14.33 192 32v34.95c.441.063.84.045 1.281.111 16.91 2.531 36.22 7.47 60.72 15.55 16.81 5.531 25.94 23.61 20.41 40.41-5.531 16.77-23.69 25.86-40.41 20.38-20.72-6.812-37.12-11.08-50.16-13.02C137 123.4 86.97 131.2 80.91 164.5 76.5 188.8 85.66 202 153.8 221l14.59 4.016C228.3 241.4 318.9 266.1 302.1 358.1z"
                          ></path>
                        </svg>
                      </div>
                      <div>
                        <p className="mb-0 gray-text">Pagar</p>
                        <p className="mb-0 ref-text">
                          {currency === "USD"
                            ? `USD ${usdAmount?.toFixed(4) ?? 0}`
                            : `${usdAmount?.toFixed(4) ?? 0} CLP`}
                        </p>
                      </div>
                    </div>
                    <div className="my-1 d-flex align-items-center me-5 pe-5">
                      <div className="me-3 pay-icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="35"
                          height="35"
                          fill="none"
                          viewBox="0 0 49 43"
                        >
                          <path
                            fill="#fff"
                            d="M44.792 7.841H6.39c-.51 0-.997-.204-1.358-.566a1.939 1.939 0 010-2.733c.36-.362.849-.566 1.358-.566h32.642c.51 0 .998-.204 1.358-.566a1.939 1.939 0 000-2.733A1.914 1.914 0 0039.032.11H6.39c-1.528 0-2.993.61-4.073 1.698a5.817 5.817 0 00-1.687 4.1v30.92c0 1.538.607 3.013 1.687 4.1a5.742 5.742 0 004.073 1.698h38.402a3.827 3.827 0 002.716-1.132 3.878 3.878 0 001.124-2.733V11.706a3.878 3.878 0 00-1.124-2.733 3.828 3.828 0 00-2.716-1.132zm0 30.921H6.39c-.51 0-.997-.203-1.358-.566a1.939 1.939 0 01-.562-1.366V11.375a5.69 5.69 0 001.92.331h38.402v27.056zm-11.52-14.494c0-.573.169-1.134.485-1.61a2.884 2.884 0 011.293-1.068 2.863 2.863 0 013.138.628 2.916 2.916 0 01.624 3.16c-.217.529-.587.982-1.06 1.3a2.867 2.867 0 01-3.637-.36 2.908 2.908 0 01-.843-2.05z"
                          ></path>
                        </svg>
                      </div>
                      <div>
                        <p className="mb-0 gray-text">Recibido</p>
                        <p className="mb-0 ref-text">
                          {usdtAmount?.toFixed(4) || 0} USDT
                        </p>
                      </div>
                    </div>
                  </div>
                </motion.div>
              </div>
            ) : (
              <div className=" black-container margin-x5 my-5 py-4">
                <div className="stepper-header  mb-4">
                  <Stepper active={active} allowNextStepsSelect={false}>
                    <Stepper.Step
                      allowStepSelect={false}
                      label="Seleccionar moneda"
                    ></Stepper.Step>
                    <Stepper.Step
                      allowStepSelect={false}
                      label="Confirmar pago"
                    ></Stepper.Step>
                    <Stepper.Step
                      allowStepSelect={false}
                      label="Detalles del pago"
                    ></Stepper.Step>
                  </Stepper>
                </div>
                <Divider />
                <div className="step-1 border ">
                  <div className="d-flex align-items-center justify-content-center ">
                    <p className="me-3 mb-0 success-text text-center">ÉXITO</p>
                    <div className="correct-icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="22"
                        height="16"
                        fill="none"
                        viewBox="0 0 22 16"
                      >
                        <path
                          fill="#fff"
                          d="M6.737 12.623L1.711 7.648 0 9.33 6.737 16 21.2 1.682 19.5 0 6.738 12.623z"
                        ></path>
                      </svg>
                    </div>
                  </div>

                  <p className="success-text-1 text-center">
                    Esperando la aprobación del administrador. Gracias
                  </p>

                  <p className="ref-text text-center">
                    Pagaste exitosamente{" "}
                    {currency === "USD"
                      ? `USD ${usdAmount?.toFixed(4) ?? 0}`
                      : `${usdAmount?.toFixed(4) ?? 0} CLP`}{" "}
                    a cambio de {usdtAmount?.toFixed(4)} USDT
                  </p>

                  <p
                    style={{ width: "50%", textAlign: "center" }}
                    className="pt-1 ref-text text-center mx-auto"
                  >
                    Los pagos realizados en días laborables antes de las 2 pm en
                    Chile se procesan al siguiente día hábil. Después de las 2
                    pm, espere 48 horas hábiles. Los pagos realizados durante el
                    fin de semana se procesan en 2 días hábiles. Para un
                    servicio más rápido, envíe un correo electrónico a
                    Yourwaycommunity@gmail.com
                  </p>

                  <div className="d-flex justify-content-center align-items-center border-primary">
                    <div className="success-table">
                      <div className="p-3 d-flex justify-content-between align-items-center">
                        <p className="mb-0">Estatus</p>
                        <p className="mb-0 pending-text">Pendiente</p>
                      </div>
                      <Divider />
                      <div className="p-3  d-flex justify-content-between align-items-center">
                        <p className="mb-0">ID de transacción</p>

                        <p className="mb-0">{transactionId}</p>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex justify-content-end ">
                    <button
                      type="submit"
                      onClick={() => {
                        role !== "admin"
                          ? navigate("/order-history")
                          : navigate("/dashboard/transaction-history");
                      }}
                      className="yellow-btn mb-4 px-5 py-2 "
                    >
                      {"Ver detalle"}
                    </button>
                  </div>
                </div>
              </div>
            )}
            {active === 2 && (
              <form
                ref={secondFormRef}
                className=" px-0 mb-5 pt-2"
                onSubmit={form.onSubmit(handleSubmit)}
              >
                <motion.div
                  initial={{ opacity: 0 }}
                  whileInView={{ opacity: 1 }}
                  transition={{ duration: 1 }}
                  className=" black-container margin-x5 my-3 py-1"
                >
                  <div className="step-1">
                    <p className="buy-text text-uppercase mb-0">
                      Pago <span>Detalles</span>
                    </p>

                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <span style={{ fontSize: "18px" }} className="ref-text">
                        Detalles de la billetera
                      </span>
                      <span className="ref-text ">
                        *La dirección de Binance debe estar en la red Tron
                        (TRC20) con USDT.
                      </span>
                    </div>
                    <div className="mt-1 pt-0 row">
                      <div className="mt-2 col-12 col-sm-12 col-md-6 col-lg-6">
                        <p>Dirección de la billetera de Binance</p>
                        <TextInput {...form.getInputProps("walletAddress")} />
                      </div>
                      <div className="mt-2 col-12 col-sm-12 col-md-6 col-lg-6">
                        <p>Nombre de la billetera de Binance</p>
                        <TextInput {...form.getInputProps("walletName")} />
                      </div>
                      <div className="mt-2 col-12 col-sm-12 col-md-6 col-lg-6">
                        <Stack className="mt-1" gap={2}>
                          <p>Método de Pago</p>
                          <Radio.Group {...form.getInputProps("bankName")}>
                            <Radio
                              icon={CheckIcon}
                              label="Mercado Pago"
                              value="mercado"
                              color="#f4c924"
                              className="pb-3"
                            />
                            <Radio
                              icon={CheckIcon}
                              label="Transbank"
                              value="transbank"
                              color="#f4c924"
                              // checked
                              // defaultValue={"transbank"}
                              // defaultChecked
                            />
                          </Radio.Group>
                        </Stack>
                      </div>

                      <div className="my-3 pt-3">
                        <p className="yellow-text fw-bold fs-4 mb-0">
                          Código de referencia
                        </p>
                        <p>
                          DEBES incluir el Código de Referencia en tu depósito
                          para acreditar tu cuenta. Código de Referencia:
                        </p>
                        <TextInput {...form.getInputProps("reference")} />
                      </div>
                    </div>
                  </div>
                </motion.div>
                <motion.div
                  initial={{ opacity: 0, y: 100 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ duration: 1 }}
                  className="margin-x5 mb-5 d-flex flex-wrap justify-content-between"
                >
                  <button
                    onClick={() => backFunc(false)}
                    type="button"
                    className="black-btn px-5 py-2 "
                  >
                    Atrás
                  </button>
                  <button type="submit" className="yellow-btn px-5 py-2 ">
                    {loading ? (
                      <Loader color="#000" size={"sm"} />
                    ) : (
                      "¡Sigamos adelante!"
                    )}
                  </button>
                </motion.div>
              </form>
            )}
          </div>
        )}
      </motion.div>

      <ModalAlert
        opened={opened}
        close={close}
        title={"Confirmar Orden?"}
        children={
          <div>
            <p style={{ textAlign: "center" }}>
              Por favor, asegúrate de ingresar el monto correcto y la dirección
              de correo electrónico correcta.
            </p>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: "1rem",
              }}
            >
              <button
                onClick={close}
                type="button"
                className="black-btn1 px-5 py-2 "
              >
                Cancelar
              </button>

              <a
                href={
                  form?.values?.bankName === "transbank"
                    ? "https://www.webpay.cl/company/129322?utm_source=transbank&utm_medium=portal3.0&utm_campaign=link_portal"
                    : "https://link.mercadopago.cl/yourway"
                }
                target="_blank"
                // disabled={loading}
                onClick={transbankSubmitHandler}
                className="yellow-btn1 px-5 py-2 decoration-none"
                id="sibutton"
                style={{ textDecoration: "none" }}
              >
                {loading ? <Loader color="#000" size={"sm"} /> : "Sí"}
              </a>
            </div>
          </div>
        }
      />
    </>
  );
};

export default UsdtForm;
