import React from "react";
import { motion } from "framer-motion";

const TermsAndConditions: React.FC = () => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      transition={{ duration: 1 }}
      className="p-3"
    >
      <p className="mb-0 terms-heading">
        Términos y <span>Condiciones</span>
      </p>
      <p className="mb-0 terms-text">
        Acepto las presentes condiciones generales de uso del portal regulan el
        acceso y la utilización del portal, así como la información y atención
        personalizada otorgada por su miembros y asistentes, incluyendo los
        contenidos y los servicios puestos a disposición de los usuarios en y/o
        a través del portal, bien por el portal, bien por sus usuarios, bien por
        terceros. No obstante, el acceso y la utilización de ciertos contenidos
        y/o servicios puede encontrarse sometido a determinadas condiciones
        específicas. La empresa se reserva la facultad de modificar en cualquier
        momento las condiciones generales de uso del portal. En todo caso, se
        recomienda que consulte periódicamente los presentes términos de uso del
        portal, ya que pueden ser modificados. El usuario deberá respetar en
        todo momento los términos y condiciones establecidos en las presentes
        condiciones generales de uso del portal. De forma expresa el usuario
        manifiesta que utilizará el portal de forma diligente y asumiendo
        cualquier responsabilidad que pudiera derivarse del incumplimiento de
        las normas. Así mismo, el usuario no podrá utilizar el portal para
        transmitir, almacenar, divulgar promover o distribuir datos o contenidos
        que sean portadores de virus o cualquier otro código informático,
        archivos o programas diseñados para interrumpir, destruir o perjudicar
        el funcionamiento de cualquier programa o equipo informático o de
        telecomunicaciones.El usuario conoce y acepta que el portal no otorga
        ninguna garantía de cualquier naturaleza, ya sea expresa o implícita,
        sobre los datos, contenidos, información y servicios que se incorporan y
        ofrecen desde el Portal. Exceptuando los casos que la Ley imponga
        expresamente lo contrario, y exclusivamente con la medida y extensión en
        que lo imponga, el Portal no garantiza ni asume responsabilidad alguna
        respecto a los posibles daños y perjuicios causados por el uso y
        utilización de la información, datos y servicios. Finalmente todo
        consejo financiero no asegura resultados futuros ni es responsabilidad
        de yourway y sus ejecutivos por tratarse de activos financieros
        esencialmente variables.
      </p>
    </motion.div>
  );
};

export default TermsAndConditions;
