import {
  Avatar,
  Divider,
  Loader,
  NumberInput,
  Select,
  Stepper,
} from "@mantine/core";
import React, { useEffect, useState } from "react";
import FAQs from "../components/home/FAQs";
import { useFaqStore } from "../zustand/store/FaqsStore";
import { useShallow } from "zustand/react/shallow";
import { useForm } from "@mantine/form";
import { useLocation, useNavigate } from "react-router-dom";
import EditButton from "../components/layout/EditButton";
import { useSettingStore } from "../zustand/store/SettingStore";
import { baseURL } from "../configs/axiosConfig";
import { useCryptoStore } from "./../zustand/store/CryptoStore";
import {
  errorMessage,
  successMessage,
  warningMessage,
} from "../services/helpers";
import BuyUsdt from "./BuyUsdt";
import { motion } from "framer-motion";
import { useMediaQuery } from "@mantine/hooks";
import exchangeUsdt from "../assets/exchangeUsdt.png";

const UsdtExchange: React.FC = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const status: any = new URLSearchParams(search).get("status");
  const [stepper, setStepper] = useState(false);
  // const [paymentIntentDetail, setPaymentIntentDetail] = useState({});
  const [active, setActive] = useState<number>(1);
  const [value, setValue] = useState<string | number>("");
  const [usdt, setUsdt] = useState<number>(0);
  const [getFaqs] = useFaqStore(useShallow((state) => [state.getFaqs]));
  const [setting] = useSettingStore(useShallow((state) => [state.setting]));
  const { loading, usdtConversion, createPaymentIntent } = useCryptoStore(
    useShallow((state) => state)
  );
  const mobile = useMediaQuery("(max-width: 1024px)");

  useEffect(() => {
    getFaqs();
  }, []);

  useEffect(() => {
    if (status == "false") {
      errorMessage(
        "Lamentamos informarle que el pago a través de PayPal no se realizó con éxito."
      );
    }
  }, []);

  const form = useForm({
    initialValues: { amount: "" },
    validate: {
      amount: (value) => (value ? null : "Por favor, ingresa la cantidad"),
    },
  });

  const continueHandler = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const numericValue = parseFloat(form?.values?.amount?.toString());
    if (numericValue <= 0 || Number(usdt) <= 0) {
      errorMessage("Ingresa una cantidad válida en dólares estadounidenses.");
    } else {
      localStorage.setItem("usdtAmount", JSON.stringify(usdt)?.toString());
      localStorage.setItem(
        "usdAmount",
        JSON.stringify(numericValue)?.toString()
      );
      localStorage.setItem("currency", "CLP");
      setStepper(!stepper);
      window.scrollTo(0, 0);
    }
    // else {
    //   const res = await createPaymentIntent(numericValue);
    //   if (res?.data?.success) {
    //     localStorage.setItem("usdtAmount", (usdt || 0)?.toString());
    //     localStorage.setItem("usdAmount", (numericValue || 0)?.toString());
    //     localStorage.setItem("currency", "CLP");
    //     const paymentDetail = {
    //       paymentRedirectUrl: res?.data?.data?.paymentRedirectUrl,
    //       paymentId: res?.data?.data?.paymentId,
    //     };
    //     localStorage.setItem("paymentDetail", JSON.stringify(paymentDetail));

    //     setStepper(!stepper);
    //     window.scrollTo(0, 0);
    //   }
    // }
  };

  const conversionHandler = async (
    e: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (e.key === "Enter") {
      e.preventDefault();
      const res = await usdtConversion(value);
      if (res) {
        setUsdt(res?.data?.data?.usdtAmount * 0.92);
      }
    }
  };

  const conversionClick = async (values: any) => {
    const payload = {
      amount: values.amount,
      currency: "clp",
    };

    if (payload.amount) {
      if (payload.currency === "clp" && payload.amount < 500) {
        warningMessage(
          "Por favor, ingresa una cantidad válida en CLP mayor a 500"
        );
      } else {
        const res = await usdtConversion(payload);

        if (res?.data?.success) {
          // setUsdt(res?.data?.data?.usdtAmount * 0.08);
          // setUsdt(res?.data?.data?.usdtAmount * 0.92);
          setUsdt(res?.data?.data?.usdtAmount);
        }
      }
    }
  };

  const goBack = (value: boolean) => {
    setStepper(value);
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      transition={{ duration: 1 }}
    >
      {!stepper ? (
        <div>
          <div
            className="hero-section-usdt"
            style={{
              backgroundImage: `url("${
                setting && baseURL + setting[0]?.usdt_exchange?.header_bg
              }")`,
            }}
          >
            <div className="d-flex justify-content-end p-3">
              <EditButton
                dataKey={"header_bg"}
                pageName="usdt_exchange"
                inputType="input"
                colour="#777"
              />
            </div>
            <motion.div
              initial={{ opacity: 0, y: -100 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 1 }}
              className="h-100 d-flex justify-content-center"
            >
              <p className="mb-0 h-auto hero-text">
                {(setting && setting[0]?.usdt_exchange?.header_text) || ""}
                <EditButton
                  dataKey={"header_text"}
                  pageName="usdt_exchange"
                  inputType="text"
                />
              </p>
            </motion.div>
          </div>
          <div className="container ">
            {/* <form onSubmit={(e) => conversionClick(e)} className="px-0"> */}
            <form
              onSubmit={form.onSubmit((values) => conversionClick(values))}
              className="px-0"
            >
              <motion.div
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                transition={{ duration: 0.5 }}
                className=" black-container my-5 py-4"
              >
                <div className="stepper-header  mb-4">
                  <Stepper active={active}>
                    <Stepper.Step label="Seleccionar moneda"></Stepper.Step>
                    <Stepper.Step label="Confirmar pago"></Stepper.Step>
                    <Stepper.Step label="Detalles del pago"></Stepper.Step>
                  </Stepper>
                </div>
                <Divider />
                <div className="step-1">
                  <p className="buy-text mb-0">
                    Comprar <span>USDT</span>
                  </p>

                  <p className="ref-text">
                    Precio de referencia: {usdt?.toFixed(4) ?? 0} in USDT
                  </p>

                  <div className="row mt-1 pt-1  pay-form">
                    <div className="d-flex col-lg-5 enter-amount  align-items-end justify-content-between gap-4">
                      <div style={{ width: "100%" }}>
                        <p className="ref-text">Pagar</p>
                        <NumberInput
                          placeholder="Ingrese la cantidad"
                          allowNegative={false}
                          hideControls
                          {...form.getInputProps("amount")}
                        />
                      </div>
                      {/* <div style={{ width: "40%" }}>
                        <p className="ref-text">Seleccionar Moneda</p>
                        <Select
                          classNames={{
                            input: "select-dropdown",
                            dropdown: "select-dropdown",
                            option: "select-option",
                          }}
                          style={{ width: "100%" }}
                          placeholder=" CLP"
                          data={["CLP"]}
                          // placeholder="USD or CLP"
                          // data={["USD", "CLP"]}
                          comboboxProps={{
                            transitionProps: {
                              transition: "pop",
                              duration: 200,
                            },
                          }}
                          {...form.getInputProps("currency")}
                        />
                      </div> */}
                      {/* <p className="mb-0 press-enter">
                        Press Enter{" "}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="22"
                          height="22"
                          version="1.1"
                          viewBox="0 0 19.05 19.05"
                        >
                          <g opacity="1">
                            <g
                              fill="none"
                              fillOpacity="1"
                              stroke="#d8d8d8"
                              strokeDasharray="none"
                              strokeDashoffset="0"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeMiterlimit="4"
                              strokeOpacity="1"
                              strokeWidth="1"
                              paintOrder="markers fill stroke"
                            >
                              <path
                                d="M1.776 6.55S1.02 9.717 6.573 9.717"
                                style={{ fontVariationSettings: "normal" }}
                                stopColor="#000"
                              ></path>
                              <path
                                d="M6.573 9.718h10.733L14.623 6.55"
                                style={{ fontVariationSettings: "normal" }}
                                stopColor="#000"
                              ></path>
                              <path
                                d="M17.306 9.718l-2.683 2.783"
                                style={{ fontVariationSettings: "normal" }}
                                stopColor="#000"
                              ></path>
                            </g>
                          </g>
                        </svg>
                      </p> */}
                    </div>

                    <motion.div
                      initial={{ opacity: 0, y: 100 }}
                      whileInView={{ opacity: 1, y: 0 }}
                      transition={{ duration: 1 }}
                      className="col-lg-2 d-flex justify-content-center align-items-end"
                    >
                      {loading ? (
                        <Loader color="#fff" />
                      ) : (
                        <button
                          type="submit"
                          // onClick={conversionClick}
                          style={{ marginTop: "2rem" }}
                          className="yellow-btn py-2 px-4 convert-btn"
                        >
                          <svg
                            className="me-2"
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="18"
                            fill="none"
                            viewBox="0 0 46 46"
                          >
                            <path
                              stroke="#000"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2.769"
                              d="M17.461 41l-6.923-6.923 6.923-6.923"
                            ></path>
                            <path
                              stroke="#000"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2.769"
                              d="M41 25.77v5.538a2.77 2.77 0 01-2.77 2.769H10.538M28.538 5l6.923 6.923-6.923 6.923"
                            ></path>
                            <path
                              stroke="#000"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2.769"
                              d="M5 20.23v-5.538a2.77 2.77 0 012.77-2.769h27.692"
                            ></path>
                          </svg>{" "}
                          Convert
                        </button>
                      )}
                    </motion.div>

                    <div className="col-lg-5 enter-amount">
                      <p className="ref-text">Recibido</p>
                      <NumberInput
                        value={` ${usdt?.toFixed(4)}`}
                        allowNegative={false}
                        hideControls
                        readOnly
                      />
                    </div>
                  </div>

                  <div className="d-flex mt-5 justify-content-center">
                    <button
                      type="button"
                      onClick={continueHandler}
                      className="yellow-btn continue-btn py-2"
                    >
                      Continuar
                    </button>
                  </div>
                </div>
              </motion.div>
            </form>
          </div>
          <div className="container">
            <div className="row my-5 margin-x5  align-items-center">
              <motion.div
                initial={{ opacity: 0, x: 0 }}
                whileInView={{ opacity: 1, x: 0 }}
                transition={{ duration: 1 }}
                className="col-12 col-sm-12 col-md-12 col-lg-6"
              >
                <div className="about-heading my-4">
                  <p className="py-3 px-5" id="about-us">
                    INTERCAMBIO <span> USDT</span>
                  </p>
                </div>
                <p className="about-text">
                  {(setting && setting[0]?.usdt_exchange?.explore_text) || ""}
                  <EditButton
                    dataKey={"explore_text"}
                    pageName="usdt_exchange"
                    inputType="text"
                    colour="#000"
                  />
                </p>
              </motion.div>
              <motion.div
                initial={{ opacity: 0, x: 0 }}
                whileInView={{ opacity: 1, x: 0 }}
                transition={{ duration: 1 }}
                className="col-12 col-sm-12 col-md-12 col-lg-6 position-relative"
              >
                <img
                  width={"100%"}
                  src={
                    setting && baseURL + setting[0]?.usdt_exchange?.explore_img
                  }
                  alt="exchange usdt"
                />
                <div className="edit-btn ">
                  <EditButton
                    dataKey={"explore_img"}
                    pageName="usdt_exchange"
                    inputType="input"
                    colour="#000"
                  />
                </div>
              </motion.div>
            </div>
          </div>
          <Divider color="#FBC926" />
          <div className="container ">
            <div className="row my-5 margin-x5 py-4 align-items-center">
              <motion.div
                initial={{ opacity: 0, x: 0 }}
                whileInView={{ opacity: 1, x: 0 }}
                transition={{ duration: 1 }}
                className="col-12 col-sm-12 col-md-12 col-lg-6"
              >
                <div className="about-heading my-4">
                  <p className="py-3 px-4" id="about-us">
                    QUE <span>DICEN</span>
                  </p>
                </div>
                <p className="about-text">
                  {(setting &&
                    setting[0]?.usdt_exchange?.what_the_say_bold_text) ||
                    ""}
                  <EditButton
                    dataKey={"what_the_say_bold_text"}
                    pageName="usdt_exchange"
                    inputType="text"
                    colour="#000"
                  />
                </p>
                <p className="say-text">
                  {(setting && setting[0]?.usdt_exchange?.what_the_say_text) ||
                    ""}
                  <EditButton
                    dataKey={"what_the_say_text"}
                    pageName="usdt_exchange"
                    inputType="text"
                    colour="#000"
                  />
                </p>
                <div>
                  <div className="d-flex">
                    <Avatar size={"md"} className="me-2" />
                    <Avatar size={"md"} className="me-2" />
                    <Avatar size={"md"} className="me-2" />
                  </div>
                  <p className="review-counts mt-2">
                    <span className="me-2">30+</span>Customer Reviews
                  </p>
                </div>
              </motion.div>
              <motion.div
                initial={{ opacity: 0, x: 0 }}
                whileInView={{ opacity: 1, x: 0 }}
                transition={{ duration: 1 }}
                className="col-12 col-sm-12 col-md-12 col-lg-6"
              >
                <div className="qoute-box ps-3">
                  <div className="d-flex justify-content-end">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="42"
                      height="28"
                      fill="none"
                      viewBox="0 0 42 28"
                    >
                      <g clipPath="url(#clip0_1379_1836)">
                        <path
                          fill="#FCC70D"
                          d="M13.248 13.357H0l12.469-29.326h8.449l-7.67 29.326zm21.082 0H21.082l12.469-29.326H42l-7.67 29.326z"
                        ></path>
                      </g>
                      <defs>
                        <clipPath id="clip0_1379_1836">
                          <path
                            fill="#fff"
                            d="M0 0H42V42H0z"
                            transform="matrix(1 0 0 -1 0 28)"
                          ></path>
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                  <p className="about-text w-100">
                    {(setting && setting[0]?.usdt_exchange?.quote) || ""}
                    <EditButton
                      dataKey={"quote"}
                      pageName="usdt_exchange"
                      inputType="text"
                      colour="#000"
                    />
                  </p>
                  <p className="about-text w-100">
                    {" "}
                    Aplica un cargo por servicio del 8% al finalizar la compra.
                  </p>
                  <div className="d-flex">
                    <Avatar size={"md"} />
                    <div className="ms-3">
                      <p className="mb-0 author-name">Marc Piffaut</p>
                      <p className="mb-0 author-desc">Director, Company</p>
                    </div>
                  </div>
                </div>
              </motion.div>
            </div>
          </div>
          <Divider color="#FBC926" />
          <FAQs />
        </div>
      ) : (
        <BuyUsdt backFunc={goBack} />
      )}
    </motion.div>
  );
};

export default UsdtExchange;
