import React, { useRef, useState, useEffect } from "react";
import { IoMdSend } from "react-icons/io";
import { Avatar, TextInput, Tooltip } from "@mantine/core";
import robotAvatar from "../../assets/robotAvatar.png";
import { AnimatePresence, motion } from "framer-motion";
import { useAiChatStore } from "../../zustand/store/ChatStore";
// import EmojiPicker from "emoji-picker-react";
import { MdOutlineEmojiEmotions } from "react-icons/md";
import { useShallow } from "zustand/react/shallow";
import { convertUtcToAmpm } from "../../services/helpers";
import { baseURL } from "../../configs/axiosConfig";

const ChatBox: React.FC = () => {
  const { loading, chatId, sendChat } = useAiChatStore(
    useShallow((state) => state)
  );
  const [openChat, setOpenChat] = useState(false);
  const [openEmoji, setOpenEmoji] = useState(false);
  const [responseLoading, setResponseLoading] = useState(false);
  const [displayResponse, setDisplayResponse] = useState("");
  const [input, setInput] = useState<string>("");
  const [showMore, setShowMore] = useState<Boolean>(false);
  const [predefinedMsg, setDefinedMsg] = useState<string[]>([
    "What is Your Way Community?",
    "How can I learn about cryptocurrencies here?",
    "Can you assist with setting up a Binance wallet?",
    "Can you help me with buying USDT ?",
    "What educational resources do you offer?",
    "How do I join workshops or videoconferences?",
    "How do you ensure secure transactions?",
    "What support is available for crypto?",
    "How can I stay updated on crypto trends?",
  ]);

  const initialMsg: string =
    "Hey! I am Y.W.C bot, your AI assistant , you can ask me any thing or can have a fun conversation. How can i help you today? Feel free to ask.";

  const [allMessages, setAllMessages] = useState<any>([]);

  const ref = useRef<HTMLDivElement | null>(null);

  const handleSendChat = async (message: string) => {
    if (loading) {
      return;
    }
    if (message.trim() !== "" && !responseLoading) {
      const payload = {
        prompt: message,
        chatId: chatId,
      };
      const res = await sendChat(payload);

      const userMessage = { role: "user", content: message, date: new Date() };
      setAllMessages((prev: any) => [...prev, userMessage]);
      setInput("");

      setOpenEmoji(false);
      // sendChat({ prompt: message });
      // Trigger the AI response with typing animation
      triggerAIResponse(res?.aiResponse);
      // triggerAIResponse("New meessage here");
    }
  };

  const triggerAIResponse = (aiResponse: string) => {
    setAllMessages((prev: any) => [
      ...prev,
      { role: "asistance", content: aiResponse?.trim(), date: new Date() },
    ]);

    let i = -1;
    setDisplayResponse(""); // Clear previous response
    setResponseLoading(true);
    const intervalId = setInterval(() => {
      setDisplayResponse((prev) => prev + aiResponse[i]);
      ++i;
      if (i > aiResponse?.length - 2) {
        clearInterval(intervalId);
        setResponseLoading(false);
      }
    }, 20);
  };

  useEffect(() => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [allMessages, loading]);

  const [loadingText, setLoadingText] = useState("Loading.");
  useEffect(() => {
    // Change loading text every 500ms only when loading is true
    if (loading) {
      const interval = setInterval(() => {
        setLoadingText((prev) => {
          if (prev === "Loading...") return "Loading.";
          if (prev === "Loading.") return "Loading..";
          return "Loading...";
        });
      }, 500);

      return () => clearInterval(interval);
    }
  }, [loading]);

  return (
    <div className={openChat ? "chat-container" : "close-chat"}>
      <AnimatePresence>
        <motion.div className="message-box" transition={{ duration: 1.3 }}>
          <div className="profile" style={{ paddingBottom: 10 }}>
            <div
              style={{
                width: 60,
                height: 60,
              }}
            >
              <img
                src={baseURL + "/uploads/footerlogo.jpg"}
                width={60}
                height={60}
                alt="AI Assistant"
                style={{
                  outline: "2px solid #fbc926",
                  borderRadius: 99999,
                }}
              />
            </div>
            <p className="assist-name" style={{ paddingLeft: "0.8rem" }}>
              Y.W.C Bot
            </p>
          </div>
          <div className="messages py-2">
            {/* inital mSg */}
            <motion.div
              initial={{ opacity: 0, y: 10 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 1 }}
              className={`d-flex flex-col ${"justify-content-start"}`}
            >
              <div className={"prompt-box-left"} style={{ paddingBottom: 20 }}>
                <div className="prompt-message">
                  <p className="mb-0 prompt-content">{initialMsg}</p>
                </div>
                <p style={{ color: "#f2f2ff" }} className="data-text mb-0">
                  {convertUtcToAmpm(new Date())}
                </p>
              </div>
            </motion.div>

            {/* predefined msgs */}

            {predefinedMsg
              ?.slice(0, showMore ? predefinedMsg.length : 3)
              ?.map((value: any, i: number) => (
                <motion.div
                  key={i}
                  initial={{ opacity: 0, y: 10 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 1 }}
                  className={`d-flex flex-col ${"justify-content-start"}`}
                >
                  <div
                    className={"prompt-box-left-pre"}
                    style={{ paddingBottom: 20 }}
                  >
                    <div
                      className="prompt-message"
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        if (!loading) {
                          handleSendChat(value);
                        }
                      }}
                    >
                      <p className="mb-0 prompt-content">{value}</p>
                    </div>
                  </div>
                </motion.div>
              ))}

            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                width: "70%",
                marginTop: -15,
                paddingBottom: 20,
              }}
              className="flex"
            >
              <span
                style={{
                  color: "#fbc926",
                  fontSize: "1rem",
                  cursor: "pointer",
                }}
                onClick={() => setShowMore(!showMore)}
              >
                {showMore ? "Show less" : "Show more"}
              </span>
            </div>

            {allMessages?.map((value: any, i: number) => (
              <motion.div
                key={i}
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 1 }}
                ref={i === allMessages.length - 1 ? ref : null}
                className={`d-flex flex-col ${
                  value.role === "asistance"
                    ? "justify-content-start"
                    : "justify-content-end"
                }`}
              >
                <div
                  className={
                    value.role === "asistance"
                      ? "prompt-box-left"
                      : "prompt-box-right"
                  }
                  style={{ paddingBottom: 20 }}
                >
                  <div className="prompt-message">
                    {value.role === "asistance" &&
                    i === allMessages?.length - 1 &&
                    allMessages?.length > 1 ? (
                      <span>
                        {displayResponse}
                        {/* Add cursor animation if needed */}
                        {displayResponse?.length < value?.content?.length && (
                          <CursorSVG />
                        )}
                      </span>
                    ) : (
                      <p className="mb-0 prompt-content">{value?.content}</p>
                    )}
                  </div>
                  <p style={{ color: "#f2f2ff" }} className="data-text mb-0">
                    {convertUtcToAmpm(value?.date)}
                  </p>
                </div>
              </motion.div>
            ))}
            {loading && (
              <p
                style={{
                  color: "#fbc926",
                  fontSize: "1.2rem",
                  textAlign: "center",
                }}
              >
                {loadingText}
              </p>
            )}
            {/* <div className="d-flex justify-content-center">
              <Loader color="black" type="dots" />
            </div> */}
          </div>
          <div
            style={{ flexDirection: "row", margin: "20px 0" }}
            className="d-flex align-items-center g-2 input-box"
          >
            <TextInput
              style={{ border: "2px solid red" }}
              disabled={loading}
              size="md"
              value={input}
              className="w-100 border-0 search-input"
              placeholder="Type..."
              onChange={(e) => {
                if (input.length <= 300) {
                  setInput(e.target.value);
                }
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleSendChat(input);
                }
              }}
            />
            {/* <div className="mx-2">
              <Tooltip label="Emoji">
                <MdOutlineEmojiEmotions
                  className="emoji-icon"
                  size={20}
                  onClick={() => setOpenEmoji(!openEmoji)}
                />
              </Tooltip>
              {openEmoji && (
                <div className={"emoji-box"}>
                  <EmojiPicker
                    width={"300px"}
                    open={openEmoji}
                    onEmojiClick={(value) => {
                      setInput((prevInput) => prevInput + value?.emoji);
                      setOpenEmoji(false);
                    }}
                  />
                </div>
              )}
            </div> */}
            <Tooltip label="Send">
              <button
                disabled={loading}
                className="send-button"
                onClick={() => handleSendChat(input)}
              >
                <IoMdSend />
              </button>
            </Tooltip>
          </div>
        </motion.div>
      </AnimatePresence>
      <div
        className="chat-icon"
        style={{
          width: 64,
          height: 64,
          cursor: "pointer",
          borderRadius: "50%",
        }}
        onClick={() => setOpenChat(!openChat)}
      >
        <Avatar
          style={{ objectFit: "contain", width: "52px", height: "52px" }}
          src={baseURL + "/uploads/footerlogo.jpg"}
        />
      </div>
    </div>
  );
};

export default ChatBox;

const CursorSVG = () => {
  return (
    <svg
      viewBox="8 4 8 16"
      xmlns="http://www.w3.org/2000/svg"
      className="cursor"
    >
      <rect x="10" y="6" width="4" height="12" fill="#fff" />
    </svg>
  );
};
