import { Modal } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import React, { useEffect, useState } from "react";

interface GeneralModalProps {
  Component?: React.ComponentType<any>;
  opened: boolean | false;
  openModal: () => void;
  closeModal: () => void;
  loading: boolean | false;
  size: string | "";
  title?: string | "";
  content?: string;
  saveFunc?: ({ value }: any) => void;
  setting?: any;
  contentKey?: string;
}

const GeneralModal: React.FC<GeneralModalProps> = ({
  Component,
  opened,
  closeModal,
  loading,
  size,
  content,
  saveFunc,
  setting,
  contentKey,
}) => {
  const isMobile = useMediaQuery("(max-width: 50em)");

  return (
    <>
      <Modal
        opened={opened}
        onClose={closeModal}
        withCloseButton={false}
        transitionProps={{ transition: "fade", duration: 200 }}
        size={isMobile ? "100%" : size}
        centered
        className="general-modal"
      >
        <svg
          onClick={closeModal}
          className="close-modal"
          xmlns="http://www.w3.org/2000/svg"
          width="15"
          height="15"
          fill="none"
          viewBox="0 0 19 19"
        >
          <path
            fill="#000"
            fillOpacity="0.17"
            fillRule="evenodd"
            d="M.463.483a1.58 1.58 0 012.237 0l6.79 6.798L16.28.483a1.582 1.582 0 112.237 2.24l-6.79 6.796 6.79 6.798A1.584 1.584 0 0117.393 19a1.58 1.58 0 01-1.113-.445l-6.79-6.797-6.79 6.797A1.58 1.58 0 01.02 17.43a1.584 1.584 0 01.443-1.113l6.79-6.798-6.79-6.797a1.584 1.584 0 010-2.239z"
            clipRule="evenodd"
          ></path>
        </svg>
        {Component && (
          <Component
            content={content}
            contentKey={contentKey}
            saveFunc={({ value }: { value: unknown }) =>
              saveFunc && saveFunc({ value })
            }
            setting={setting}
          />
        )}
      </Modal>
    </>
  );
};

export default GeneralModal;
