import { createContext } from "react";
import { notifications } from "@mantine/notifications";
import moment from "moment";

interface ContextProps {
  data: string;
}

interface IMessage {
  withCloseButton: boolean;
  autoClose: number;
  color: string;
  message: string;
}
export const MyContext = createContext<ContextProps | undefined>(undefined);

export const successMessage = (msg: string) => {
  const options: IMessage = {
    withCloseButton: true,
    autoClose: 2000,
    color: "green",
    message: msg,
  };
  notifications.show(options);
};

export const errorMessage = (error: string) => {
  const options: IMessage = {
    withCloseButton: true,
    autoClose: 2000,
    color: "red",
    message: error,
  };
  notifications.show(options);
};
export const warningMessage = (msg: string) => {
  const options: IMessage = {
    withCloseButton: true,
    autoClose: 2000,
    color: "yellow",
    message: msg,
  };
  notifications.show(options);
};
export function convertUtcToAmpm(utcTimeStr: string | Date) {
  return moment.utc(utcTimeStr).local().format("h:mm A");
}
