import { create } from "zustand";
import custAxios, {
  attachToken,
  attachTokenWithFormAxios,
  formAxios,
} from "../../configs/axiosConfig";

interface IUserStore {
  user: any;
  singleUserQuizes: any;
  loading: boolean;
  userData: () => Promise<any>;
  updateUserData: (value: any) => Promise<any>;
  quizesOfSingleUser: (
    id: any,
    sortBy?: string | null,
    searchBy?: string | null
  ) => Promise<any>;
  quizesOfLoginUser: (
    sortBy?: string | null,
    searchBy?: string | null
  ) => Promise<any>;
  //   loginFunc: (values: any) => Promise<any>;
}

export const useUserStore = create<IUserStore>((set) => ({
  user: {},

  singleUserQuizes: [],
  loading: false,
  userData: async () => {
    try {
      set({
        loading: true,
      });

      attachToken();
      const response = await custAxios.get("/user/");

      set({
        loading: false,
        user: response?.data?.data?.user,
      });
      return response.data;
    } catch (error) {
      set({
        loading: false,
      });
      console.error("Error fetching data:", error);
    }
  },
  updateUserData: async (value) => {
    try {
      set({
        loading: true,
      });

      attachTokenWithFormAxios();
      const response = await formAxios.put("/user/", value);

      set({
        loading: false,
        user: response?.data?.data?.user,
      });
      return response.data;
    } catch (error) {
      set({
        loading: false,
      });
      console.error("Error fetching data:", error);
    }
  },
  quizesOfSingleUser: async (id, sortBy, searchBy) => {
    try {
      set({
        loading: true,
      });

      attachToken();
      const response = await custAxios.post(
        `user/quizzes-of-single-user/${id}`,
        sortBy
          ? { sortFilter: sortBy }
          : searchBy
          ? { search: searchBy }
          : sortBy && searchBy
          ? { sortFilter: sortBy, search: searchBy }
          : {}
      );

      set({
        loading: false,
        singleUserQuizes: response?.data?.data?.leaderboard,
      });
      return response.data;
    } catch (error) {
      set({
        loading: false,
      });
      console.error("Error fetching data:", error);
    }
  },
  quizesOfLoginUser: async (searchBy) => {
    try {
      set({
        loading: true,
      });

      attachToken();
      const response = await custAxios.post(`user/quizzes`, {
        // sortFilter: sortBy,
        searchFilter: searchBy,
      });

      set({
        loading: false,
        singleUserQuizes: response?.data?.data?.leaderboard,
      });
      return response.data;
    } catch (error) {
      set({
        loading: false,
      });
      console.error("Error fetching data:", error);
    }
  },
}));
