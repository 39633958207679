import React from "react";
import { Image, Loader, PasswordInput, TextInput } from "@mantine/core";
import logo from "../../assets/logo.png";
import login from "../../assets/login.png";
import { useAuth } from "../../services/hooks";
import { useAuthStore } from "../../zustand/store/AurthStore";
import { useShallow } from "zustand/react/shallow";
import { useForm } from "@mantine/form";
import { Link, useNavigate } from "react-router-dom";
import { useUserStore } from "../../zustand/store/UserStore";
import { motion } from "framer-motion";
import { baseURL } from "../../configs/axiosConfig";
import { useSettingStore } from "../../zustand/store/SettingStore";

const Login: React.FC = () => {
  const { switchForm } = useAuth();
  const navigate = useNavigate();

  const [getUserDataApi] = useUserStore(
    useShallow((state) => [state.userData])
  );
  const [setting, settingGet] = useSettingStore(
    useShallow((state) => [state.setting, state.settingGet])
  );

  const [loginFunc, loading] = useAuthStore(
    useShallow((state) => [state.loginFunc, state.loading])
  );

  const form = useForm({
    initialValues: { email: "", password: "" },
    validate: {
      email: (value) => (/^\S+@\S+$/.test(value) ? null : "Invalid email"),
      password: (value) => (!value ? "You must have 8 characters" : null),
    },
  });

  const handleSubmit = async (values: any) => {
    const data ={
      email:values?.email?.toLowerCase(),
      password:values?.password
    }
    const res = await loginFunc(data);

    if (res?.success === true) {
      getUserDataApi();
      form.reset();
      if (res?.data?.userData?.role === "user") {
        navigate("/");
      } else {
        navigate("/dashboard");
      }
    }
  };
  return (
    <motion.div
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      transition={{ duration: 1 }}
      className="signup-conatiner "
    >
      <div className="row ">
        <div className="col-md-12 col-lg-6">
          <div className="login-img-box ">
            <Image
              src={baseURL + setting[0]?.websiteSetting?.logo || logo}
              w={100}
              h={100}
              alt="logo"
              fallbackSrc={logo}
            />
          </div>
        </div>
        <div className="col-md-12 col-lg-6">
          <form onSubmit={form.onSubmit(handleSubmit)}>
            <p className="mt-5 register-title text-uppercase mb-1">
              Bienvenido <span>a Your Way Community</span>
            </p>
            {/* <p className="mt-5 register-title text-uppercase mb-1">
              INICIAR <span>SESIÓN</span>
            </p> */}
            {/* <p className="checkout-text text-start mb-0  ">¡Hola MARC! 👋 </p> */}
            {/* <p className="login-desc text-start mb-0  ">
              Bienvenid@ a Transbank 🎉
            </p>{" "}
            <p className="login-desc text-start mb-2  ">
              Activa tu producto Webpay y comienza a aumentar tus ventas. ¡Mira
              el video que hemos preparado para guiarte en esta activación! 🎥
            </p> */}
            <div>
              <label className="form-label mt-2">
                <p className="form-label-text mb-2">Correo electrónico</p>
                <TextInput size="md" {...form.getInputProps("email")} />
              </label>
            </div>
            <div>
              <label className="form-label mt-2">
                <p className="form-label-text mb-2">Contraseña</p>
                <PasswordInput size="md" {...form.getInputProps("password")} />
              </label>
            </div>
            <p className="checkout-text text-end">
              <span
                onClick={() => switchForm("forgotPassword")}
                className="cursor-pointer"
              >
                Forgot Password
              </span>
            </p>
            <button
              disabled={loading ? true : false}
              className="py-2 my-3 create-account text-uppercase"
            >
              {loading ? (
                <Loader size={"sm"} color="white" />
              ) : (
                "INICIAR SESIÓN"
              )}
            </button>
            <p className="checkout-text text-center my-3 mb-3 pb-1 ">
              No tienes una cuenta{" "}
              <span
                className="cursor-pointer"
                onClick={() => switchForm("signup")}
              >
                {" "}
                Crea tu cuenta
              </span>
            </p>
          </form>
        </div>
      </div>
    </motion.div>
  );
};

export default Login;
