import React from "react";
import aboutImg from "../../assets/about.png";
import EditButton from "../layout/EditButton";
import { baseURL } from "../../configs/axiosConfig";
import { motion } from "framer-motion";

const AboutUs: React.FC<{ dataContent: any }> = ({ dataContent }) => {
  return (
    <div className="container my-5 py-5">
      <div className="mt-5 row  justify-content-center">
        <div className="col-md-0 col-lg-6 d-flex justify-content-center">
          <motion.div
            initial={{ opacity: 0, x: 0 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 1 }}
            className="d-flex"
            style={{ width: "75%" }}
          >
            <img
              width={"100%"}
              src={
                dataContent && dataContent[0]?.home?.about_us_img
                  ? baseURL + dataContent[0]?.home?.about_us_img
                  : aboutImg
              }
              className="about-img"
            />
            <div className="mt-3">
              <EditButton
                dataKey="about_us_img"
                pageName="home"
                inputType="img"
                colour="#000"
              />{" "}
            </div>
          </motion.div>
        </div>
        <motion.div
          initial={{ opacity: 0, x: 0 }}
          whileInView={{ opacity: 1, x: 0 }}
          transition={{ duration: 1 }}
          className="col-md-12 col-lg-6"
        >
          <div className="about-heading my-4">
            <p className="py-3 px-4" id="about-us">
              Sobre <span>nosotros</span>
            </p>
          </div>
          <p className="about-text">
            {(dataContent && dataContent[0]?.home?.about_us) || ""}
            <EditButton
              dataKey="about_us"
              pageName="home"
              inputType="text"
              colour="#000"
            />
          </p>
          <button className="get-started learn-more-about px-4 py-2 text-uppercase">
            aprende más
          </button>
        </motion.div>
      </div>
    </div>
  );
};

export default AboutUs;
