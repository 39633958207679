import React, { useEffect } from "react";
import QuizVideoSection from "../components/quizHub/quizVedioSection";
import QuizVedio from "../components/cards/quizVedio";
import { useQuizStore } from "../zustand/store/QuizStore";
import { useShallow } from "zustand/react/shallow";
import { Loader } from "@mantine/core";
import { getToken, useAuth } from "../services/hooks";
import { useDisclosure } from "@mantine/hooks";
import GeneralModal from "../components/modal/GeneralModal";
import Login from "../components/auth/Login";
import Register from "../components/auth/Register";

const QuizHub: React.FC = () => {
  const token = getToken();
  const [quizArray, getQuizs, loading] = useQuizStore(
    useShallow((state) => [state.quiz, state.getQuizs, state.loading])
  );

  useEffect(() => {
    const res = getQuizs();
  }, []);

  const [opened, { open, close }] = useDisclosure();
  const { currentForm } = useAuth();

  return (
    <div>
      {token ? (
        <div className="my-5">
          <div className="container winner-heading">
            <p className="py-3 px-5">
              CENTRO DE <span> PRUEBAS</span>
            </p>
          </div>
          <QuizVideoSection data={quizArray[quizArray.length - 1]} />
          {loading ? (
            <div className="d-flex justify-content-center">
              <Loader color="black" />
            </div>
          ) : (
            <div className="container">
              <div className="row">
                {quizArray.map((value?: any, i?: number) => {
                  return (
                    <div key={i} className="col-12 col-sm-6 col-md-6 col-lg-4">
                      <QuizVedio data={value} />
                    </div>
                  );
                })}
              </div>
              {quizArray.length > 9 && (
                <div className="my-5 d-flex justify-content-center">
                  <button className="py-2 px-5  load-more">LOAD MORE</button>
                </div>
              )}
            </div>
          )}
        </div>
      ) : (
        <div className="h-100 my-5 pt-5 d-flex justify-content-center align-items-center">
          <div className="result-not-found mt-5">
            <p className="not-found-text mb-0">no inicie sesión todavía</p>
            <p className="not-found-paragraph">
              Inicia sesión para ver detalles
            </p>
            <div className="d-flex justify-content-center">
              <button
                onClick={open}
                className="get-started my-2 px-4 py-2 text-uppercase"
              >
                EMPEZAR
              </button>
            </div>
          </div>
        </div>
      )}
      <GeneralModal
        opened={opened}
        loading={true}
        title={"sign up"}
        Component={currentForm === "login" ? Login : Register}
        openModal={open}
        closeModal={close}
        size={"80%"}
      />
    </div>
  );
};

export default QuizHub;
