import React, { useRef, useState, useEffect } from "react";
import { motion } from "framer-motion";
import { useLocation, useParams } from "react-router-dom";
import { useShallow } from "zustand/react/shallow";
import { useAiChatStore } from "../../zustand/store/ChatStore";
import { convertUtcToAmpm } from "../../services/helpers";
import { Loader } from "@mantine/core";
const SingleChat: React.FC = () => {
  const limit = 9999999;
  const [currentPage, setCurrentPage] = useState<number>(1);
  const { id } = useParams();

  const location = useLocation()


  const ref = useRef<HTMLDivElement | null>(null);
  return (
    <div className="p-4">
      <div className="messages">
        
         { location?.state?.map((value: any, i: number) => (
            <motion.div
              key={i}
              initial={{ opacity: 0, y: 10 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 1 }}
              ref={i === location?.state?.length - 1 ? ref : null}
              className={`d-flex flex-col ${
                value.sender === "ai"
                  ? "justify-content-start"
                  : "justify-content-end"
              }`}
            >
              {/* {console.log(value)} */}
              <div
                className={
                  value.sender === "ai" ? "prompt-box-left" : "prompt-box-right"
                }
              >
                <div className="prompt-message">
                  <p className="mb-0 prompt-content">{value?.message}</p>
                </div>
                <p className="data-text mb-0">
                  {convertUtcToAmpm(value?.createdAt)}
                </p>
              </div>
            </motion.div>
          ))}
        
      </div>
    </div>
  );
};

export default SingleChat;
