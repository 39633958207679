import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import anime from "animejs";
import { useNavigate } from "react-router-dom";
import { useShallow } from "zustand/react/shallow";
import { useUserStore } from "../../zustand/store/UserStore";
import { useDashboardStore } from "../../zustand/store/DashboardStore";
import { Loader } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";

const BarChart: React.FC = () => {
  const navigate = useNavigate();
  const matches = useMediaQuery("(max-width: 500px)");
  const [userCount, userCountGraph, loading] = useDashboardStore(
    useShallow((state) => [
      state.userCount,
      state.userCountGraph,
      state.loading,
    ])
  );

  useEffect(() => {
    userCountGraph();
    anime({
      targets: ".chart",
      opacity: 1,

      easing: "easeInOutQuad",
    });
  }, []);

  const sorted = userCount.sort((a: any, b: any) => a.day - b.day);
  const counts = sorted.map((value: any) => value.count.toString());

  const [chartData, setChartData] = useState<any>({
    series: [
      {
        name: "User Growth",
        data: counts,
      },
    ],
    options: {
      chart: {
        height: 400,
        type: "bar",
      },
      plotOptions: {
        bar: {
          borderRadius: 20,
          width: 10,
          borderTopLeftRadius: 20,
          dataLabels: {
            position: "top", // top, center, bottom
          },
        },
      },
      stroke: {
        curve: "smooth",
        colors: ["#000"],

        width: 2,
      },
      fill: {
        type: "gradient",
        gradient: {
          shadeIntensity: 1,
          inverseColors: false,
          opacityFrom: 1,
          opacityTo: 0,
          stops: [100],
        },
      },
      //   colors: ["#fbc926", "#000"],
      colors: ["#fbc926"],
      dataLabels: {
        enabled: true,
        formatter: function (val: any) {
          return val;
        },
        offsetY: -20,
        style: {
          fontSize: "12px",
          colors: ["#000"],
        },
      },
      xaxis: {
        categories: ["Sun", "Mon", "Tue", "Wed", "Thr", "Fri", "Sat"],
        position: "top",
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        crosshairs: {
          show: false,
          fill: {
            type: "gradient",
            gradient: {
              colorFrom: "#000",
              colorTo: "#fff",
              stops: [0, 100],
              opacityFrom: 0.4,
              opacityTo: 0.5,
            },
          },
        },
        tooltip: {
          enabled: false,
        },
      },
      yaxis: {
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          show: false,
          formatter: function (val: any) {
            return val;
          },
        },
      },
    },
  });

  return (
    <div
      className="chart overflow-hidden"
      style={{ opacity: 0, overflow: "hidden" }}
    >
      <div className="d-flex justify-content-center">
        <div className="chart-heading my-4">
          <p className="py-3 px-4" id="about-us">
            NÚMERO DE <span>USUARIOS EN CRECIMIENTO</span>
          </p>
        </div>
      </div>
      {loading ? (
        <div className=" h-100 d-flex justify-content-center align-items-center">
          <Loader color="black" />
        </div>
      ) : (
        <div id="chart" className="d-flex justify-content-center">
          <ReactApexChart
            options={chartData.options}
            series={chartData?.series}
            type="bar"
            height={400}
            width={matches ? 350 : 500}
          />
        </div>
      )}
      <div id="html-dist"></div>
    </div>
  );
};

export default BarChart;
