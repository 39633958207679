import React from "react";
import { useNavigate } from "react-router-dom";
import { Image } from "@mantine/core";
import { baseURL } from "../../../configs/axiosConfig";
import { useShallow } from "zustand/react/shallow";
import { useQuizStore } from "../../../zustand/store/QuizStore";

interface IQuizData {
  createdAt: string;
  endDate: string;
  question: {
    key: string;
    que: string;
    _id: string;
  }[];
  startDate: string;
  thumbnail: string;
  title: string;
  updatedAt: string;
  video: string;
  __v: number;
  _id: string;
}

const DashboardQuizVideo: React.FC<{ data: IQuizData }> = ({ data }) => {
  const [getQuizs, deleteSingleQuizById, loading] = useQuizStore(
    useShallow((state) => [
      state.getQuizs,
      state.deleteSingleQuizById,
      state.loading,
    ])
  );
  const navigate = useNavigate();

  const handleDelete = async () => {
    const res = await deleteSingleQuizById(data?._id);
    if (res.success === true) {
      getQuizs();
    }
  };
  return (
    <div className="mt-3 dashboard-quiz-video">
      <div className="course-video">
        <div className="video-action">
          <span onClick={handleDelete}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="16"
              fill="none"
              viewBox="0 0 12 16"
            >
              <path
                fill="#fff"
                d="M8.667 2.042V1.22A1.22 1.22 0 007.446 0H4.55c-.67 0-1.217.546-1.217 1.22v.822H0v.625h.88l.983 12.112c0 .675.545 1.221 1.22 1.221h5.875a1.22 1.22 0 001.221-1.22l.971-12.113H12v-.625H8.667zM3.958 1.22c0-.338.259-.596.596-.596H7.45c.337 0 .596.258.596.596v.82H3.963v-.82h-.005zM3.78 14.04L3.333 4h.604l.455 10.042h-.613zm2.513 0h-.584V4h.584v10.042zm1.929 0h-.609L8.063 4h.605L8.22 14.042z"
              ></path>
            </svg>
          </span>
          <span
            onClick={() => navigate(`/dashboard/course-detail/${data?._id}`)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="17"
              height="14"
              fill="none"
              viewBox="0 0 17 14"
            >
              <path
                fill="#fff"
                d="M8.542 8.773a1.773 1.773 0 100-3.545 1.773 1.773 0 000 3.545z"
              ></path>
              <path
                fill="#fff"
                fillRule="evenodd"
                d="M.084 7A8.867 8.867 0 0117 7 8.867 8.867 0 01.084 7zm12.004 0a3.546 3.546 0 11-7.092 0 3.546 3.546 0 017.092 0z"
                clipRule="evenodd"
              ></path>
            </svg>
          </span>
        </div>
        <Image
          width="100%"
          height={"200px"}
          src={baseURL + data?.thumbnail}
          alt="img"
          fallbackSrc={`https://placehold.co/600x400/orange/white?text=${data?.title}`}
        />
      </div>
      <div className="p-2">
        <p className="video-title mb-0 text-capitalize">{data?.title}</p>
        {/* <p className="video-text">
          Unlock the secrets of becoming a successful business magnate in our
          riveting video ....
        </p> */}
        <div className="d-flex justify-content-end align-items-center">
          <span
            className="see-detail"
            onClick={() => navigate(`/dashboard/course-detail/${data?._id}`)}
          >
            Ver detalles
          </span>
        </div>
      </div>
    </div>
  );
};

export default DashboardQuizVideo;
