import React, { useEffect, useState } from "react";
import currencyBg from "../assets/currency-bg.png";
import HeroSection from "../components/home/HeroSection";
import VideoSection from "../components/home/VideoSection";
import AboutUs from "../components/home/AboutUs";
import TopWinners from "../components/home/TopWinners";
import FAQs from "../components/home/FAQs";
import CryptoCurrencyTable from "../components/tables/CryptoCurrencyTable";
import { useShallow } from "zustand/react/shallow";
import { useAuthStore } from "../zustand/store/AurthStore";
import { useQuizStore } from "../zustand/store/QuizStore";
import { getToken } from "../services/hooks";
import { useSettingStore } from "../zustand/store/SettingStore";
import { useFaqStore } from "../zustand/store/FaqsStore";
import Newsletter from "../components/layout/Newsletter";
import { useDashboardStore } from "../zustand/store/DashboardStore";
import { motion } from "framer-motion";
import AdvertiseVideo from "../components/home/AdvertiseVideo";

const Home: React.FC = () => {
  const [showVideoSec, setShowVideoSec] = useState<boolean>(false);
  const [quizArray, getQuizs, loading] = useQuizStore(
    useShallow((state) => [state.quiz, state.getQuizs, state.loading])
  );
  const [userdata] = useAuthStore(useShallow((state) => [state.user]));
  const [setting] = useSettingStore(useShallow((state) => [state.setting]));
  const [getFaqs] = useFaqStore(useShallow((state) => [state.getFaqs]));
  const [userCountGraph] = useDashboardStore(
    useShallow((state) => [state.userCountGraph])
  );
  useEffect(() => {
    const res = getQuizs();
    const res2 = getFaqs();
    const res3 = userCountGraph();
  }, []);

  useEffect(() => {
    const token = getToken();

    if (token) {
      setShowVideoSec(true);
    } else {
      setShowVideoSec(false);
    }
  }, [userdata]);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      transition={{ duration: 1 }}
    >
      <HeroSection show={showVideoSec} dataContent={setting} />
      {showVideoSec && quizArray.length > 0 && (
        <VideoSection
          data={quizArray[quizArray.length - 1]}
          dataContent={setting}
        />
      )}

      <div className="currency-bg ">
        <img width={"100%"} src={currencyBg} alt="currecy" />
        <motion.div
          initial={{ opacity: 0, y: 100 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 1 }}
        >
          <CryptoCurrencyTable />
        </motion.div>
      </div>
      <AdvertiseVideo />

      <AboutUs dataContent={setting} />
      {/* <TopWinners /> */}
      <FAQs />
      <Newsletter />
    </motion.div>
  );
};

export default Home;
