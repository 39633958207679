import React, { useEffect, useState } from "react";
import profile from "../../assets/first-winner.png";
import { Loader, TextInput } from "@mantine/core";
import { useShallow } from "zustand/react/shallow";
import { useUserStore } from "../../zustand/store/UserStore";
import { baseURL } from "../../configs/axiosConfig";
import { useSettingStore } from "../../zustand/store/SettingStore";
import { pageContentData } from "../../data/data";
import { motion } from "framer-motion";
import SetChatContext from "../../components/aiChat/setChatContext";
import GeneralModal from "../../components/modal/GeneralModal";
import { useDisclosure, useMediaQuery } from "@mantine/hooks";
const WebsiteSetting: React.FC = () => {
  const [userdata, updateUserData] = useUserStore(
    useShallow((state) => [state.user, state.updateUserData])
  );
  const [opened, { open, close }] = useDisclosure();
  const matches = useMediaQuery("(max-width: 992px)");

  const [setting, settingAddAndEdit, settingGet, settingImage, loading] =
    useSettingStore(
      useShallow((state) => [
        state.setting,
        state.settingAddAndEdit,
        state.settingGet,
        state.settingImage,
        state.loading,
      ])
    );

  const [editField, setEditField] = useState("");

  const [favFile, setFavFile] = useState("");
  const [profileFile, setProfileFile] = useState("");
  const [profileData, setProfileData] = useState<any>();

  useEffect(() => {
    settingGet();
  }, []);

  useEffect(() => {
    if (setting.length > 0) {
      setProfileData(setting[0]?.websiteSetting || {});
    }
  }, [setting]);

  const handleEdit = (e: any, value: string) => {
    e.preventDefault();
    setEditField(value);
  };

  const handleCancel = () => {
    setEditField("");
  };

  const handleLogoImage = async (e: any) => {
    const file = e.target.files?.[0];
    if (file) {
      const formData = new FormData();
      formData.append("image", file);

      //   updateUserData(formData);
      const res = await settingImage(formData);
      if (res) {
        const updatedPageContentData = setting?.map((item: any) => {
          return {
            ...item,
            websiteSetting: {
              ...item.websiteSetting,
              logo: res?.data?.image,
            },
          };
        });

        const res2 = await settingAddAndEdit({
          content: updatedPageContentData,
        });
        if (res2.success === true) {
          settingGet();
        }
      }

      URL.createObjectURL(file);
      // setProfileUrl(newImageUrl);
      setProfileFile(profileFile);
    }
  };

  const handlefaviconImage = async (e: any) => {
    const file = e.target.files?.[0];
    if (file) {
      const formData = new FormData();
      formData.append("image", file);

      //   updateUserData(formData);

      const res = await settingImage(formData);

      if (res?.success) {
        const updatedPageContentData = setting?.map((item: any) => {
          return {
            ...item,
            websiteSetting: {
              ...item.websiteSetting,
              favicon: res?.data?.image,
            },
          };
        });
        const res2 = await settingAddAndEdit({
          content: updatedPageContentData,
        });
        if (res2.success === true) {
          settingGet();
        }
      }

      URL.createObjectURL(file);
      // setProfileUrl(newImageUrl);
      setFavFile(favFile);
    }
  };

  const openFileInput = () => {
    // Trigger the click event of the file input
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  const openFileInput2 = () => {
    // Trigger the click event of the file input
    if (inputRef2.current) {
      inputRef2.current.click();
    }
  };
  const handleFulName = async (e: any) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("websiteName", profileData?.websiteName);

    const updatedPageContentData = setting?.map((item: any) => {
      return {
        ...item,
        websiteSetting: {
          ...item.websiteSetting,
          websiteName: profileData?.websiteName,
        },
      };
    });

    const res = await settingAddAndEdit({ content: updatedPageContentData });

    if (res.success === true) {
      settingGet();
      handleCancel();
    }
  };

  const handleWebsiteDesc = async (e: any) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("websitedesc", profileData?.websitedesc);

    const updatedPageContentData = setting?.map((item: any) => {
      return {
        ...item,
        websiteSetting: {
          ...item.websiteSetting,
          websitedesc: profileData?.websitedesc,
        },
      };
    });

    const res = await settingAddAndEdit({ content: updatedPageContentData });
    if (res.success === true) {
      settingGet();
      handleCancel();
    }
  };

  const handleInstaLink = async (e: any) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("instagram", profileData?.instagram);

    const updatedPageContentData = setting?.map((item: any) => {
      return {
        ...item,
        websiteSetting: {
          ...item.websiteSetting,
          instagram: profileData?.instagram,
        },
      };
    });

    const res = await settingAddAndEdit({ content: updatedPageContentData });
    if (res.success === true) {
      settingGet();
      handleCancel();
    }
  };

  const handleLinkedinLink = async (e: any) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("linkedin", profileData?.linkedin);

    const updatedPageContentData = setting?.map((item: any) => {
      return {
        ...item,
        websiteSetting: {
          ...item.websiteSetting,
          linkedin: profileData?.linkedin,
        },
      };
    });

    const res = await settingAddAndEdit({ content: updatedPageContentData });
    if (res.success === true) {
      settingGet();
      handleCancel();
    }
  };

  const handleTwitterLink = async (e: any) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("twitter", profileData?.twitter);

    const updatedPageContentData = setting?.map((item: any) => {
      return {
        ...item,
        websiteSetting: {
          ...item.websiteSetting,
          twitter: profileData?.twitter,
        },
      };
    });

    const res = await settingAddAndEdit({ content: updatedPageContentData });
    if (res.success === true) {
      settingGet();
      handleCancel();
    }
  };

  // Create a ref for the file input
  const inputRef = React.useRef<HTMLInputElement | null>(null);
  const inputRef2 = React.useRef<HTMLInputElement | null>(null);
  return (
    <>
      {loading ? (
        <div className=" h-100 d-flex justify-content-center align-items-center">
          <Loader color="black" />
        </div>
      ) : (
        <motion.form
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 1 }}
          className="setting-form pb-5"
        >
          <div className=" my-4 py-4 single-field">
            <p className="form-label-text">Logotipo del sitio web</p>
            <div className="d-flex justify-content-between align-items-center">
              <img
                className="profile-img "
                width={80}
                height={80}
                src={
                  setting[0]?.websiteSetting?.logo
                    ? baseURL + setting[0]?.websiteSetting?.logo
                    : profile
                }
                alt="profile"
              />
              <div className="d-flex justify-content-end flex-wrap">
                <label htmlFor="image-input ">
                  <input
                    className="d-none"
                    ref={inputRef}
                    id="image-input"
                    type="file"
                    accept="image/*"
                    onChange={(e) => {
                      handleLogoImage(e);
                    }}
                  />
                  <button
                    onClick={openFileInput}
                    type="button"
                    className="yellow-btn mt-1 px-4 py-2 ms-2"
                  >
                    Cambiar foto
                  </button>
                </label>
              </div>
            </div>
          </div>

          <div className="pb-3 single-field">
            <p className="form-label-text">favicon del sitio web</p>
            <div className="d-flex justify-content-between align-items-center">
              <img
                className="profile-img "
                width={80}
                height={80}
                src={
                  setting[0]?.websiteSetting?.favicon
                    ? baseURL + setting[0]?.websiteSetting?.favicon
                    : profile
                }
                alt="profile"
              />
              <div className="d-flex justify-content-end flex-wrap">
                <label htmlFor="image-input ">
                  <input
                    className="d-none"
                    ref={inputRef2}
                    id="image-input"
                    type="file"
                    accept="image/*"
                    onChange={(e) => {
                      handlefaviconImage(e);
                    }}
                  />
                  <button
                    onClick={openFileInput2}
                    type="button"
                    className="yellow-btn mt-1 px-4 py-2 ms-2"
                  >
                    Cambiar foto
                  </button>
                </label>
              </div>
            </div>
          </div>

          <div
            className={
              editField === "website name"
                ? "  py-3 single-field"
                : "  py-3 single-field-readonly"
            }
          >
            <p className="form-label-text mb-0">Nombre del Sitio Web</p>
            <div
              className={`d-flex justify-content-between align-items-center ${
                editField === "first name" && "field-on-mobile"
              }`}
            >
              <TextInput
                size="md"
                variant="unstyled"
                value={profileData?.websiteName}
                readOnly={editField === "website name" ? false : true}
                onChange={(e) => {
                  setProfileData({
                    ...profileData,
                    websiteName: e.target.value,
                  });
                }}
              />
              {editField === "website name" ? (
                <div className="ms-3 field-action-onMobile d-flex justify-content-end">
                  <button
                    type="button"
                    onClick={handleCancel}
                    className="black-btn px-4 mt-1 py-2"
                  >
                    Cancelar
                  </button>
                  <button
                    onClick={handleFulName}
                    type="button"
                    className="mt-1 yellow-btn px-4 py-2 ms-2"
                  >
                    Ahorrar{" "}
                  </button>
                </div>
              ) : (
                <div className="ms-3 w-100 d-flex justify-content-end">
                  <button
                    type="button"
                    onClick={(e) => handleEdit(e, "website name")}
                    className="yellow-btn px-4 py-2"
                  >
                    Editar
                  </button>
                </div>
              )}
            </div>
          </div>

          <div
            className={
              editField === "website desc"
                ? "  py-3 single-field"
                : "  py-3 single-field-readonly"
            }
          >
            <p className="form-label-text mb-0">Descripcion del Sitio Web</p>
            <div
              className={`d-flex justify-content-between align-items-center ${
                editField === "website desc" && "field-on-mobile"
              }`}
            >
              <TextInput
                size="md"
                variant="unstyled"
                value={profileData?.websitedesc}
                readOnly={editField === "website desc" ? false : true}
                onChange={(e) => {
                  setProfileData({
                    ...profileData,
                    websitedesc: e.target.value,
                  });
                }}
              />
              {editField === "website desc" ? (
                <div className="ms-3 field-action-onMobile d-flex justify-content-end">
                  <button
                    type="button"
                    onClick={handleCancel}
                    className="black-btn px-4 mt-1 py-2"
                  >
                    Cancelar
                  </button>
                  <button
                    onClick={handleWebsiteDesc}
                    type="button"
                    className="mt-1 yellow-btn px-4 py-2 ms-2"
                  >
                    Ahorrar{" "}
                  </button>
                </div>
              ) : (
                <div className="ms-3 w-100 d-flex justify-content-end">
                  <button
                    type="button"
                    onClick={(e) => handleEdit(e, "website desc")}
                    className="yellow-btn px-4 py-2"
                  >
                    Editar
                  </button>
                </div>
              )}
            </div>
          </div>

          <div
            className={
              editField === "instgram"
                ? "  py-3 single-field"
                : "  py-3 single-field-readonly"
            }
          >
            <p className="form-label-text mb-0">Enlace de instagram</p>
            <div
              className={`d-flex justify-content-between align-items-center ${
                editField === "instgram" && "field-on-mobile"
              }`}
            >
              <TextInput
                size="md"
                variant="unstyled"
                value={profileData?.instagram}
                readOnly={editField === "instgram" ? false : true}
                onChange={(e) => {
                  setProfileData({
                    ...profileData,
                    instagram: e.target.value,
                  });
                }}
              />
              {editField === "instgram" ? (
                <div className="ms-3 field-action-onMobile d-flex justify-content-end">
                  <button
                    type="button"
                    onClick={handleCancel}
                    className="black-btn px-4 mt-1 py-2"
                  >
                    Cancelar
                  </button>
                  <button
                    onClick={handleInstaLink}
                    type="button"
                    className="mt-1 yellow-btn px-4 py-2 ms-2"
                  >
                    Ahorrar{" "}
                  </button>
                </div>
              ) : (
                <div className="ms-3 w-100 d-flex justify-content-end">
                  <button
                    type="button"
                    onClick={(e) => handleEdit(e, "instgram")}
                    className="yellow-btn px-4 py-2"
                  >
                    Editar
                  </button>
                </div>
              )}
            </div>
          </div>

          <div
            className={
              editField === "linkedin"
                ? "  py-3 single-field"
                : "  py-3 single-field-readonly"
            }
          >
            <p className="form-label-text mb-0">Enlace de tiktok</p>
            <div
              className={`d-flex justify-content-between align-items-center ${
                editField === "linkedin" && "field-on-mobile"
              }`}
            >
              <TextInput
                size="md"
                variant="unstyled"
                value={profileData?.linkedin}
                readOnly={editField === "linkedin" ? false : true}
                onChange={(e) => {
                  setProfileData({
                    ...profileData,
                    linkedin: e.target.value,
                  });
                }}
              />
              {editField === "linkedin" ? (
                <div className="ms-3 field-action-onMobile d-flex justify-content-end">
                  <button
                    type="button"
                    onClick={handleCancel}
                    className="black-btn px-4 mt-1 py-2"
                  >
                    Cancelar
                  </button>
                  <button
                    onClick={handleLinkedinLink}
                    type="button"
                    className="mt-1 yellow-btn px-4 py-2 ms-2"
                  >
                    Ahorrar{" "}
                  </button>
                </div>
              ) : (
                <div className="ms-3 w-100 d-flex justify-content-end">
                  <button
                    type="button"
                    onClick={(e) => handleEdit(e, "linkedin")}
                    className="yellow-btn px-4 py-2"
                  >
                    Editar
                  </button>
                </div>
              )}
            </div>
          </div>

          <div
            className={
              editField === "twitter"
                ? "  py-3 single-field"
                : "  py-3 single-field-readonly"
            }
          >
            <p className="form-label-text mb-0">Enlace de twitter</p>
            <div
              className={`d-flex justify-content-between align-items-center ${
                editField === "twitter" && "field-on-mobile"
              }`}
            >
              <TextInput
                size="md"
                variant="unstyled"
                value={profileData?.twitter}
                readOnly={editField === "twitter" ? false : true}
                onChange={(e) => {
                  setProfileData({
                    ...profileData,
                    twitter: e.target.value,
                  });
                }}
              />
              {editField === "twitter" ? (
                <div className="ms-3 field-action-onMobile d-flex justify-content-end">
                  <button
                    type="button"
                    onClick={handleCancel}
                    className="black-btn px-4 mt-1 py-2"
                  >
                    Cancelar
                  </button>
                  <button
                    onClick={handleTwitterLink}
                    type="button"
                    className="mt-1 yellow-btn px-4 py-2 ms-2"
                  >
                    Ahorrar{" "}
                  </button>
                </div>
              ) : (
                <div className="ms-3 w-100 d-flex justify-content-end">
                  <button
                    type="button"
                    onClick={(e) => handleEdit(e, "twitter")}
                    className="yellow-btn px-4 py-2"
                  >
                    Editar
                  </button>
                </div>
              )}
            </div>
          </div>

          {/* <p className="form-label-text mb-0 mt-2">Asistente de Ai Tags</p>
          <div className={``}>
            <p
              style={{
                color: "#9A9DA0",
                fontWeight: 450,
                fontSize: 17,
              }}
            >
              Mejore su asistente de chat con IA configurando etiquetas
              relevantes.
            </p>
            <div className="ms-3 w-200 d-flex justify-content-end">
              <button
                onClick={open}
                type="button"
                className="yellow-btn px-4 py-2"
              >
                establecer contexto de chat
              </button>
            </div>
          </div> */}
        </motion.form>
      )}

      <GeneralModal
        opened={opened}
        loading={true}
        Component={SetChatContext}
        openModal={open}
        closeModal={close}
        size={matches ? "80%" : "50%"}
      />
    </>
  );
};

export default WebsiteSetting;
