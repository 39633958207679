import { useEffect, useRef } from "react";
import homeVideo from "../../assets/video/videoHome.mp4";
const AdvertiseVideo = () => {
  const videoRef = useRef<HTMLVideoElement>(null);
  useEffect(() => {
    const video = videoRef.current;
    if (video) {
      // Try to play the video in a Promise to handle errors gracefully
      video.play().catch((error) => {
        console.error("Video play failed:", error);
      });
    }
  }, [videoRef]);
  return (
    <div className="advertise-box ">
      <video
        ref={videoRef}
        controls
        height={"100%"}
        width={"100%"}
        autoPlay
        loop
        muted
      >
        <source src={homeVideo} type="video/mp4" />
      </video>
      {/* <video src={homeVideo} /> */}
    </div>
  );
};

export default AdvertiseVideo;
