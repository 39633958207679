import React from "react";
import BarChart from "../components/charts/BarChart";

const UserChart: React.FC = () => {
  return (
    <div
      style={{ height: "100vh" }}
      className="chart-box d-flex justify-content-center align-items-center"
    >
      <BarChart />
    </div>
  );
};

export default UserChart;
