import { Accordion, Loader, Switch } from "@mantine/core";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useFaqStore } from "../../zustand/store/FaqsStore";
import { useShallow } from "zustand/react/shallow";
import { motion } from "framer-motion";
const Faqs: React.FC = () => {
  const navigate = useNavigate();

  const [faqs, getFaqs, editFaqs, deleteFaqs, loading] = useFaqStore(
    useShallow((state) => [
      state.faqs,
      state.getFaqs,
      state.editFaq,
      state.deleteFaq,
      state.loading,
    ])
  );

  useEffect(() => {
    getFaqs();
  }, []);

  const handleDelete = async (id: string) => {
    const res = await deleteFaqs(id);
    if (res?.success) {
      getFaqs();
    }
  };

  const handleStatus = async (e: any, item: any) => {
    const updatedData = {
      ...item,
      isActive: !item?.isActive,
    };
    const res = await editFaqs(updatedData, item?._id);

    if (res?.success === true) {
      getFaqs();
    }
  };

  return (
    <>
      <div className="quiz-answers">
        <div className="d-flex justify-content-end align-items-center">
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 1 }}
            className="col-sm-12 col-md-4 col-lg-6 d-flex justify-content-end"
          >
            <button
              className="px-4 py-2 add-course"
              onClick={() => navigate("/dashboard/faqs-create")}
            >
              agregar preguntas frecuentes
            </button>
          </motion.div>
        </div>

        {loading ? (
          <div
            style={{ margin: "4rem 0" }}
            className="d-flex justify-content-center align-items-center"
          >
            <Loader color="black" />
          </div>
        ) : (
          <Accordion chevronPosition="right" defaultValue="hello">
            {faqs?.length > 0 &&
              faqs?.map((item: any, i: number) => {
                return (
                  <motion.div
                    initial={{ opacity: 0, x: -50 + i * 3 }}
                    whileInView={{ opacity: 1, x: 0 }}
                    transition={{ duration: 1 }}
                    key={i}
                  >
                    <Accordion.Item value={item?.question}>
                      <Accordion.Control> {item?.question}</Accordion.Control>
                      <Accordion.Panel>
                        <div className="answer p-4">
                          <p className="text-capitalize">{item?.answer}</p>
                        </div>
                        <div className="d-flex align-items-center justify-content-between flex-wrap mx-3 mt-2">
                          <Switch
                            onChange={(e) => {
                              handleStatus(e.target.checked, item);
                            }}
                            checked={item?.isActive}
                            color="yellow"
                            labelPosition="left"
                            label="Unable FAQ"
                          />
                          <div>
                            <button
                              onClick={() =>
                                navigate("/dashboard/faqs-update", {
                                  state: item,
                                })
                              }
                              className="faq-action-btn"
                            >
                              <svg
                                width="16"
                                height="20"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke={"#fff"}
                                // className=" "
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                                />
                              </svg>
                            </button>
                            <button
                              onClick={() => handleDelete(item?._id)}
                              className="faq-action-btn"
                            >
                              <svg
                                width="12"
                                height="16"
                                viewBox="0 0 12 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M8.66667 2.04167V1.22083C8.66667 0.545833 8.12083 0 7.44583 0H4.55C3.87917 0 3.33333 0.545833 3.33333 1.22083V2.04167H0V2.66667H0.879167L1.8625 14.7792C1.8625 15.4542 2.40833 16 3.08333 16H8.95833C9.63333 16 10.1792 15.4542 10.1792 14.7792L11.15 2.66667H12V2.04167H8.66667ZM3.95833 1.22083C3.95833 0.883333 4.21667 0.625 4.55417 0.625H7.45C7.7875 0.625 8.04583 0.883333 8.04583 1.22083V2.04167H3.9625V1.22083H3.95833ZM3.77917 14.0417L3.33333 4H3.9375L4.39167 14.0417H3.77917ZM6.29167 14.0417H5.70833V4H6.29167V14.0417ZM8.22083 14.0417H7.6125L8.0625 4H8.66667L8.22083 14.0417Z"
                                  fill="white"
                                />
                              </svg>
                            </button>
                          </div>
                        </div>
                      </Accordion.Panel>
                    </Accordion.Item>
                  </motion.div>
                );
              })}
          </Accordion>
        )}
      </div>
    </>
  );
};

// <>
//   {loading ? (
//     <div className=" h-100 d-flex justify-content-center align-items-center">
//       <Loader color="black" />
//     </div>
//   ) : (
//     <div className="quiz-answers">
//       <div className="d-flex justify-content-end align-items-center">
//         <motion.div
//           initial={{ opacity: 0 }}
//           whileInView={{ opacity: 1 }}
//           transition={{ duration: 1 }}
//           className="col-sm-12 col-md-4 col-lg-6 d-flex justify-content-end"
//         >
//           <button
//             className="px-4 py-2 add-course"
//             onClick={() => navigate("/dashboard/faqs-create")}
//           >
//             agregar preguntas frecuentes
//           </button>
//         </motion.div>
//       </div>

//       <Accordion chevronPosition="right" defaultValue="hello">
//         {faqs?.length > 0 &&
//           faqs?.map((item: any, i: number) => {
//             return (
//               <motion.div
//                 initial={{ opacity: 0, x: -50 + i * 3 }}
//                 whileInView={{ opacity: 1, x: 0 }}
//                 transition={{ duration: 1 }}
//                 key={i}
//               >
//                 <Accordion.Item value={item?.question}>
//                   <Accordion.Control> {item?.question}</Accordion.Control>
//                   <Accordion.Panel>
//                     <div className="answer p-4">
//                       <p className="text-capitalize">{item?.answer}</p>
//                     </div>
//                     <div className="d-flex align-items-center justify-content-between flex-wrap mx-3 mt-2">
//                       <Switch
//                         onChange={(e) => {
//                           handleStatus(e.target.checked, item);
//                         }}
//                         checked={item?.isActive}
//                         color="yellow"
//                         labelPosition="left"
//                         label="Unable FAQ"
//                       />
//                       <div>
//                         <button
//                           onClick={() =>
//                             navigate("/dashboard/faqs-update", {
//                               state: item,
//                             })
//                           }
//                           className="faq-action-btn"
//                         >
//                           <svg
//                             width="16"
//                             height="20"
//                             xmlns="http://www.w3.org/2000/svg"
//                             fill="none"
//                             viewBox="0 0 24 24"
//                             strokeWidth={1.5}
//                             stroke={"#fff"}
//                             // className=" "
//                           >
//                             <path
//                               strokeLinecap="round"
//                               strokeLinejoin="round"
//                               d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
//                             />
//                           </svg>
//                         </button>
//                         <button
//                           onClick={() => handleDelete(item?._id)}
//                           className="faq-action-btn"
//                         >
//                           <svg
//                             width="12"
//                             height="16"
//                             viewBox="0 0 12 16"
//                             fill="none"
//                             xmlns="http://www.w3.org/2000/svg"
//                           >
//                             <path
//                               d="M8.66667 2.04167V1.22083C8.66667 0.545833 8.12083 0 7.44583 0H4.55C3.87917 0 3.33333 0.545833 3.33333 1.22083V2.04167H0V2.66667H0.879167L1.8625 14.7792C1.8625 15.4542 2.40833 16 3.08333 16H8.95833C9.63333 16 10.1792 15.4542 10.1792 14.7792L11.15 2.66667H12V2.04167H8.66667ZM3.95833 1.22083C3.95833 0.883333 4.21667 0.625 4.55417 0.625H7.45C7.7875 0.625 8.04583 0.883333 8.04583 1.22083V2.04167H3.9625V1.22083H3.95833ZM3.77917 14.0417L3.33333 4H3.9375L4.39167 14.0417H3.77917ZM6.29167 14.0417H5.70833V4H6.29167V14.0417ZM8.22083 14.0417H7.6125L8.0625 4H8.66667L8.22083 14.0417Z"
//                               fill="white"
//                             />
//                           </svg>
//                         </button>
//                       </div>
//                     </div>
//                   </Accordion.Panel>
//                 </Accordion.Item>
//               </motion.div>
//             );
//           })}
//       </Accordion>
//     </div>
//   )}
// </>;
export default Faqs;
