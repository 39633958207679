import React from "react";

export const DashboardIcon: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      fill="none"
      viewBox="0 0 22 22"
    >
      <path
        fill="#fff"
        d="M1.222 12.222h7.334c.672 0 1.222-.55 1.222-1.222V1.222C9.778.55 9.228 0 8.556 0H1.222C.55 0 0 .55 0 1.222V11c0 .672.55 1.222 1.222 1.222zm0 9.778h7.334c.672 0 1.222-.55 1.222-1.222v-4.89c0-.671-.55-1.221-1.222-1.221H1.222c-.672 0-1.222.55-1.222 1.222v4.889C0 21.45.55 22 1.222 22zm12.222 0h7.334C21.45 22 22 21.45 22 20.778V11c0-.672-.55-1.222-1.222-1.222h-7.334c-.672 0-1.222.55-1.222 1.222v9.778c0 .672.55 1.222 1.222 1.222zM12.222 1.222v4.89c0 .671.55 1.221 1.222 1.221h7.334c.672 0 1.222-.55 1.222-1.222V1.222C22 .55 21.45 0 20.778 0h-7.334c-.672 0-1.222.55-1.222 1.222z"
      ></path>
    </svg>
  );
};

export const QuizManagementIcon: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="22"
      fill="none"
      viewBox="0 0 19 22"
    >
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M19 22V0H1.267v5.033H.633A.64.64 0 000 5.68a.64.64 0 00.633.647h.634v3.882H.633a.64.64 0 00-.633.647.64.64 0 00.633.647h.634v3.883H.633a.64.64 0 00-.633.647.64.64 0 00.633.647h.634V22H19zM13.153 6.076H5.987V4.782h7.166v1.294zm1.224 2.462h-8.39V7.244h8.39v1.294zm-1.224 6.128H5.987v-1.294h7.166v1.294zm1.224 2.462h-8.39v-1.294h8.39v1.294z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};

export const LeaderboardIcon: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="18"
      fill="none"
      viewBox="0 0 20 18"
    >
      <path
        fill="#fff"
        d="M4.5 18H1c-.55 0-1-.45-1-1V7c0-.55.45-1 1-1h3.5c.55 0 1 .45 1 1v10c0 .55-.45 1-1 1zm7.25-18h-3.5c-.55 0-1 .45-1 1v16c0 .55.45 1 1 1h3.5c.55 0 1-.45 1-1V1c0-.55-.45-1-1-1zM19 8h-3.5c-.55 0-1 .45-1 1v8c0 .55.45 1 1 1H19c.55 0 1-.45 1-1V9c0-.55-.45-1-1-1z"
      ></path>
    </svg>
  );
};

export const DollarSign: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="2 0 24 24"
      fill="#fff"
    >
      <path d="M12 2C6.486 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.514 2 12 2zm1 14.915V18h-2v-1.08c-2.339-.367-3-2.002-3-2.92h2c.011.143.159 1 2 1 1.38 0 2-.585 2-1 0-.324 0-1-2-1-3.48 0-4-1.88-4-3 0-1.288 1.029-2.584 3-2.915V6.012h2v1.109c1.734.41 2.4 1.853 2.4 2.879h-1l-1 .018C13.386 9.638 13.185 9 12 9c-1.299 0-2 .516-2 1 0 .374 0 1 2 1 3.48 0 4 1.88 4 3 0 1.288-1.029 2.584-3 2.915z"></path>
    </svg>
  );
};

export const FaqIcon: React.FC = () => {
  return (
    <svg
      width="20"
      height="22"
      viewBox="0 0 1792 1792"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill="#fff"
        d="M1088 1256v240q0 16-12 28t-28 12h-240q-16 0-28-12t-12-28v-240q0-16 12-28t28-12h240q16 0 28 12t12 28zm316-600q0 54-15.5 101t-35 76.5-55 59.5-57.5 43.5-61 35.5q-41 23-68.5 65t-27.5 67q0 17-12 32.5t-28 15.5h-240q-15 0-25.5-18.5t-10.5-37.5v-45q0-83 65-156.5t143-108.5q59-27 84-56t25-76q0-42-46.5-74t-107.5-32q-65 0-108 29-35 25-107 115-13 16-31 16-12 0-25-8l-164-125q-13-10-15.5-25t5.5-28q160-266 464-266 80 0 161 31t146 83 106 127.5 41 158.5z"
      />
    </svg>
  );
};

export const ChatIcon: React.FC = () => {
  return (
    <svg
      width="24"
      height="24"
      xmlns="http://www.w3.org/2000/svg"
      enableBackground="new 0 0 24 24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <g>
        <path
          fill="#fff"
          d="M20 3H4C1.8 3 0 4.8 0 7v10c0 2.2 1.8 4 4 4h16c2.2 0 4-1.8 4-4V7c0-2.2-1.8-4-4-4zm1.6 5.8l-7.9 5.3c-.5.3-1.1.5-1.7.5s-1.2-.2-1.7-.5L2.4 8.8c-.4-.3-.5-.9-.2-1.4.3-.4.9-.5 1.4-.2l7.9 5.3c.3.2.8.2 1.1 0l7.9-5.3c.5-.3 1.1-.2 1.4.3.2.4.1 1-.3 1.3z"
        ></path>
      </g>
    </svg>
  );
};

export const SettingIcon: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill="#fff"
        d="M21.71 8.613c-2.172 0-3.06-1.56-1.98-3.472.624-1.11.252-2.522-.84-3.156L16.817.78c-.948-.573-2.171-.232-2.735.73l-.132.232c-1.08 1.913-2.854 1.913-3.946 0l-.132-.231a1.952 1.952 0 00-2.71-.731L5.084 1.985c-1.091.634-1.463 2.06-.84 3.168 1.092 1.9.205 3.46-1.966 3.46C1.03 8.613 0 9.649 0 10.928v2.144c0 1.267 1.02 2.315 2.279 2.315 2.17 0 3.058 1.56 1.967 3.472-.624 1.11-.252 2.522.84 3.156L7.16 23.22c.948.573 2.171.232 2.735-.73l.132-.232c1.08-1.913 2.855-1.913 3.946 0l.132.231c.564.963 1.787 1.304 2.735.731l2.075-1.206c1.091-.634 1.463-2.06.84-3.156-1.092-1.912-.205-3.472 1.966-3.472 1.247 0 2.279-1.036 2.279-2.315v-2.144c-.012-1.267-1.032-2.315-2.29-2.315zm-9.716 7.347c-2.147 0-3.898-1.78-3.898-3.96 0-2.18 1.751-3.96 3.898-3.96 2.147 0 3.898 1.78 3.898 3.96 0 2.18-1.751 3.96-3.898 3.96z"
      ></path>
    </svg>
  );
};
