import React, { useEffect, useRef, useState } from "react";
import profile from "../assets/profile.png";
import { Avatar, TextInput } from "@mantine/core";
import { useShallow } from "zustand/react/shallow";
import { useUserStore } from "../zustand/store/UserStore";
import { baseURL } from "../configs/axiosConfig";
import { motion } from "framer-motion";

const ProfileSetting: React.FC = () => {
  const [userdata, updateUserData, loading] = useUserStore(
    useShallow((state) => [state.user, state.updateUserData, state.loading])
  );

  const [editField, setEditField] = useState("");

  const [profileFile, setProfileFile] = useState("");
  const [profileData, setProfileData] = useState<any>({});

  useEffect(() => {
    if (userdata) {
      setProfileData(userdata);
    }
  }, [userdata]);

  const handleEdit = (e: any, value: string) => {
    e.preventDefault();
    setEditField(value);
  };

  const handleCancel = () => {
    setEditField("");
  };

  const handleProfileImage = (e: any) => {
    const file = e.target.files?.[0];
    if (file) {
      const formData = new FormData();
      formData.append("profilePic", file);

      updateUserData(formData);

      setProfileFile(profileFile);
    }
  };

  const openFileInput = () => {
    // Trigger the click event of the file input
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  const handleFulName = async (e: any) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("name", profileData?.name);

    const res = await updateUserData(formData);
    if (res.success === true) {
      handleCancel();
    }
  };

  const handlePassword = async (e: any) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("password", profileData?.password);

    const res = await updateUserData(formData);
    if (res.success === true) {
      handleCancel();
    }
  };

  // Create a ref for the file input
  const inputRef = React.useRef<HTMLInputElement | null>(null);
  return (
    <motion.div
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      transition={{ duration: 1 }}
      className="container"
    >
      <form className="profile-form my-5 pb-4">
        <div className=" my-4 py-4 single-field">
          <p className="form-label-text">Cambiar foto de perfil</p>
          <div className="d-flex justify-content-between align-items-center">
            <Avatar
              className="profile-img "
              w={80}
              h={80}
              src={baseURL + userdata?.profilePic}
              alt="profile"
            />
            <div className="d-flex justify-content-end align-items-center flex-wrap">
              <label htmlFor="image-input ">
                <input
                  className="d-none"
                  ref={inputRef}
                  id="image-input"
                  type="file"
                  accept="image/*"
                  onChange={(e) => {
                    handleProfileImage(e);
                  }}
                />
                <button
                  onClick={openFileInput}
                  type="button"
                  className="yellow-btn mt-1 px-4 py-2 ms-2"
                >
                  Cambiar foto
                </button>
              </label>
            </div>
          </div>
        </div>

        <div
          className={
            editField === "first name"
              ? "  py-4 single-field"
              : "  py-4 single-field-readonly"
          }
        >
          <p className="form-label-text mb-0">Nombre completo</p>
          <div
            className={`d-flex justify-content-between align-items-center ${
              editField === "first name" && "field-on-mobile"
            }`}
          >
            <TextInput
              size="md"
              variant="unstyled"
              value={profileData?.name}
              readOnly={editField === "first name" ? false : true}
              onChange={(e) => {
                setProfileData({
                  ...profileData,
                  name: e.target.value,
                });
              }}
            />
            {editField === "first name" ? (
              <div className="ms-3 field-action-onMobile d-flex justify-content-end">
                <button
                  type="button"
                  onClick={handleCancel}
                  className="black-btn px-4 mt-1 py-2"
                >
                  Cancelar
                </button>
                <button
                  onClick={handleFulName}
                  type="button"
                  className="mt-1 yellow-btn px-4 py-2 ms-2"
                >
                  Ahorrar{" "}
                </button>
              </div>
            ) : (
              <div className="ms-3 w-100 d-flex justify-content-end">
                <button
                  type="button"
                  onClick={(e) => handleEdit(e, "first name")}
                  className="black-btn px-4 py-2"
                >
                  Editar
                </button>
              </div>
            )}
          </div>
        </div>

        <div className="  py-4 single-field-readonly">
          <p className="form-label-text mb-0">Correo electrónico</p>
          <div className="d-flex justify-content-between align-items-center">
            <TextInput
              size="md"
              variant="unstyled"
              value={profileData?.email}
              readOnly
            />
          </div>
        </div>

        <div
          className={
            editField === "password"
              ? "  py-4 single-field"
              : "  py-4 single-field-readonly"
          }
        >
          <p className="form-label-text mb-0">Contraseña</p>
          <div
            className={`d-flex justify-content-between align-items-center ${
              editField === "password" && "field-on-mobile"
            }`}
          >
            <TextInput
              size="md"
              placeholder="******"
              variant="unstyled"
              readOnly={editField === "password" ? false : true}
              onChange={(e) => {
                setProfileData({
                  ...profileData,
                  password: e.target.value,
                });
              }}
            />
            {editField === "password" ? (
              <div className="ms-3 d-flex field-action-onMobile justify-content-end">
                <button
                  type="button"
                  onClick={handleCancel}
                  className="black-btn mt-1 px-4 py-2"
                >
                  Cancelar
                </button>
                <button
                  onClick={handlePassword}
                  type="button"
                  className="yellow-btn mt-1 px-4 py-2 ms-2"
                >
                  Ahorrar{" "}
                </button>
              </div>
            ) : (
              <div className="ms-3 d-flex">
                <button
                  type="button"
                  onClick={(e) => handleEdit(e, "password")}
                  className="black-btn px-4 py-2"
                >
                  Editar
                </button>
              </div>
            )}
          </div>
        </div>

        {/* <div className="my-3 mb-5 d-flex justify-content-between align-items-center">
          <button className="black-btn px-4 py-2">cancel</button>
          <button className="yellow-btn px-4 py-2">Save changes</button>
        </div> */}
      </form>
    </motion.div>
  );
};

export default ProfileSetting;
