import React from "react";
import { Navigate } from "react-router-dom";
import { UseGetRole, useAuthentication } from "../services/hooks";
interface AuthRouteProps {
  Component: React.ComponentType<any>;
}
const AuthRoute: React.FC<AuthRouteProps> = ({ Component }: AuthRouteProps) => {
  const isAuthenticated: boolean = useAuthentication();
  const role: string = UseGetRole();
  if (!isAuthenticated) {
    return <Navigate to="/" />;
  } else if (isAuthenticated) {
    return <Component />;
  }
  return null;
};
export default AuthRoute;
