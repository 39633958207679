import React from "react";
import { DataTable } from "mantine-datatable";
import { QuizRecord } from "../../data/data";

interface GeneralTableProps {
  columns: {
    accessor: string;
    title: string;
  }[];
  records: QuizRecord[];
  loading: boolean;
}

const GeneralTable: React.FC<GeneralTableProps> = ({
  columns,
  records,
  loading,
}) => {
  return (
    <div>
      <DataTable
        loaderType="dots"
        loaderBackgroundBlur={0}
        fetching={loading}
        withRowBorders={false}
        loaderSize="lg"
        columns={columns}
        records={records}
        loaderColor={"yellow"}
      />
    </div>
  );
};

export default GeneralTable;
