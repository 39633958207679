import React, { useEffect } from "react";
import QuizVideoSection from "../components/quizHub/quizVedioSection";
import profile from "../assets/profile.png";
import { useNavigate, useParams } from "react-router-dom";
import { useQuizStore } from "../zustand/store/QuizStore";
import { useShallow } from "zustand/react/shallow";
import { baseURL } from "../configs/axiosConfig";
import { Image } from "@mantine/core";
import { motion } from "framer-motion"

const QuizDetail: React.FC = () => {
  const navigate = useNavigate();
  const { id, userid } = useParams();

  const [singleQuizByUser, getSingleQuizByUser] = useQuizStore(
    useShallow((state: any) => [
      state.singleQuizByUser,
      state.getSingleQuizByUser,
    ])
  );

  useEffect(() => {
    if (id) {
      getSingleQuizByUser(id, userid);
    }
  }, []);

  return (
    <motion.div initial={{ opacity: 0 }}
    whileInView={{ opacity: 1 }}
    transition={{ duration: 1 }}>
      <div className="container py-5  ">
        <div className="detail-container ">
          <button
            onClick={() => navigate(-1)}
            className="my-5 px-5 py-2 go-back"
          >
            REGRESA
          </button>
          <motion.div initial={{ opacity: 0, y: 100 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 1 }} className=" row detail-box align-content-center mx-0">
            <div className="col-md-4 col-lg-3 d-flex justify-content-center align-content-center">
              <div className="py-4 d-flex flex-column justify-content-center align-items-center">
                <Image
                  style={{ width: "100px" }}
                  className="profile-pic "
                  width={100}
                  height={100}
                  fallbackSrc={`https://placehold.co/400x400/orange/white?text=${singleQuizByUser?.user?.name}`}
                  src={
                    singleQuizByUser?.user?.profilePic
                      ? baseURL + singleQuizByUser?.user?.profilePic
                      : profile
                  }
                  alt="profile"
                />
                <p className="profile-name mb-0 mt-2 text-center">
                  {singleQuizByUser?.user?.name}
                </p>
              </div>
            </div>
            <div className="col-md-4 col-lg-3 border-left ">
              <div className="py-4 info-box">
                <p className="label-text mb-2">Prueba Nombre</p>
                <p className="detail-text mb-0">
                  {" "}
                  {singleQuizByUser?.quiz?.title}
                </p>
              </div>
            </div>
            <div className="col-md-4 col-lg-2 border-left ">
              <div className="py-4 info-box">
                <p className="label-text mb-2">Pregunta total</p>
                <p className="detail-text mb-0">
                  {" "}
                  {singleQuizByUser?.quiz?.questions?.length}
                </p>
              </div>
            </div>
            <div className="col-md-4 col-lg-1 border-left ">
              <div className="py-4 info-box">
                <p className="label-text mb-2">Correcta</p>
                <p className="detail-text mb-0">
                  {" "}
                  {singleQuizByUser?.score || "-"}
                </p>
              </div>
            </div>
            <div className="col-md-4 col-lg-1 border-left ">
              <div className="py-4 info-box">
                <p className="label-text mb-2">incorrecta</p>
                <p className="detail-text mb-0">
                  {singleQuizByUser?.quiz?.questions?.length -
                    singleQuizByUser?.score || "-"}
                  {""}
                </p>
              </div>
            </div>

            <div className="col-md-4 col-lg-2 border-left ">
              <div className="py-4 info-box">
                <p className="label-text mb-2">Notas totales</p>
                <p className="detail-text mb-0">
                  {singleQuizByUser?.score || "-"}
                </p>
              </div>
            </div>
          </motion.div>
          {/* <QuizDetailCard /> */}
        </div>
      </div>

      <div className="pb-5">
        <QuizVideoSection data={singleQuizByUser} />
      </div>
    </motion.div>
  );
};

export default QuizDetail;
