import React from "react";
import { Route, Routes } from "react-router-dom";
import DefaultLayout from "../layouts/DefaultLayout";
import Home from "../pages/Home";
import NotFound from "../pages/NotFound";
import QuizHub from "../pages/QuizHub";
import LeaderBoard from "../pages/LeaderBoard";
import MyQuiz from "../pages/MyQuiz";
import QuizDetail from "../pages/QuizDetail";
import StartQuiz from "../pages/StartQuiz";
import ProfileSetting from "../pages/ProfileSetting";
import DashboardLayout from "../layouts/DashboardLayout";
import Dashboard from "../pages/dashboardPages/Dashboard";
import CourseManagement from "../pages/dashboardPages/CourseManagement";
import LeaderboardManagement from "../pages/dashboardPages/leaderboardManagement";
import Setting from "../pages/dashboardPages/Setting";
import AddCourse from "../pages/dashboardPages/AddCourse";
import CourseDetails from "../pages/dashboardPages/CourseDetails";
import CheckQuiz from "../pages/dashboardPages/CheckQuiz";
import AdminRoute from "./AdminRoute";
import Faqs from "../pages/dashboardPages/Faqs";
import FaqForm from "../pages/dashboardPages/FaqForm";
import WebsiteSetting from "../pages/dashboardPages/WebsiteSetting";
import UserChart from "../pages/UserChart";
import UsdtExchange from "../pages/UsdtExchange";
import BuyUsdt from "../pages/BuyUsdt";
import OrderHistory from "../pages/OrderHistory";
import TransactionHistory from "../pages/dashboardPages/TransactionHistory";
import AuthRoute from "./AuthRoute";
import ChatHistory from "../pages/dashboardPages/ChatHistory";
import SingleChat from "../pages/dashboardPages/SingleChat";

const Router: React.FC = () => {
  // use protected routes for authenticated users (i.e: UserRoute & AdminRoute or make more if you've to)..
  const defaultBackFunc = () => {
    // Default behavior for backFunc
  };
  return (
    <Routes>
      {/* Default Layout routes */}
      <Route path="/" element={<DefaultLayout />}>
        <Route path="/" element={<Home />} />
        <Route path="/user" element={<UserChart />} />
        <Route path="/quiz-hub" element={<QuizHub />} />
        {/* <Route path="/leader-board" element={<LeaderBoard />} /> */}
        <Route path="/my-quiz/:id?" element={<MyQuiz />} />
        <Route path="/quiz-details/:id/:userid" element={<QuizDetail />} />
        <Route path="/start-quiz/:id" element={<StartQuiz />} />
        <Route path="/profile-setting" element={<ProfileSetting />} />
        <Route path="/usdt-exchange" element={<UsdtExchange />} />
        <Route
          path="/buy-usdt"
          element={<BuyUsdt backFunc={defaultBackFunc} />}
        />
        {/* auth route */}

        <Route
          path="/order-history"
          element={<AuthRoute Component={OrderHistory} />}
        />
        <Route path="*" element={<NotFound />} />
      </Route>

      <Route path="/dashboard" element={<DashboardLayout />}>
        <Route
          path="/dashboard"
          element={<AdminRoute Component={Dashboard} />}
        />
        <Route
          path="/dashboard/course-management"
          element={<AdminRoute Component={CourseManagement} />}
        />
        <Route
          path="/dashboard/leaderboard-management/:quiz?"
          element={<AdminRoute Component={LeaderboardManagement} />}
        />
        <Route
          path="/dashboard/transaction-history"
          element={<AdminRoute Component={TransactionHistory} />}
        />
        <Route
          path="/dashboard/faqs"
          element={<AdminRoute Component={Faqs} />}
        />
        <Route
          path="/dashboard/faqs-create"
          element={<AdminRoute Component={FaqForm} />}
        />

        <Route
          path="/dashboard/faqs-update"
          element={<AdminRoute Component={FaqForm} />}
        />
        <Route
          path="/dashboard/setting"
          element={<AdminRoute Component={Setting} />}
        />
        <Route
          path="/dashboard/add-course"
          element={<AdminRoute Component={AddCourse} />}
        />
        <Route
          path="/dashboard/website-setting"
          element={<AdminRoute Component={WebsiteSetting} />}
        />
        <Route
          path="/dashboard/course-detail/:id"
          element={<AdminRoute Component={CourseDetails} />}
        />
        <Route
          path="/dashboard/check-quiz/:id/:userid"
          element={<AdminRoute Component={CheckQuiz} />}
        />
        <Route
          path="/dashboard/chat-history"
          element={<AdminRoute Component={ChatHistory} />}
        />
        <Route
          path="/dashboard/single-chat/:id"
          element={<AdminRoute Component={SingleChat} />}
        />
      </Route>
    </Routes>
  );
};

export default Router;
