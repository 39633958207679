import React from "react";
import { Modal } from "@mantine/core";
import { AnimatePresence, motion } from "framer-motion";

interface IModalAlert {
  children: any;
  title?: string;
  opened: boolean | false;
  close: () => void;
}
const ModalAlert: React.FC<IModalAlert> = ({
  children,
  opened,
  close,
  title = "",
}) => {
  return (
    <div className="alert-modal">
      <AnimatePresence>
        {opened && (
          <motion.div
            initial={{ opacity: 0, scale: 0.5 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.5 }}
            transition={{ duration: 0.3 }}
          >
            <Modal
              opened={opened}
              onClose={close}
              size="md"
              radius={"md"}
              centered
              overlayProps={{
                backgroundOpacity: 0.55,
                blur: 3,
              }}
              title={title}
            >
              <motion.div
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
              >
                {children}
              </motion.div>
            </Modal>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default ModalAlert;
