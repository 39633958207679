import React from "react";
import profile from "../../../assets/first-winner.png";
import { Link } from "react-router-dom";
import { baseURL } from "../../../configs/axiosConfig";
import { Image } from "@mantine/core";

interface IquizParticipants {
  createdAt: string;
  rank?: string;
  updatedAt: string;
  user: {
    createdAt: string;
    email: string;
    isActive: boolean;
    name: string;
    password: string;
    profilePic?: string;
    role: string;
    updatedAt: string;
    __v?: number;
    _id: string;
  };
  _id: string;
}

export const DashboardLeaderCard: React.FC<{
  data: IquizParticipants;
  quizId: string;
}> = ({ data, quizId }) => {
  return (
    <div className="m-auto p-4 leader-card">
      <div className="d-flex justify-content-center">
        <Image
          className="profile-pic"
          width={"150px"}
          height={"150px"}
          fallbackSrc={`https://placehold.co/400x400/orange/white?text=${data?.user?.name}`}
          src={data?.user?.profilePic ? baseURL + data?.user?.profilePic : null}
          alt="img"
        />
      </div>
      <p className="text-center mt-2 profile-name text-uppercase">
        {data?.user?.name}
      </p>
      <div className="d-flex justify-content-center">
        <Link to={`/dashboard/check-quiz/${quizId}/${data?.user?._id}`}>
          <button className="py-2 px-4 text-uppercase view-details">
            Ver detalles
          </button>
        </Link>
      </div>
    </div>
  );
};

export const DashboardLeaderCardList: React.FC<{
  data: IquizParticipants;
  quizId: string;
}> = ({ data, quizId }) => {
  return (
    <div className="my-3  p-3 leader-card rounded-4">
      <div className="d-flex justify-content-between align-items-center">
        <div className="d-flex align-items-center">
          <Image
            width={"70px"}
            height={"70px"}
            fallbackSrc={`https://placehold.co/400x400/orange/white?text=${data?.user?.name}`}
            src={
              data?.user?.profilePic ? baseURL + data?.user?.profilePic : null
            }
            alt="img"
            className="student-profile profile-pic"
          />
          <p className="ms-3 mb-0 text-center mt-2 profile-name text-uppercase">
            {" "}
            {data?.user?.name}
          </p>
        </div>
        <Link to={`/dashboard/check-quiz/${quizId}/${data?.user?._id}`}>
          <button className="py-2 px-4 text-uppercase view-details">
            Ver detalles
          </button>
        </Link>
      </div>
    </div>
  );
};
