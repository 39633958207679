import React, { useEffect } from "react";
import Sidebar from "../components/layout/Sidebar";
import DashboardNavbar from "../components/layout/DashboardNavbar";
import { Outlet } from "react-router";
import { Burger } from "@mantine/core";
import { useDisclosure, useMediaQuery } from "@mantine/hooks";
import { useLocation } from "react-router-dom";
const DashboardLayout: React.FC = () => {
  const location = useLocation();
  const [opened, { toggle, close }] = useDisclosure(false);
  const screen_1200 = useMediaQuery("(max-width: 1200px)");

  const handleMenuBtn = () => {
    toggle();
  };

  useEffect(() => {
    close();
  }, [location?.pathname]);

  return (
    <div className="d-flex">
      <div
        className={`side-bar-conatiner ${
          opened ? "open-sidebar" : "close-sidebar"
        }`}
      >
        <Sidebar
          handleMenuBtn={handleMenuBtn}
          opened={opened}
          close={close}
          icon={<Burger color="#fff" opened={opened} onClick={toggle} />}
        />
      </div>

      <div className="navbar-outlet">
        <div className="d-flex">
          <DashboardNavbar
            icon={<Burger color="#000" opened={opened} onClick={toggle} />}
          />
        </div>
        <div className="outlet-container m-3">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default DashboardLayout;
