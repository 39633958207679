import { Menu, TextInput } from "@mantine/core";
import { useListState } from "@mantine/hooks";
import React, { useState } from "react";
import Swal from "sweetalert2";
import { DatePickerInput } from "@mantine/dates";
import { rem } from "@mantine/core";
import { IconUpload, IconPhoto, IconX } from "@tabler/icons-react";
import { Dropzone, IMAGE_MIME_TYPE } from "@mantine/dropzone";
import { useForm } from "@mantine/form";
import { useQuizStore } from "../../zustand/store/QuizStore";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
const AddCourse: React.FC = () => {
  const navigate = useNavigate();
  const addQuiz = useQuizStore((state) => state.addQuiz);

  const [thumbnailImg, setThumbnailImg] = useState();
  const [thumbnailImgURL, setThumbnailImgURL] = useState<string>();
  const [question, setQuestion] = useState<{ key: number; que: string }>({
    key: 0,
    que: "",
  });
  const [questionArray, setQuestionArray] = useState([{ key: 0, que: "" }]);

  const [state, handlers] = useListState(questionArray);
  const handleAddQuestion = () => {
    if (
      questionArray?.length > 0 &&
      questionArray[questionArray.length - 1]?.que?.trim() !== ""
    ) {
      setQuestionArray([...questionArray, { key: 0, que: "" }]);
      setQuestion({ key: 0, que: "" }); // Clear the input after adding a question
      handlers.setState([...questionArray, { key: 0, que: "" }]);
    } else {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "yellow-btn px-5 py-2 mx-2",
          cancelButton: "black-btn px-5 py-2 mx-2",
        },
        buttonsStyling: false,
      });
      swalWithBootstrapButtons.fire({
        text: `Introducir pregunta`,
      });
    }
  };

  // const handleDelete = (i: number) => {
  //   setQuestionArray((prevQuestionArray) => {
  //     const updatedArray = [...prevQuestionArray];
  //     updatedArray.splice(i, 1);
  //     return updatedArray;
  //   });
  // };
  const handleRemoveQuestion = (indexToRemove: any) => {
    if (
      questionArray &&
      questionArray.length > 0 &&
      indexToRemove >= 0 &&
      indexToRemove < questionArray.length
    ) {
      const updatedQuestions = [...questionArray];
      updatedQuestions.splice(indexToRemove, 1);

      setQuestionArray(updatedQuestions);
      handlers.setState(updatedQuestions);
    } else {
      // Handle the case when the index is out of bounds or there are no questions to remove
    }
  };
  const renderIcon = (i: number): React.ReactNode => (
    <Menu trigger="hover" closeDelay={400} position="left-start">
      {/* ... menu items */}
      <Menu.Target>
        <svg
          className="cursor-pointer"
          width="5"
          height="16"
          viewBox="0 0 5 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M2.5 4C1.83696 4 1.20107 3.78929 0.732234 3.41421C0.263393 3.03914 0 2.53043 0 2C0 1.46957 0.263393 0.960859 0.732234 0.585786C1.20107 0.210714 1.83696 0 2.5 0C3.16304 0 3.79893 0.210714 4.26777 0.585786C4.73661 0.960859 5 1.46957 5 2C5 2.53043 4.73661 3.03914 4.26777 3.41421C3.79893 3.78929 3.16304 4 2.5 4ZM2.5 10C1.83696 10 1.20107 9.78929 0.732234 9.41421C0.263393 9.03914 0 8.53043 0 8C0 7.46957 0.263393 6.96086 0.732234 6.58579C1.20107 6.21071 1.83696 6 2.5 6C3.16304 6 3.79893 6.21071 4.26777 6.58579C4.73661 6.96086 5 7.46957 5 8C5 8.53043 4.73661 9.03914 4.26777 9.41421C3.79893 9.78929 3.16304 10 2.5 10ZM2.5 16C1.83696 16 1.20107 15.7893 0.732234 15.4142C0.263393 15.0391 0 14.5304 0 14C0 13.4696 0.263393 12.9609 0.732234 12.5858C1.20107 12.2107 1.83696 12 2.5 12C3.16304 12 3.79893 12.2107 4.26777 12.5858C4.73661 12.9609 5 13.4696 5 14C5 14.5304 4.73661 15.0391 4.26777 15.4142C3.79893 15.7893 3.16304 16 2.5 16Z"
            fill="#C7C6C7"
          />
        </svg>
      </Menu.Target>
      <Menu.Dropdown>
        <Menu.Item onClick={() => handleRemoveQuestion(i)}>Delete</Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
  const handleEnterNext = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      handleAddQuestion();
    }
  };

  const handleThumbnailImg = (file: any) => {
    setThumbnailImg(file);
    if (file) {
      const newImageUrl = URL.createObjectURL(file);
      setThumbnailImgURL(newImageUrl);
    }
  };

  const form = useForm({
    initialValues: {
      thumbnailImg: null,
      title: "",
      startDate: null,
      endDate: null,
      video: "",
    },

    validate: {
      title: (value) => (!value ? "Introduce el título" : null),
      startDate: (value) => (!value ? "Introduzca la fecha de inicio" : null),
      endDate: (value) =>
        !value ? "Introduzca la fecha de finalización" : null,
      video: (value) => (!value ? "Ingrese el enlace del vídeo" : null),
    },
  });

  const handleSubmit = (values: any) => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "yellow-btn px-5 py-2 mx-2",
        cancelButton: "black-btn px-5 py-2 mx-2",
      },
      buttonsStyling: false,
    });
    if (!thumbnailImg) {
      swalWithBootstrapButtons.fire({
        text: `All fields are required`,
      });
    } else if (
      questionArray.length > 0 &&
      questionArray[questionArray.length - 1]?.que?.trim() === ""
    ) {
      swalWithBootstrapButtons.fire({
        text: `All fields are required`,
      });
    } else {
      const formData = new FormData();
      formData.append("thumbnail", thumbnailImg);
      formData.append("title", values?.title);
      formData.append("video", values?.video);
      formData.append("startDate", values?.startDate);
      formData.append("endDate", values?.endDate);
      formData.append("questions", JSON.stringify(questionArray));

      addQuiz(formData);
    }
  };
  const handlePublishCourse = async (values: any) => {
    if (
      questionArray.length < 1 ||
      questionArray[questionArray.length - 1]?.que?.trim() === "" ||
      form.values.video.trim() === "" ||
      form.values.title.trim() === "" ||
      !form.values.startDate ||
      !form.values.endDate ||
      !thumbnailImg
    ) {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "yellow-btn px-5 py-2 mx-2",
          cancelButton: "black-btn px-5 py-2 mx-2",
        },
        buttonsStyling: false,
      });
      swalWithBootstrapButtons.fire({
        text: `Todos los campos son obligatorios`,
      });
    } else {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "yellow-btn px-5 py-2 mx-2",
          cancelButton: "black-btn px-5 py-2 mx-2",
        },
        buttonsStyling: false,
      });
      swalWithBootstrapButtons
        .fire({
          text: `¡Buen trabajo! has agregado ${questionArray?.length} preguntas. ¿Estás seguro de que quieres publicar el curso y ponerlo a disposición de los usuarios?`,
          showCancelButton: true,
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          reverseButtons: true,
        })
        .then((result) => {
          if (result.isConfirmed) {
            const formData = new FormData();
            formData.append("thumbnail", thumbnailImg);
            formData.append("title", form?.values?.title);
            formData.append("video", form?.values?.video);
            formData.append("startDate", form?.values?.startDate || "");
            formData.append("endDate", form?.values?.endDate || "");
            formData.append("questions", JSON.stringify(questionArray));

            addQuiz(formData);
            swalWithBootstrapButtons
              .fire({
                title: "¡Prueba publicada!",
                text: "Su cuestionario ha sido publicado.",
              })
              .then((result) => {
                if (result.isConfirmed) {
                  navigate(-1);
                }
              });
          } else if (result.dismiss === Swal.DismissReason.cancel) {
            swalWithBootstrapButtons.fire({
              title: "¡Prueba no publicada!",
              text: "Su cuestionario no ha sido publicado.",
            });
          }
        });
    }
  };
  return (
    <motion.div
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      transition={{ duration: 1 }}
      className="my-5 mx-3"
    >
      <form>
        <div className="row mt-3">
          <div className="col-6">
            <div className="course-field">
              <label className="w-100">
                <p className="course-form-lable mb-2">
                  Imagen en miniatura del cuestionario
                </p>
                <Dropzone
                  onDrop={(files) => handleThumbnailImg(files[0])}
                  onReject={(files) => console.log("rejected files", files)}
                  maxSize={5 * 1024 ** 2}
                  accept={IMAGE_MIME_TYPE}
                  {...form.getInputProps("thumbnailImg")}
                >
                  <Dropzone.Accept>
                    <IconUpload
                      style={{
                        width: rem(52),
                        height: rem(52),
                        color: "var(--mantine-color-blue-6)",
                      }}
                      stroke={1.5}
                    />
                  </Dropzone.Accept>
                  <Dropzone.Reject>
                    <IconX
                      style={{
                        width: rem(52),
                        height: rem(52),
                        color: "var(--mantine-color-red-6)",
                      }}
                      stroke={1.5}
                    />
                  </Dropzone.Reject>
                  {thumbnailImgURL ? (
                    <img
                      className="thumbnail-img"
                      width={"100%"}
                      src={thumbnailImgURL}
                      alt="thumbnail"
                    />
                  ) : (
                    <>
                      <Dropzone.Idle>
                        <div className="d-flex justify-content-center">
                          <IconPhoto
                            style={{
                              width: rem(52),
                              height: rem(52),
                              color: "var(--mantine-color-dimmed)",
                            }}
                            stroke={1.5}
                          />
                        </div>
                      </Dropzone.Idle>

                      <div>
                        <p className="text-center mb-0 thumbnail-heading">
                          Arrastre las imágenes aquí o haga clic para
                          seleccionar archivos
                        </p>

                        <p className="thumbnail-text text-center">
                          Adjunte una imagen en miniatura para su cuestionario;
                          el archivo no debe exceder los 5mb
                        </p>
                      </div>
                    </>
                  )}
                </Dropzone>
              </label>
            </div>
          </div>
        </div>

        <div className="course-field mt-3">
          <label className="w-100">
            <p className="course-form-lable mb-2">Quiz Title</p>
            <TextInput
              size="sm"
              placeholder="Título aquí"
              {...form.getInputProps("title")}
            />
          </label>
        </div>

        <div className="row mt-3">
          <div className="col-6">
            <div className="course-field">
              <label className="w-100">
                <p className="course-form-lable mb-2">
                  Fecha de inicio del cuestionario
                </p>
                <DatePickerInput
                  clearable
                  minDate={new Date()}
                  size="sm"
                  placeholder="elegir fecha"
                  {...form.getInputProps("startDate")}
                />

                {/* <TextInput size="md" placeholder="Title here" /> */}
              </label>
            </div>
          </div>
          <div className="col-6">
            <div className="course-field">
              <label className="w-100">
                <p className="course-form-lable mb-2">
                  Fecha de finalización del cuestionario
                </p>
                <DatePickerInput
                  clearable
                  minDate={new Date()}
                  size="sm"
                  placeholder="elegir fecha"
                  {...form.getInputProps("endDate")}
                />
              </label>
            </div>
          </div>
        </div>

        <div className="course-field">
          <label className="w-100">
            <p className="course-form-lable mt-3 mb-2">
              Enlace de video{" "}
              <svg
                className="ms-2"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="none"
                viewBox="0 0 20 20"
              >
                <path
                  stroke="#000"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeOpacity="0.25"
                  strokeWidth="2"
                  d="M11 14h-1v-4H9m1-4h.01M19 10a9 9 0 11-18 0 9 9 0 0118 0z"
                ></path>
              </svg>
            </p>
            <TextInput
              size="sm"
              placeholder="https://www.youtube.com/watch?v=LmpL_rrT8ho"
              {...form.getInputProps("video")}
            />
          </label>
        </div>
        {questionArray?.map((value, i) => {
          return (
            <div key={i} className="course-field">
              <label className="w-100 mt-4">
                <TextInput
                  //   rightSectionPointerEvents="none"
                  rightSection={questionArray?.length > 1 && renderIcon(i)}
                  size="sm"
                  placeholder="Añadir pregunta aquí"
                  onChange={(event) => {
                    setQuestionArray((prevQuestionArray) => {
                      const updatedArray = [...prevQuestionArray];
                      updatedArray[i].key = updatedArray.length;
                      updatedArray[i].que = event.target.value;
                      return updatedArray;
                    });
                  }}
                  onKeyDown={handleEnterNext}
                />
              </label>
            </div>
          );
        })}

        <div className="mt-3">
          <button
            onClick={handleAddQuestion}
            type="button"
            className="black-btn px-3 py-2"
          >
            AÑADIR PREGUNTA
          </button>
        </div>
        <div className="mt-3 d-flex justify-content-end ">
          <button
            onClick={handlePublishCourse}
            type="button"
            className=" publish-btn text-uppercase yellow-btn px-4 py-2"
          >
            Publicar
          </button>
        </div>
      </form>
    </motion.div>
  );
};

export default AddCourse;
