import React from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import UsdtForm from "../components/form/usdtForm";

interface BuyUsdtProps {
  backFunc: (value: boolean) => void;
}

const BuyUsdt: React.FC<BuyUsdtProps> = ({ backFunc }) => {
  const stripePromise = loadStripe(
    "pk_test_51NSIlJCz6A0J32KfawEKMFsfzwVOcuGJSQkJTGcwtbXtYE0jTQFQk628ZlUNP6kApZy8JUlnDYrQPEj1Ksmak1m600VisDRfA7"
  );

  return (
    <Elements stripe={stripePromise}>
      <UsdtForm backFunc={backFunc} />
    </Elements>
  );
};

export default BuyUsdt;
