import React, { useEffect, useState } from "react";
import vedioImg from "../../assets/quizVedio.png";
import { Image } from "@mantine/core";
import { updateDecorator } from "typescript";
import { baseURL } from "../../configs/axiosConfig";
import moment from "moment";
import { useShallow } from "zustand/react/shallow";
import { useQuizStore } from "../../zustand/store/QuizStore";
import { useTranscriptStore } from "../../zustand/store/TranscriptStore";

interface IQuizData {
  createdAt: string;
  endDate: string;
  question: {
    key: string;
    que: string;
    _id: string;
  }[];
  startDate: string;
  thumbnail: string;
  title: string;
  updatedAt: string;
  video: string;
  __v: number;
  _id: string;
}

const QuizVedio: React.FC<{ data: IQuizData }> = ({ data }) => {
  const [quizArray, getQuizs, getSingleQuiz] = useQuizStore(
    useShallow((state) => [state.quiz, state.getQuizs, state.getSingleQuizs])
  );

  const [transcript, getTransript, loading] = useTranscriptStore(
    useShallow((state) => [
      state.transcript,
      state.getTranscript,
      state.loading,
    ])
  );

  const [videoId, setVideoId] = useState<string>();

  useEffect(() => {
    const videoID = data && data.video.split("=");
    setVideoId(videoID[videoID.length - 1]);
  }, []);

  const inputDate = data?.startDate;
  const currentDate = moment();
  const inputMoment = moment(inputDate);

  const isTodayOrPast = inputMoment.isSameOrBefore(currentDate, "day");

  return (
    <div className="mt-5">
      <Image
        className="thumnail-img"
        width="100%"
        height="300"
        loading="lazy"
        src={baseURL + data.thumbnail}
        fallbackSrc={`https://placehold.co/600x400/orange/white?text=${data?.title}`}
      />

      <div>
        <p className="video-title text-capitalize">{data?.title}</p>
        {/* <p className="video-text">
          Unlock the secrets of becoming a successful business magnate in our
          riveting video ....
        </p> */}
        <div className="d-flex justify-content-between align-items-center">
          {isTodayOrPast ? (
            <p
              className="mb-0 watch-video"
              onClick={() => {
                getSingleQuiz(data);
                const res = getTransript({
                  videoId: videoId,
                  lang: "es",
                });
                window.scroll({
                  top: 350,
                  left: 0,
                  behavior: "smooth",
                });
              }}
            >
              Ver video{" "}
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="23"
                  height="18"
                  fill="none"
                  viewBox="0 0 23 18"
                >
                  <path
                    fill="#000"
                    fillRule="evenodd"
                    d="M12.714 16.21V9.537H.954a.954.954 0 010-1.907h11.76V.954a.954.954 0 011.628-.675l7.629 7.629a.953.953 0 010 1.349l-7.629 7.628a.953.953 0 01-1.628-.674z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </span>
            </p>
          ) : null}
          {isTodayOrPast ? null : (
            <div className="d-flex justify-content-end w-100">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="21"
                height="31"
                fill="none"
                viewBox="0 0 31 41"
              >
                <path
                  fill="#231F20"
                  fillOpacity="0.46"
                  d="M28.417 15.375h-2.584V10.25C25.833 4.59 21.207 0 15.5 0S5.167 4.59 5.167 10.25v5.125H2.583A2.571 2.571 0 000 17.938v20.5A2.571 2.571 0 002.583 41h25.834A2.571 2.571 0 0031 38.437v-20.5a2.571 2.571 0 00-2.583-2.562zM15.5 30.75a2.571 2.571 0 01-2.583-2.563 2.571 2.571 0 012.583-2.562 2.571 2.571 0 012.583 2.563A2.571 2.571 0 0115.5 30.75zm5.167-15.375H10.333V10.25c0-2.83 2.314-5.125 5.167-5.125 2.853 0 5.167 2.295 5.167 5.125v5.125z"
                ></path>
              </svg>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default QuizVedio;
