import { create } from "zustand";
import custAxios, {
  attachToken,
  attachTokenWithFormAxios,
} from "../../configs/axiosConfig";
import { errorMessage, successMessage } from "../../services/helpers";

interface OrderDetail {
  totalUsdtSpent?: number;
  totalUsdBought?: number;
  totalOrders?: number;
  pendingOrders?: number;
  completedOrders?: number;
}
interface IOrder {
  status?: string;
  usdAmount?: number;
  usdtAmount?: number;
  reference?: string;
  paymentIntentId?: string;
  walletAddress?: string;
}

interface ICryptoStore {
  loading: boolean;

  usdtAmount: number;
  pages: number;
  docCount: number;
  orders: [];
  order: IOrder;
  transactionList: [];
  orderDetail: OrderDetail;
  usdtConversion: (values: any) => Promise<any>;
  createPaymentIntent: (values: any) => Promise<any>;
  createOrder: (values: any) => Promise<any>;
  fetchOrders: (page: number, searchFilter?: string) => Promise<any>;
  markOrderCompleted: (id: string) => Promise<any>;
  createBankOrder: (value: any) => Promise<any>;
}

export const useCryptoStore = create<ICryptoStore>((set) => ({
  loading: false,

  pages: 0,
  docCount: 0,
  usdtAmount: 0,
  orders: [],
  order: {},
  transactionList: [],
  orderDetail: {},

  usdtConversion: async (values) => {
    try {
      set({
        loading: true,
      });

      attachToken();
      const res = await custAxios.post(
        "/cryptoPurchase/usdt-conversion",
        values
      );

      if (res?.data?.success) {
        set({
          loading: false,
        });
        return res;
      }
    } catch (error) {
      set({
        loading: false,
      });
      console.error("Error fetching data:", error);
    }
  },
  createPaymentIntent: async (values) => {
    try {
      set({
        loading: true,
      });

      attachToken();
      const res = await custAxios.post(
        "/cryptoPurchase/create-payment-intent",
        {
          usdAmount: values,
        }
      );

      if (res?.data?.success) {
        set({
          loading: false,
        });
        return res;
      }
    } catch (error: any) {
      set({
        loading: false,
      });
      errorMessage(error?.response?.data?.message || "Something went wrong");
    }
  },
  createOrder: async (values) => {
    try {
      set({
        loading: true,
      });

      attachToken();
      const res = await custAxios.post("/cryptoPurchase/paypal-order", values);
      if (res?.data?.success) {
        set({
          loading: false,
          order: res?.data?.data?.order,
        });

        successMessage("Transaction successful!");
        return true;
      }
    } catch (error: any) {
      set({
        loading: false,
      });

      errorMessage(error?.response?.data?.message);
    }
  },

  createBankOrder: async (values) => {
    try {
      set({
        loading: true,
      });

      attachToken();
      const res = await custAxios.post("/cryptoPurchase/bank-order", values);
      if (res?.data?.success) {
        set({
          loading: false,
          order: res?.data?.data?.order,
        });

        return res;
      }
    } catch (error: any) {
      set({
        loading: false,
      });

      errorMessage(error?.response?.data?.message);
    }
  },

  fetchOrders: async (page, searchFilter = "") => {
    const pageSize = 10;

    let queryParams: { [key: string]: any } = {};
    queryParams.page = page;
    queryParams.pageSize = pageSize;
    queryParams.searchFilter = searchFilter;

    try {
      set({
        loading: true,
      });

      attachToken();

      const res = await custAxios.get("/cryptoPurchase/orders", {
        params: queryParams,
      });

      if (res?.data?.success) {
        const pages = Math.ceil(res?.data?.data?.docCount / pageSize);
        set({
          loading: false,
          transactionList: res?.data?.data?.orders?.orders || [],
          orders: res?.data?.data?.orders,

          orderDetail: res?.data?.data?.orderDetail,
          docCount: res?.data?.data?.docCount,
          pages: pages,
        });
        return true;
      }
    } catch (error: any) {
      set({
        loading: false,
      });

      errorMessage(error?.response?.data?.message);
    }
  },
  markOrderCompleted: async (id) => {
    try {
      set({
        loading: true,
      });

      attachToken();
      const res = await custAxios.put(`/cryptoPurchase/order/${id}/complete`);

      if (res?.data?.success) {
        set({
          loading: false,
        });
        successMessage(res?.data?.data?.message);
        return true;
      }
    } catch (error: any) {
      set({
        loading: false,
      });
      errorMessage(error?.response?.data?.message);
    }
  },
}));
