import React from "react";
import { Loader, PasswordInput, TextInput, Image } from "@mantine/core";
import logo from "../../assets/logo.png";
import { useAuth } from "../../services/hooks";
import { useAuthStore } from "../../zustand/store/AurthStore";
import { useShallow } from "zustand/react/shallow";
import { useForm } from "@mantine/form";
import { useLocation, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { useSettingStore } from "../../zustand/store/SettingStore";
import { baseURL } from "../../configs/axiosConfig";

const ResetPassword = () => {
  const { switchForm } = useAuth();
  const search = useLocation().search;
  const asToken = new URLSearchParams(search).get("resetToken");

  const [resetPasswordFunc, loading] = useAuthStore(
    useShallow((state) => [state.resetPasswordFunc, state.loading])
  );
  const [setting] = useSettingStore(useShallow((state) => [state.setting]));

  const form = useForm({
    initialValues: { password: "", confirmPassword: "" },
    validate: {
      password: (value) => (!value ? "You must have 8 characters" : null),
      confirmPassword: (value, values) =>
        value !== values.password ? "Passwords did not match" : null,
    },
  });

  const handleSubmit = async (values: any) => {
    let data = {
      ...values,
      resetToken: asToken,
    };

    const res = await resetPasswordFunc(data);

    if (res) {
      form.reset();
      switchForm("login");
    }
  };
  return (
    <motion.div
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      transition={{ duration: 1 }}
      className="signup-conatiner "
    >
      <div className="row ">
        <div className="col-md-12 col-lg-6">
          <div className="login-img-box ">
            <Image
              // radius="md"
              src={baseURL + setting[0]?.websiteSetting?.logo || logo}
              w={100}
              h={100}
              alt="logo"
              fallbackSrc={logo}
            />
          </div>
        </div>
        <div className="col-md-12 col-lg-6">
          <form onSubmit={form.onSubmit(handleSubmit)}>
            <p className="mt-5 register-title text-uppercase">
              Crear nueva contraseña
            </p>

            <div>
              <label className="form-label mt-2">
                <p className="form-label-text mb-2">Contraseña</p>
                <PasswordInput size="md" {...form.getInputProps("password")} />
              </label>
            </div>
            <div>
              <label className="form-label mt-2">
                <p className="form-label-text mb-2">
                  Vuelva a escribir la contraseña
                </p>
                <PasswordInput
                  size="md"
                  {...form.getInputProps("confirmPassword")}
                />
              </label>
            </div>

            <button
              disabled={loading ? true : false}
              className="py-2 my-3 create-account text-uppercase"
            >
              {loading ? <Loader size={"sm"} color="white" /> : "Continuar"}
            </button>
          </form>
        </div>
      </div>
    </motion.div>
  );
};

export default ResetPassword;
