import { create } from "zustand";
import custAxios, { attachToken } from "../../configs/axiosConfig";

interface IFaqStore {
  faqs: any;

  loading: boolean;
  getFaqs: () => Promise<any>;
  createFaq: (value: any) => Promise<any>;
  deleteFaq: (id: string) => Promise<any>;
  editFaq: (value: any, id: string) => Promise<any>;
}

export const useFaqStore = create<IFaqStore>((set) => ({
  faqs: [],

  loading: false,
  getFaqs: async () => {
    try {
      set({
        loading: true,
      });

      attachToken();
      const response = await custAxios.get("/faq/");

      set({
        loading: false,
        faqs: response?.data?.data?.faqs,
      });
      return response.data;
    } catch (error) {
      set({
        loading: false,
      });
      console.error("Error fetching data:", error);
    }
  },
  createFaq: async (value) => {
    try {
      set({
        loading: true,
      });

      attachToken();
      const response = await custAxios.post("/faq/", value);

      set({
        loading: false,
        faqs: response?.data?.data?.faqs,
      });
      return response.data;
    } catch (error) {
      set({
        loading: false,
      });
      console.error("Error fetching data:", error);
    }
  },
  deleteFaq: async (id) => {
    try {
      set({
        loading: true,
      });

      attachToken();
      const response = await custAxios.delete(`/faq/${id}`);

      set({
        loading: false,
      });
      return response.data;
    } catch (error) {
      set({
        loading: false,
      });
      console.error("Error fetching data:", error);
    }
  },
  editFaq: async (value, id) => {
    try {
      set({
        loading: true,
      });
      attachToken();
      const response = await custAxios.put(`/faq/${id}`, value);

      set({
        loading: false,
        // quizRank: response?.data?.data?.quizes,
      });
      return response.data;
    } catch (error) {
      set({
        loading: false,
      });
      console.error("Error fetching data:", error);
    }
  },
}));
