import React from "react";
import pageNotFound from "../assets/pagenotfound.png";
const NotFound: React.FC = () => {
  return (
    <div className="d-flex justify-content-center align-items-center my-5">
      <img width={"40%"} src={pageNotFound} alt="Not Found" />
    </div>
  );
};

export default NotFound;
