import React, { useEffect, useState } from "react";
import GeneralTable from "../../components/table/GeneralTable";
import { Loader, Pagination, Text, Tooltip } from "@mantine/core";
import { Link } from "react-router-dom";
import moment from "moment";
import { useShallow } from "zustand/react/shallow";
import { useCryptoStore } from "../../zustand/store/CryptoStore";
import { motion } from "framer-motion";
import { IconArrowLeft, IconArrowRight } from "@tabler/icons-react";
import { useAiChatStore } from "../../zustand/store/ChatStore";
import GeneralModal from "../../components/modal/GeneralModal";
import SetChatContext from "../../components/aiChat/setChatContext";
import { useDisclosure, useMediaQuery } from "@mantine/hooks";
import { DatePickerInput } from "@mantine/dates";
const ChatHistory: React.FC = () => {
  const {
    fetchChats,
    chats,
    chatCount: docCount,
    loading,
    pages,
  } = useAiChatStore(useShallow((state) => state));
  const limit = 10;
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [date, setDate] = useState<Date | null>(null);

  useEffect(() => {
    fetchChats({ page: currentPage, limit: limit, date: date });
  }, [date]);

  const paginationHandler = async (page: number) => {
    setCurrentPage(page);
    await fetchChats({ page: page, limit: limit });
  };

  console.log("chats...", chats);

  const columns = [
    {
      accessor: "id",
      title: "ID",
      width: "10%",
      render: (row: any, i: number) => {
        return <p className="ms-0mb-0 student-name">{i + 1}</p>;
      },
    },
    {
      accessor: "ip",
      title: "IP Address",
      render: (row: any) => {
        return <Text className="ms-0mb-0 student-name">{row?._id}</Text>;
      },
    },
    {
      accessor: "date",
      title: "Fecha",
      render: (row: any) => {
        const formattedDate = moment(row?.date).format("MM/DD/YYYY");
        return <p className=" mb-0 student-name"> {formattedDate}</p>;
      },
    },

    {
      accessor: "count",
      title: "Messages Count",
      render: (row: any) => {
        return <p className="ms-4 mb-0 student-name ">{row?.messages.length}</p>;
      },
    },

    {
      accessor: "action",
      title: "Acción",
      render: (row: any) => {
        return (
          <Link
            to={`/dashboard/single-chat/${row?.ip}`}
            state={row?.messages}
            style={{ color: "blue" }}
            // onClick={() => {
            //   open();
            //   setOrderData(row);
            // }}
            className="ms-0 mb-0 student-name"
          >
            View Detail
          </Link>
        );
      },
    },
  ];

  return (
    <div className="m-3">
      {" "}
      <div className="d-flex justify-content-end">
        <DatePickerInput
          w={300}
          placeholder="Filtro de fecha"
          // value={value}
          onChange={setDate}
        />
      </div>
      <div className="black-table amirbhaikelassan mt-3">
        {loading ? (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ height: "60vh" }}
          >
            <Loader color="black" />
          </div>
        ) : (
          <motion.div
            initial={{ opacity: 0, y: 100 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 1 }}
          >
            <GeneralTable loading={loading} columns={columns} records={chats} />

            {docCount > 10 && (
              <Pagination
                style={{ justifyContent: "center", display: "flex" }}
                className="mx-sm-2 mx-md-4 my-3 "
                total={pages}
                value={currentPage}
                nextIcon={IconArrowRight}
                previousIcon={IconArrowLeft}
                onChange={(page) => {
                  paginationHandler(page);
                }}
                boundaries={1}
              />
            )}
          </motion.div>
        )}
      </div>
    </div>
  );
};

export default ChatHistory;
