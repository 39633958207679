import React, { useEffect, useRef, useState } from "react";

import { Link } from "react-router-dom";
import { useTranscriptStore } from "../../zustand/store/TranscriptStore";
import { useShallow } from "zustand/react/shallow";
import { Loader } from "@mantine/core";
import { UseGetRole } from "../../services/hooks";
import YouTube from "react-youtube";
import EditButton from "../layout/EditButton";
import { motion } from "framer-motion";

interface IQuizData {
  createdAt: string;
  endDate: string;
  answers?: any;
  rank?: number;
  score?: number;
  user?: any;
  quiz?: any;
  question: {
    key: string;
    que: string;
    _id: string;
  }[];
  startDate: string;
  thumbnail: string;
  title: string;
  updatedAt: string;
  video: string;
  __v: number;
  _id: string;
}

const VideoSection: React.FC<{ data: IQuizData; dataContent: any }> = ({
  data,
  dataContent,
}) => {
  const role = UseGetRole();
  const [intervalId, setIntervalId] = useState<any>(null);

  const videoID = data?.video?.split("=");
  // const videoID = "WiTzqGAaFhg";
  const videoID1 = videoID[1]?.split("&");

  const [transcript, getTransript, loading] = useTranscriptStore(
    useShallow((state) => [
      state.transcript,
      state.getTranscript,
      state.loading,
    ])
  );

  useEffect(() => {
    if (videoID1?.length > 0) {
      const res = getTransript({
        videoId: videoID1[0],
        lang: "es",
      });
    }
  }, [data]);

  const [player, setPlayer] = useState<any>(null);
  const [currentTime, setCurrentTime] = useState<any>(0);

  // Ref for the YouTube player
  const playerRef = useRef(null);

  // Options for the YouTube player
  const opts = {
    height: "420px",
    width: "100%",
    playerVars: {
      // Add any additional player parameters here
      autoplay: 1,
      showinfo: 0,
    },
  };

  const onReady = (event: any) => {
    setPlayer(event.target);
  };

  const getYoutubeVideoPlayback = () => {
    setCurrentTime(player.getCurrentTime() || 0);
  };
  // Function to handle changes in the video's current time
  const onPlayerStateChange = (event: any) => {
    if (event.data === window.YT.PlayerState.PLAYING) {
      // Update the current time regularly while the video is playing
      const id = setInterval(getYoutubeVideoPlayback, 1000);
      setIntervalId(id);
    } else {
      clearInterval(intervalId);
    }
  };

  const highlightedTextRef = useRef<any>(null);

  useEffect(() => {
    const containerElement: any = document.querySelector(".paragraph-box");

    // Assuming you have a reference to the highlighted text element
    const highlightedTextElement: any =
      document.querySelector(".highlighted-text");

    // Check if both elements exist before attempting to scroll
    if (containerElement && highlightedTextElement) {
      // Calculate the offset based on the viewport width
      const offset =
        window.innerWidth < 991 ? 1000 : window.innerWidth < 768 ? 900 : 800;

      // Scroll to the top of the container element with an adjusted offset
      containerElement.scrollTop = highlightedTextElement.offsetTop - offset;
    }
  }, [currentTime]);

  return (
    <div className="video-container ">
      <div className="black-box ">
        <div className="container">
          <div className="row align-items-center">
            <motion.div
              initial={{ opacity: 0, x: 0 }}
              whileInView={{ opacity: 1, x: 0 }}
              transition={{ duration: 1 }}
              className="col-md-12 col-lg-6 "
            >
              <YouTube
                // videoId={"WiTzqGAaFhg&t"} // Replace with your actual YouTube video ID
                videoId={videoID1?.length > 0 ? videoID1[0] : videoID[1]} // Replace with your actual YouTube video ID
                opts={opts}
                onReady={onReady}
                onStateChange={onPlayerStateChange}
                ref={playerRef}
              />
            </motion.div>
            <motion.div
              initial={{ opacity: 0, x: 0 }}
              whileInView={{ opacity: 1, x: 0 }}
              transition={{ duration: 1 }}
              className="col-md-12 col-lg-6"
            >
              <div className="">
                {/* <div className="three-dots d-flex justify-content-end">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="35"
                    height="12"
                    fill="none"
                    viewBox="0 0 54 12"
                  >
                    <circle cx="6" cy="6" r="6" fill="#DBDDE9"></circle>
                    <circle cx="27" cy="6" r="6" fill="#DBDDE9"></circle>
                    <circle cx="48" cy="6" r="6" fill="#DBDDE9"></circle>
                  </svg>
                </div> */}
                <div>
                  {loading ? (
                    <div className="d-flex justify-content-center">
                      <Loader color="white" />
                    </div>
                  ) : (
                    <div>
                      {transcript?.length > 0 ? (
                        <div className="paragraph-box">
                          {transcript?.map((value: any, i: number) => {
                            const endtime = value?.duration + value?.start;

                            return (
                              <p
                                key={i}
                                ref={
                                  currentTime > value?.start
                                    ? highlightedTextRef
                                    : null
                                }
                                className={
                                  currentTime > value?.start &&
                                  currentTime < endtime
                                    ? "ps-2 highlighted-text video-paragraph mb-4"
                                    : "video-paragraph mb-4"
                                }
                              >
                                {value?.text}
                              </p>
                            );
                          })}
                        </div>
                      ) : (
                        <div className="h-100 mt-5 pt-5 d-flex justify-content-center align-items-center">
                          <div className="result-not-found">
                            <p className="not-found-text text-white mb-0">
                              No hay transcripción disponible
                            </p>
                            <p className="not-found-paragraph text-white">
                              ¡No hay transcripción disponible en este video!
                            </p>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </motion.div>
            <motion.div
              initial={{ opacity: 0, y: -100 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 1 }}
            >
              <Link to={"/quiz-hub"}>
                <button className="get-started my-5 px-4 py-2 text-uppercase">
                  más videos
                </button>
              </Link>
            </motion.div>
          </div>
        </div>
      </div>
      <div className="start-quiz container">
        <div className="row">
          <motion.div
            initial={{ opacity: 0, x: 0 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 1 }}
            className="col-lg-8 col-xl-9"
          >
            <p className="mb-0 ready-to-levelup">
              {(dataContent && dataContent[0]?.home?.listo_para) || ""}
              <EditButton
                dataKey="listo_para"
                pageName="home"
                inputType="text"
              />
            </p>
            <p className="mb-0 start-quiz-now">
              {(dataContent && dataContent[0]?.home?.empiece_el) || ""}
              <EditButton
                dataKey="empiece_el"
                pageName="home"
                inputType="text"
              />
            </p>
          </motion.div>
          <div className="col-lg-4 col-xl-3 d-flex justify-content-end align-items-center">
            {role !== "admin" && (
              <Link to={`/start-quiz/${data?._id ? data?._id : ""}`}>
                <button className="quiz-btn px-5 py-2">INICIAR EXAMEN</button>
              </Link>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoSection;
