import { Loader, Textarea } from "@mantine/core";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { useNavigate, useParams } from "react-router-dom";
import { useShallow } from "zustand/react/shallow";
import { useQuizStore } from "../zustand/store/QuizStore";
import { motion } from "framer-motion"
interface QuizQuestion {
  id?: number;
  key?: string;
  que?: string;
  _id?: string;
  ans?: string;
}
const StartQuiz: React.FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [getSingleQuizById, SingleQuizById, submitSingleQuizById, loading] =
    useQuizStore(
      useShallow((state) => [
        state.getSingleQuizById,
        state.singleQuizById,
        state.submitSingleQuizById,
        state.loading,
      ])
    );

  const fetchData = async () => {
    if (id) {
      await getSingleQuizById(id);
    } else {
      navigate(-1);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const [quizQuestionArray, setQuizQuestionArray] = useState<QuizQuestion[]>(
    SingleQuizById?.questions || []
  );

  const [answers, setAnswers] = useState<{ [key: number]: string }>({});
  const [currentQuestion, setCurrentQuestion] = useState<number>(0);
  const [currentQuestionData, setCurrentQuestionData] =
    useState<QuizQuestion>();

  useEffect(() => {
    if (SingleQuizById?.questions) {
      setCurrentQuestionData(SingleQuizById?.questions[currentQuestion]);
    }
  }, [SingleQuizById, currentQuestion]);

  const currentQuestionAnswer = answers[currentQuestion];
  const handleNext = () => {
    if (
      currentQuestionAnswer !== undefined &&
      currentQuestionAnswer?.trim() !== ""
    ) {
      const updatedQuizQuestion = [...quizQuestionArray];
      if (SingleQuizById?.questions) {
        updatedQuizQuestion[currentQuestion] = {
          ...updatedQuizQuestion[currentQuestion],
          key: SingleQuizById?.questions[currentQuestion].key,
          ans: currentQuestionAnswer,
        };
        setQuizQuestionArray(updatedQuizQuestion);
        setCurrentQuestion((prevQuestion) => prevQuestion + 1);
      }
    } else {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "yellow-btn px-5 py-2 mx-2",
          cancelButton: "black-btn px-5 py-2 mx-2",
        },
        buttonsStyling: false,
      });
      swalWithBootstrapButtons.fire({
        text: `Introduce la respuesta`,
      });
    }
  };

  const handlePrevious = () => {
    if (currentQuestion > 0) {
      setCurrentQuestion((prevQuestion) => prevQuestion - 1);
    }
  };

  const handleAnswerChange = (value: string) => {
    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      [currentQuestion]: value,
    }));
  };

  const handleSubmit = () => {
    if (
      currentQuestionAnswer !== undefined &&
      currentQuestionAnswer?.trim() !== ""
    ) {
      const updatedQuizQuestion = [...quizQuestionArray];
      if (SingleQuizById?.questions) {
        updatedQuizQuestion[currentQuestion] = {
          ...updatedQuizQuestion[currentQuestion],
          key: SingleQuizById?.questions[currentQuestion].key,
          ans: currentQuestionAnswer,
        };
        setQuizQuestionArray(updatedQuizQuestion);

        submitSingleQuizById(id, { answers: updatedQuizQuestion });
        // setCurrentQuestion((prevQuestion) => prevQuestion + 1);
      }
    }

    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "yellow-btn px-5 py-2 mx-2 text-uppercase",
      },
      buttonsStyling: false,
    });

    swalWithBootstrapButtons
      .fire({
        title: "¡Prueba completada!",
        text: "Recibirás un correo electrónico con el estado de tu prueba en breve",
        confirmButtonText: "Finalizar",
      })
      .then((result) => {
        if (result.isConfirmed) {
          // Call your function here
          navigate(-1);
        }
      });
  };

  return (
    <>
      {loading ? (
        <div className="my-4 d-flex justify-content-center">
          <Loader color="black" />
        </div>
      ) : (
        <motion.div initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{ duration: 1 }} className="container my-5 pt-5">
          <div className="question-container  my-4">
            <p className="text-center">
              <span className="question-number px-5 py-2">{`${
                currentQuestion + 1
              }/${SingleQuizById?.questions?.length || ""}`}</span>
            </p>
            <p className="question-text ">Q: {currentQuestionData?.que}</p>
            <Textarea
              placeholder="Responde aquí..."
              autosize
              value={answers[currentQuestion] || ""}
              minRows={20}
              maxRows={10}
              onChange={(e) => handleAnswerChange(e.target.value)}
            />
            <div className="d-flex justify-content-between mt-5">
              <button
                disabled={currentQuestion === 0 ? true : false}
                onClick={handlePrevious}
                className="previous-btn px-5 py-2 text-uppercase"
              >
                Previa
              </button>
              {currentQuestion + 1 > SingleQuizById?.questions?.length - 1 ? (
                <button
                  onClick={handleSubmit}
                  className="yellow-btn px-5 py-2 text-uppercase"
                >
                  Entregar
                </button>
              ) : (
                <button
                  onClick={handleNext}
                  className="next-btn px-5 py-2 text-uppercase"
                >
                  Próxima
                </button>
              )}
            </div>
          </div>
        </motion.div>
      )}
    </>
  );
};

export default StartQuiz;
