import { Loader, TextInput, rem } from "@mantine/core";
import { useForm } from "@mantine/form";
import { IconAt } from "@tabler/icons-react";
import React from "react";
import { useNewsletterStore } from "../../zustand/store/NewsLetter";
import { useShallow } from "zustand/react/shallow";
import { motion } from "framer-motion"

const Newsletter: React.FC = () => {
  const [sendNewsLetter, loading] = useNewsletterStore(
    useShallow((state) => [state.sendNewsLetter, state.loading])
  );

  const form = useForm({
    initialValues: { email: "" },

    validate: {
      email: (value) => (/^\S+@\S+$/.test(value) ? null : "Email inválido"),
    },
  });

  const handleSubmit = async (value: { email: string }) => {
    const res = await sendNewsLetter(value);
    if (res === "success") {
      form?.reset();
    }
  };

  return (
    <div className="news-letter-box  d-flex justify-content-center">
      <motion.div initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 1 }} className="container-box">
        <div className="newsletter-heading ">
          <p className=" text-center px-3 mb-0">
            No <span>omitir</span>
          </p>
        </div>
        <p className="newsletter-text text-center px-3 pt-3 pb-5 mb-0">
          ¡Infórmate de las últimas novedades y actualizaciones, suscríbete a
          nuestra newsletter!
        </p>
        <form
          style={{ height: 0 }}
          className="my-3"
          onSubmit={form.onSubmit(handleSubmit)}
        >
          <div className="newsletter-input d-flex align-items-start p-3  ">
            <TextInput
              className="w-100"
              leftSection={
                <IconAt style={{ width: rem(18), height: rem(18) }} />
              }
              placeholder="Ingrese correo electrónico"
              {...form.getInputProps("email")}
            />
            <button type="submit" className="ms-2 py-1 w-25 yellow-btn">
              {loading ? <Loader size={"sm"} color="white" /> : "Enviar"}
            </button>
          </div>
        </form>
      </motion.div>
    </div>
  );
};

export default Newsletter;
