// @ts-nocheck
import React, { useState, useEffect } from "react";
import GeneralTable from "../../components/table/GeneralTable";
import moment from "moment";
import { useCryptoStore } from "../../zustand/store/CryptoStore";
import { useShallow } from "zustand/react/shallow";
import { Button, Loader, Menu, Tooltip, Pagination, Text } from "@mantine/core";
import { IconArrowLeft, IconArrowRight } from "@tabler/icons-react";
import { FaCaretDown } from "react-icons/fa";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import ModalAlert from "../../components/modal/ModalAlert";
import { useDisclosure } from "@mantine/hooks";

const TransactionHistory = () => {
  const {
    fetchOrders,
    markOrderCompleted,
    transactionList,
    orderDetail,
    loading,
    pages,
    docCount,
  } = useCryptoStore(useShallow((state) => state));
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [search, setSearch] = useState<string>("");
  const [orderData, setOrderData] = useState<any>(null);
  const [opened, { open, close }] = useDisclosure(false);

  const handleSearch = async (e: any) => {
    if (e.keyCode === 13) {
      setSearch(e.target.value);
      await fetchOrders(currentPage, e.target.value);
    }
  };

  // pagination
  const paginationHandler = async (page: number) => {
    setCurrentPage(page);
    await fetchOrders(currentPage, search);
  };

  const handleButtonClick = async (id: string) => {
    const res = await markOrderCompleted(id);
    if (res) {
      fetchOrders(currentPage, search);
    }
  };

  useEffect(() => {
    fetchOrders(currentPage, search);
  }, [currentPage]);

  const columns = [
    {
      accessor: "user",
      title: "Usuario",
      width: "10%",
      render: (row: any) => {
        return <p className="ms-0mb-0 student-name">{row?.user?.name}</p>;
      },
    },
    {
      accessor: "date",
      title: "Fecha",
      render: (row: any) => {
        const formattedDate = moment(row?.createdAt).format("MM/DD/YYYY");
        return <p className=" mb-0 student-name"> {formattedDate}</p>;
      },
    },

    {
      accessor: "quiz",
      title: "ID de transacción",

      render: (row: any) => {
        return <Text className="ms-0mb-0 student-name">{row?._id}</Text>;
      },
    },
    {
      accessor: "wallet",
      title: "Cantidad",

      render: (row: any) => {
        return (
          <p className="ms-0mb-0 student-name">
            {row?.type === "bank"
              ? `${row?.clpAmount?.toFixed(4)} CLP`
              : `$${row?.usdAmount?.toFixed(4)}`}
          </p>
        );
      },
    },
    {
      accessor: "amount",
      title: "Dirección de la Billetera",
      width: "20%",
      render: (row: any) => {
        return (
          <Tooltip label={row?.walletAddress}>
            <Text lineClamp={1} className=" mb-0 student-name">
              {row?.walletAddress}
            </Text>
          </Tooltip>
        );
      },
    },

    {
      accessor: "status",
      title: "Estado",
      render: (row: any) => {
        return (
          <div className="d-flex justify-content-center">
            {/* @ts-ignore */}
            <Menu
              className={
                row?.status === "pending"
                  ? "border border-warning"
                  : "border border-success"
              }
            >
              <Menu.Target>
                <Button
                  justify="flex-start"
                  style={{
                    border: "none",
                    fontWeight: "normal",
                    fontSize: "12px",
                  }}
                  variant="default"
                  rightSection={<FaCaretDown />}
                >
                  {row?.status}
                </Button>
              </Menu.Target>

              {row?.status === "completed" ? (
                <Menu.Dropdown
                  style={{
                    maxHeight: "200px",
                    overflowY: "auto",
                    width: "10rem",
                    display: "none",
                  }}
                ></Menu.Dropdown>
              ) : (
                <Menu.Dropdown
                  style={{
                    maxHeight: "200px",
                    overflowY: "auto",
                    width: "10rem",
                  }}
                >
                  <Menu.Item onClick={() => handleButtonClick(row?._id)}>
                    Completed
                  </Menu.Item>
                </Menu.Dropdown>
              )}
            </Menu>
          </div>
        );
      },
    },

    {
      accessor: "action",
      title: "Acción",
      render: (row) => {
        return (
          <Link
            to={"#"}
            style={{ color: "blue" }}
            onClick={() => {
              open();
              setOrderData(row);
            }}
            className="ms-0 mb-0 student-name"
          >
            View Detail
          </Link>
        );
      },
    },
  ];

  return (
    <div>
      <div className="m-3">
        <div className="row align-items-center">
          <div className="col-sm-12 col-md-12 col-lg-12">
            <div className="row mb-3">
              <motion.div
                initial={{ opacity: 0, y: -100 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
                className="col-md-6 mt-sm-2 mt-md-3 mt-lg-0 col-lg-3"
              >
                <div className={`p-2rem ${1 % 2 === 0 ? "even" : "odd"}`}>
                  <p className="top-heading mb-0 text-capitalize">
                    Ganancias Totales
                  </p>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    {/* <p className="main-text mb-0">
                      ${orderDetail?.totalUsdBought?.toFixed(2) ?? 0}
                    </p> */}
                    <p className="main-text mb-0">
                      {orderDetail?.totalClpBought?.toFixed(2) ?? 0} CLP
                    </p>
                  </div>
                </div>
              </motion.div>
              <motion.div
                initial={{ opacity: 0, y: -100 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 1 }}
                className="col-md-6 mt-sm-2 mt-md-3 mt-lg-0 col-lg-3"
              >
                <div className={`p-2rem ${2 % 2 === 0 ? "even" : "odd"}`}>
                  <p className="top-heading mb-0 text-capitalize">
                    USDT Gastado
                  </p>
                  <p className="main-text mb-0">
                    {orderDetail?.totalUsdtSpent?.toFixed(4) ?? 0} USDT
                  </p>
                </div>
              </motion.div>
              <motion.div
                initial={{ opacity: 0, y: -100 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 1.5 }}
                className="col-md-6 mt-sm-2 mt-md-3 mt-lg-0 col-lg-3"
              >
                <div className={`p-2rem ${3 % 2 === 0 ? "even" : "odd"}`}>
                  <p className="top-heading mb-0 text-capitalize">
                    Órdenes totales
                  </p>
                  <p className="main-text mb-0">
                    {orderDetail?.totalOrders ?? 0}
                  </p>
                </div>
              </motion.div>
              <motion.div
                initial={{ opacity: 0, y: -100 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 2 }}
                className="col-md-6 mt-sm-2 mt-md-3 mt-lg-0 col-lg-3"
              >
                <div className={`p-2rem ${4 % 2 === 0 ? "even" : "odd"}`}>
                  <p className="top-heading mb-0 text-capitalize">
                    Órdenes Completadas
                  </p>
                  <p className="main-text mb-0">
                    {orderDetail?.completedOrders ?? 0}
                  </p>
                </div>
              </motion.div>
            </div>

            <motion.div
              initial={{ opacity: 0, y: -100 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 1 }}
              style={{ width: "30%", marginLeft: "15px", marginTop: "10px" }}
              className=" px-2 py-1 search-box"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="21"
                fill="none"
                viewBox="0 0 23 24"
              >
                <path
                  fill="#94A3B8"
                  d="M22.49 22.892a.924.924 0 01-.646.265.958.958 0 01-.658-.265l-3.113-3.126A10.648 10.648 0 01.58 11.383 10.645 10.645 0 0110.994.971a10.648 10.648 0 0110.855 9.951 10.645 10.645 0 01-2.471 7.54l3.113 3.113a.927.927 0 010 1.317zm-11.242-2.48a8.778 8.778 0 008.608-10.487A8.776 8.776 0 002.47 11.637a8.787 8.787 0 008.777 8.776z"
                ></path>
              </svg>
              <input
                placeholder="Busca aquí"
                className="ms-2 search-input"
                onChange={(e) => {
                  if (e.target.value === "") {
                    fetchOrders(currentPage, e.target.value);
                    setSearch("");
                  }
                }}
                onKeyDown={handleSearch}
              />
            </motion.div>
          </div>
        </div>
        <div className="black-table amirbhaikelassan my-5">
          {loading ? (
            <div className="d-flex justify-content-center align-items-center">
              <Loader color="black" />
            </div>
          ) : (
            <motion.div
              initial={{ opacity: 0, y: 100 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 1 }}
            >
              <GeneralTable
                loading={loading}
                columns={columns}
                records={transactionList}
                loading={loading}
              />

              {docCount > 10 && (
                <Pagination
                  style={{ justifyContent: "center", display: "flex" }}
                  className="mx-sm-2 mx-md-4 my-3 "
                  total={pages}
                  value={currentPage}
                  nextIcon={IconArrowRight}
                  previousIcon={IconArrowLeft}
                  onChange={(page) => {
                    paginationHandler(page);
                  }}
                  boundaries={1}
                />
              )}
            </motion.div>
          )}
        </div>
      </div>
      <ModalAlert
        children={
          <div>
            <h4 className="text-center mb-3">Detalle del pedido</h4>

            <div className="modal-div">
              <span className="modal-item">Correo electrónico:</span>
              <span className="modal-value">{orderData?.user?.email}</span>
            </div>
            <div className="modal-div">
              <span className="modal-item">Transacción:</span>
              <span className="modal-value">{orderData?._id}</span>
            </div>

            <div className="modal-div">
              <span className="modal-item">Método de Pago:</span>
              <span className="modal-value">
                {orderData?.bankName?.toUpperCase()}
              </span>
            </div>
            <div className="modal-div">
              <span className="modal-item">Dirección de Binance:</span>
              <span className="modal-value">{orderData?.walletAddress}</span>
            </div>
            <div className="modal-div">
              <span className="modal-item">Nombre de la cartera:</span>
              <span className="modal-value">{orderData?.walletName}</span>
            </div>
            <div className="modal-div">
              <span className="modal-item">
                Monto en {orderData?.type === "bank" ? "CLP" : "USD"}:
              </span>
              <span className="modal-value">
                {orderData?.type === "bank"
                  ? `${orderData?.clpAmount?.toFixed(2)} CLP`
                  : `$${orderData?.usdAmount?.toFixed(2)}`}
              </span>
            </div>
            <div className="modal-div">
              <span className="modal-item">Monto en USDT:</span>
              <span className="modal-value">
                {orderData?.usdtAmount?.toFixed(4)}
              </span>
            </div>
          </div>
        }
        opened={opened}
        close={close}
      />
    </div>
  );
};

export default TransactionHistory;
