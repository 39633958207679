import { Image, Loader, Select } from "@mantine/core";
import React, { useEffect, useState } from "react";
import GeneralTable from "../components/table/GeneralTable";
import profile from "../assets/profile.png";
import { Link, useParams } from "react-router-dom";
import { useUserStore } from "../zustand/store/UserStore";
import { useShallow } from "zustand/react/shallow";
import moment from "moment";
import { baseURL } from "../configs/axiosConfig";
import { useSettingStore } from "../zustand/store/SettingStore";
import { motion } from "framer-motion";

const MyQuiz: React.FC = () => {
  const [sortBy, setSortBy] = useState<string | null>("");
  const [searchBy, setSearchBy] = useState<string>("");

  // const [setting] = useSettingStore(useShallow((state) => [state.setting]));

  const [singleUserQuizes, quizesOfSingleUser, quizesOfLoginUser, loading] =
    useUserStore(
      useShallow((state) => [
        state.singleUserQuizes,
        state.quizesOfSingleUser,
        state.quizesOfLoginUser,
        state.loading,
      ])
    );

  const [userdata] = useUserStore(useShallow((state) => [state.user]));

  const { id } = useParams();

  useEffect(() => {
    if (id) {
      if (sortBy) {
        quizesOfSingleUser(id, sortBy);
      } else if (searchBy) {
        quizesOfSingleUser(id, searchBy);
      } else if (searchBy && sortBy) {
        quizesOfSingleUser(id, sortBy, searchBy);
      } else {
        quizesOfSingleUser(id);
      }
    } else {
      if (sortBy || searchBy) {
        quizesOfLoginUser(searchBy);
      } else {
        quizesOfLoginUser();
      }
    }
  }, [sortBy]);

  const handleSearch = (e: any) => {
    if (e.key === "Enter") {
      if (id) {
        quizesOfSingleUser(id, sortBy, searchBy);
      } else {
        quizesOfLoginUser(sortBy, searchBy);
      }
    }
  };

  const columns = [
    {
      accessor: "name",
      title: "Nombre",
      render: (row: any) => {
        return (
          <div className="d-flex align-items-center justify-content-center">
            <Image
              className="profile-pic"
              width={60}
              height={60}
              fallbackSrc={`https://placehold.co/400x400/orange/white?text=${
                id ? row?.user?.name : userdata?.name
              }`}
              src={
                id
                  ? baseURL + row?.user?.profilePic
                  : baseURL + userdata?.profilePic || profile
              }
              alt="img"
            />{" "}
            <p className="ms-3 mb-0 student-name">
              {id ? row?.user?.name : userdata?.name || ""}
            </p>
          </div>
        );
      },
    },
    {
      accessor: "quiz",
      title: "Prueba Nombre",
      render: (row: any) => {
        return <p className="ms-3 mb-0 student-name">{row?.quiz?.title}</p>;
      },
    },
    {
      accessor: "date",
      title: "Fecha",
      render: (row: any) => {
        const formattedDate = moment(row?.updatedAt).format("MM/DD/YYYY");
        return <p className="ms-3 mb-0 quiz-date">{formattedDate}</p>;
      },
    },
    {
      accessor: "position",
      title: "Posición",
      render: (row: any) => {
        return <p className="ms-3 mb-0 student-name">{row.rank || "-"}</p>;
      },
    },
    {
      accessor: "action",
      title: "Acción",
      render: (row: any) => (
        <Link to={`/quiz-details/${row?.quiz?._id}/${row?.user?._id}`}>
          <button className="view-detail-btn py-2 px-3 text-uppercase">
            Ver detalles
          </button>
        </Link>
      ),
    },
  ];
  // <div className=" h-100 d-flex justify-content-center align-items-center">
  //   <Loader color="black" />
  // </div>;
  return (
    <div className="container py-5">
      <div className="winner-heading my-4 mt-5  d-flex justify-content-start">
        <p className="py-3 px-5 ">
          {id ? "Toda" : "MI"} <span>PRUEBA</span>
        </p>
      </div>
      <div className="search-an-filter  my-4 ">
        <div className="px-2 search-bar d-flex align-items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="17"
            height="18"
            fill="none"
            viewBox="0 0 23 24"
          >
            <path
              fill="#94A3B8"
              d="M22.491 22.891a.924.924 0 01-.646.266.958.958 0 01-.658-.266l-3.113-3.125A10.648 10.648 0 01.58 11.383 10.645 10.645 0 0110.995.971a10.648 10.648 0 0110.856 9.95 10.645 10.645 0 01-2.472 7.54l3.112 3.114a.924.924 0 010 1.316zM11.25 20.413a8.777 8.777 0 006.206-14.981 8.777 8.777 0 00-14.983 6.205 8.787 8.787 0 008.777 8.776z"
            ></path>
          </svg>
          <input
            value={searchBy}
            className="search-input ps-3"
            placeholder="Busca Aquí"
            onChange={(e) => setSearchBy(e.target.value)}
            onKeyDown={handleSearch}
          />
        </div>
        <div className="filter-box d-flex align-items-center justify-content-end">
          <p className="mb-0 me-3 select-label">Ordenar por:</p>
          <Select
            mt="md"
            data={[
              { value: "lowestRank", label: "Posición más baja" },
              { value: "highestRank", label: "Posición superior" },
              { value: "avgRank", label: "Posición promedio" },
            ]}
            // data={["Lowest Position", "Top Position", "Average Position"]}
            rightSectionPointerEvents="none"
            rightSection={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="10"
                height="5"
                fill="none"
                viewBox="0 0 10 5"
              >
                <path fill="#fff" d="M5 0L0 5h10L5 0z"></path>
              </svg>
            }
            value={sortBy}
            onChange={(_value: any) => setSortBy(_value)}
            // onChange={setSortBy}
            placeholder="Seleccionar"
          />
        </div>
      </div>
      {loading ? (
        <div className=" h-100 d-flex justify-content-center align-items-center">
          <Loader color="black" />
        </div>
      ) : (
        <motion.div
          initial={{ opacity: 0, y: 100 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 1 }}
          className="pt-3"
        >
          {singleUserQuizes?.length < 1 ? (
            <div className="result-not-found mt-5">
              <p className="not-found-text mb-0">No se encontraron registros</p>
              <p className="not-found-paragraph">
                No hay ningún registro basado en su búsqueda.
              </p>
            </div>
          ) : (
            <GeneralTable
              loading={loading}
              columns={columns}
              records={singleUserQuizes}
            />
          )}
        </motion.div>
      )}
    </div>
  );
};

export default MyQuiz;
