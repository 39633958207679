import { Loader, Menu, TextInput } from "@mantine/core";
import { useListState } from "@mantine/hooks";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { useNavigate, useParams } from "react-router-dom";
import { useQuizStore } from "../../zustand/store/QuizStore";
import { useShallow } from "zustand/react/shallow";
import { motion } from "framer-motion";
interface QuizQuestion {
  id?: number;
  key?: string;
  que?: string;
  _id?: string;
  ans?: string;
}
const CourseDetails: React.FC = () => {
  const { id } = useParams();

  const [getSingleQuizById, SingleQuizById, editSingleQuizById, loading] =
    useQuizStore(
      useShallow((state) => [
        state.getSingleQuizById,
        state.singleQuizById,
        state.editSingleQuizById,
        state.loading,
      ])
    );

  useEffect(() => {
    const fetchData = async () => {
      await getSingleQuizById(id);
    };
    fetchData();
  }, [getSingleQuizById, id]);

  const navigate = useNavigate();
  const [video, setVideo] = useState<string>(SingleQuizById?.video || "");

  const [questionArray, setQuestionArray] = useState<QuizQuestion[]>(
    SingleQuizById?.questions || [{ key: 0, que: "" }]
  );

  useEffect(() => {
    if (SingleQuizById?.questions) {
      setQuestionArray(SingleQuizById?.questions);
      setVideo(SingleQuizById?.video);
    }
  }, [SingleQuizById?.questions]);

  const [state, handlers] = useListState(questionArray);
  const handleAddQuestion = () => {
    if (
      questionArray?.length > 0 &&
      questionArray[questionArray.length - 1]?.que?.trim() !== ""
    ) {
      setQuestionArray([...questionArray, { key: "", que: "" }]);

      handlers.setState([...questionArray, { key: "", que: "" }]);
    } else {
    }
  };

  const handleRemoveQuestion = (indexToRemove: any) => {
    if (
      questionArray &&
      questionArray.length > 0 &&
      indexToRemove >= 0 &&
      indexToRemove < questionArray.length
    ) {
      const updatedQuestions = [...questionArray];
      updatedQuestions.splice(indexToRemove, 1);

      setQuestionArray(updatedQuestions);
      handlers.setState(updatedQuestions);
    } else {
      // Handle the case when the index is out of bounds or there are no questions to remove
    }
  };

  const icon = (i: number): React.ReactNode => (
    <Menu trigger="hover" closeDelay={400} position="left-start">
      {/* ... menu items */}
      <Menu.Target>
        <svg
          className="cursor-pointer"
          width="5"
          height="16"
          viewBox="0 0 5 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M2.5 4C1.83696 4 1.20107 3.78929 0.732234 3.41421C0.263393 3.03914 0 2.53043 0 2C0 1.46957 0.263393 0.960859 0.732234 0.585786C1.20107 0.210714 1.83696 0 2.5 0C3.16304 0 3.79893 0.210714 4.26777 0.585786C4.73661 0.960859 5 1.46957 5 2C5 2.53043 4.73661 3.03914 4.26777 3.41421C3.79893 3.78929 3.16304 4 2.5 4ZM2.5 10C1.83696 10 1.20107 9.78929 0.732234 9.41421C0.263393 9.03914 0 8.53043 0 8C0 7.46957 0.263393 6.96086 0.732234 6.58579C1.20107 6.21071 1.83696 6 2.5 6C3.16304 6 3.79893 6.21071 4.26777 6.58579C4.73661 6.96086 5 7.46957 5 8C5 8.53043 4.73661 9.03914 4.26777 9.41421C3.79893 9.78929 3.16304 10 2.5 10ZM2.5 16C1.83696 16 1.20107 15.7893 0.732234 15.4142C0.263393 15.0391 0 14.5304 0 14C0 13.4696 0.263393 12.9609 0.732234 12.5858C1.20107 12.2107 1.83696 12 2.5 12C3.16304 12 3.79893 12.2107 4.26777 12.5858C4.73661 12.9609 5 13.4696 5 14C5 14.5304 4.73661 15.0391 4.26777 15.4142C3.79893 15.7893 3.16304 16 2.5 16Z"
            fill="#C7C6C7"
          />
        </svg>
      </Menu.Target>
      <Menu.Dropdown>
        <Menu.Item onClick={() => handleRemoveQuestion(i)}>Delete</Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );

  const handleSubmit = () => {
    const formData = new FormData();

    formData.append("title", SingleQuizById.title);
    formData.append("video", video);
    formData.append("startDate", SingleQuizById.startDate);
    formData.append("endDate", SingleQuizById.endDate);
    formData.append("questions", JSON.stringify(questionArray));
    editSingleQuizById(SingleQuizById._id, formData);
  };

  // const handlePublishCourse = async (e: any) => {
  //   e.preventDefault();

  //   const swalWithBootstrapButtons = Swal.mixin({
  //     customClass: {
  //       confirmButton: "yellow-btn px-5 py-2 mx-2",
  //       cancelButton: "black-btn px-5 py-2 mx-2",
  //     },
  //     buttonsStyling: false,
  //   });
  //   swalWithBootstrapButtons
  //     .fire({
  //       text: `¡Buen trabajo! Has añadido ${questionArray?.length} preguntas. ¿Estás seguro de que quieres publicar el curso y ponerlo a disposición de los usuarios?`,
  //       showCancelButton: true,
  //       confirmButtonText: "Sí",
  //       cancelButtonText: "No",
  //       reverseButtons: true,
  //     })
  //     .then((result) => {
  //       if (result.isConfirmed) {
  //         handleSubmit();
  //         swalWithBootstrapButtons.fire({
  //           title: "¡Prueba publicada!",
  //           text: "Su cuestionario ha sido publicado.",
  //         });
  //       } else if (
  //         /* Read more about handling dismissals below */
  //         result.dismiss === Swal.DismissReason.cancel
  //       ) {
  //         swalWithBootstrapButtons.fire({
  //           title: "¡Prueba no publicada!",
  //           text: "Su cuestionario no ha sido publicado.",
  //         });
  //       }
  //     });
  // };

  const handlePublishCourse = async (values: any) => {
    if (
      questionArray.length < 1 ||
      questionArray[questionArray.length - 1]?.que?.trim() === "" ||
      video.trim() === "" ||
      SingleQuizById.title.trim() === "" ||
      !SingleQuizById.endDate ||
      !SingleQuizById.endDate
      // !thumbnailImg
    ) {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "yellow-btn px-5 py-2 mx-2",
          cancelButton: "black-btn px-5 py-2 mx-2",
        },
        buttonsStyling: false,
      });
      swalWithBootstrapButtons.fire({
        text: `Todos los campos son obligatorios`,
      });
    } else {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "yellow-btn px-5 py-2 mx-2",
          cancelButton: "black-btn px-5 py-2 mx-2",
        },
        buttonsStyling: false,
      });
      swalWithBootstrapButtons
        .fire({
          text: `¡Buen trabajo! has agregado ${questionArray?.length} preguntas. ¿Estás seguro de que quieres publicar el curso y ponerlo a disposición de los usuarios?`,
          showCancelButton: true,
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          reverseButtons: true,
        })
        .then((result) => {
          if (result.isConfirmed) {
            const formData = new FormData();
            formData.append("title", SingleQuizById.title);
            formData.append("video", video);
            formData.append("startDate", SingleQuizById.startDate);
            formData.append("endDate", SingleQuizById.endDate);
            formData.append("questions", JSON.stringify(questionArray));
            editSingleQuizById(SingleQuizById._id, formData);
            swalWithBootstrapButtons
              .fire({
                title: "¡Prueba publicada!",
                text: "Su cuestionario ha sido publicado.",
              })
              .then((result) => {
                if (result.isConfirmed) {
                  navigate(-1);
                }
              });
          } else if (result.dismiss === Swal.DismissReason.cancel) {
            swalWithBootstrapButtons.fire({
              title: "¡Prueba no publicada!",
              text: "Su cuestionario no ha sido publicado.",
            });
          }
        });
    }
  };
  // <div className=" h-100 d-flex justify-content-center align-items-center">
  //   <Loader color="black" />
  // </div>
  return (
    <>
      <>
        <motion.p
          initial={{ opacity: 0, x: 0 }}
          whileInView={{ opacity: 1, x: 0 }}
          transition={{ duration: 1 }}
          className="mx-4 mt-3 back-text"
        >
          {" "}
          <svg
            className="me-3"
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="10"
            fill="none"
            viewBox="0 0 21 14"
          >
            <path
              fill="#000"
              fillRule="evenodd"
              d="M7.449 12.654a1.305 1.305 0 01-1.846 0L.383 7.433a1.305 1.305 0 010-1.846l5.22-5.22A1.305 1.305 0 017.45 2.211L4.456 5.205h15.122a1.305 1.305 0 110 2.61H4.456l2.993 2.993a1.305 1.305 0 010 1.846z"
              clipRule="evenodd"
            ></path>
          </svg>
          <span onClick={() => navigate(-1)}>Atrás</span>
        </motion.p>
        <div className="my-4 mx-3">
          <form>
            <motion.div
              initial={{ opacity: 0, y: -100 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 1 }}
              className=" d-flex justify-content-between align-items-center"
            >
              <p className="course-name mb-0">{SingleQuizById?.title}</p>
              <button
                type="button"
                onClick={() =>
                  navigate(`/dashboard/leaderboard-management`, {
                    state: {
                      title: SingleQuizById?.title,
                      id: SingleQuizById?._id,
                    },
                  })
                }
                className="black-btn px-4 py-2  "
              >
                ver participantes
              </button>
            </motion.div>

            {loading ? (
              <div
                style={{ margin: "4rem 0", height: "100px" }}
                className="d-flex justify-content-center align-items-center"
              >
                <Loader color="black" />
              </div>
            ) : (
              <>
                <motion.div
                  initial={{ opacity: 0, y: 100 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ duration: 1 }}
                  className="course-field"
                >
                  <label className="w-100">
                    <p className="course-form-lable mt-3 mb-2">
                      Enlace de video{" "}
                      <svg
                        className="ms-2"
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="none"
                        viewBox="0 0 20 20"
                      >
                        <path
                          stroke="#000"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeOpacity="0.25"
                          strokeWidth="2"
                          d="M11 14h-1v-4H9m1-4h.01M19 10a9 9 0 11-18 0 9 9 0 0118 0z"
                        ></path>
                      </svg>
                    </p>
                    <TextInput
                      value={video}
                      size="md"
                      placeholder="https://www.youtube.com/watch?v=LmpL_rrT8ho"
                      onChange={(e) => {
                        setVideo(e.target.value);
                      }}
                    />
                  </label>
                </motion.div>

                {questionArray?.map((value, i) => {
                  return (
                    <motion.div
                      initial={{ opacity: 0, y: 100 }}
                      whileInView={{ opacity: 1, y: 0 }}
                      transition={{ duration: 1 }}
                      key={i}
                      className="course-field"
                    >
                      <label className="w-100 mt-4">
                        <TextInput
                          //   rightSectionPointerEvents="none"
                          rightSection={icon(i)}
                          value={value?.que}
                          size="md"
                          placeholder="Añadir pregunta aquí"
                          onChange={(event) => {
                            setQuestionArray((prevQuestionArray) => {
                              const updatedArray = [...prevQuestionArray];

                              updatedArray[i].key =
                                questionArray.length.toString();
                              updatedArray[i].que = event.target.value;
                              return updatedArray;
                            });
                          }}
                        />
                      </label>
                    </motion.div>
                  );
                })}
                <div className="mt-3">
                  <button
                    onClick={handleAddQuestion}
                    type="button"
                    className="black-btn px-3 py-2"
                  >
                    AÑADIR PREGUNTA
                  </button>
                </div>
                <div className="mt-3 d-flex justify-content-end ">
                  <button
                    onClick={handlePublishCourse}
                    type="button"
                    className=" publish-btn text-uppercase yellow-btn px-4 py-2"
                  >
                    guardar cambios
                  </button>
                </div>
              </>
            )}
          </form>
        </div>
      </>
    </>
  );
};

export default CourseDetails;
