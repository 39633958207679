import React, { useEffect } from "react";
import logo from "../../assets/logo.png";
import { Link } from "react-router-dom";
import { useDisclosure, useMediaQuery } from "@mantine/hooks";
import { Image } from "@mantine/core";
import whiteInlineLogo from "../../assets/whiteInlineLogo.png";
import { HashLink } from "react-router-hash-link";
import GeneralModal from "../modal/GeneralModal";
import Login from "../auth/Login";
import Register from "../auth/Register";
import { getToken, useAuth } from "../../services/hooks";
import { useSettingStore } from "../../zustand/store/SettingStore";
import { useShallow } from "zustand/react/shallow";
import EditButton from "./EditButton";
import { baseURL } from "../../configs/axiosConfig";
import TermsAndConditions from "../auth/TermsAndConditions";

const Footer: React.FC = () => {
  const token = getToken();
  const [setting] = useSettingStore(useShallow((state) => [state.setting]));

  useEffect(() => {
    close();
  }, [token]);

  const [opened, { open, close }] = useDisclosure();
  const [openedTerms, { open: openTerms, close: closeTerms }] = useDisclosure();
  const { currentForm } = useAuth();
  const isMobile = useMediaQuery("(max-width: 576px)");
  return (
    <div className="footer-box ">
      <div className="container  pt-4">
        <div className="row py-5 footer-container ">
          <div className="col-sm-12 col-md-6 col-xl-4">
            <Link to={"/"}>
              <Image
                w={120}
                h={120}
                className=" footer-logo"
                src={baseURL + setting[0]?.websiteSetting?.logo || logo}
                alt="logo"
                fallbackSrc={logo}
              />
            </Link>
            <p className="white-text mb-0  web-detail">
              {(setting && setting[0]?.footer?.footer_about) || ""}
              <EditButton
                dataKey="footer_about"
                pageName="footer"
                inputType="text"
              />
            </p>
          </div>
          <div className="col-sm-12 col-md-6 col-xl-3">
            <p className="yellow-text ms-4 ">Contacta con nosotros </p>
            <ul className="footer-list">
              <li className="my-3">
                <a
                  className="text-decoration-none"
                  href={`mailto:${setting && setting[0]?.footer?.footer_email}`}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="15"
                    height="15"
                    fill="none"
                    viewBox="0 0 25 16"
                  >
                    <path
                      fill="#FBC926"
                      d="M0 16V0h25v16H0zm12.5-7l10-5V2l-10 5-10-5v2l10 5z"
                    ></path>
                  </svg>
                  <span className="ms-2 white-text">
                    {(setting && setting[0]?.footer?.footer_email) || ""}
                    <EditButton
                      dataKey="footer_email"
                      pageName="footer"
                      inputType="text"
                    />
                  </span>
                </a>
              </li>
              <li className="my-3">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="15"
                  height="17"
                  fill="none"
                  viewBox="0 0 18 17"
                >
                  <path
                    fill="#FBC926"
                    d="M12.557 10.906l-.455.453s-1.083 1.076-4.038-1.862-1.872-4.014-1.872-4.014l.286-.286c.707-.702.774-1.83.157-2.654L5.375.86C4.61-.16 3.135-.295 2.26.575L.69 2.135c-.433.432-.723.99-.688 1.61.09 1.587.808 5 4.812 8.982 4.247 4.222 8.232 4.39 9.86 4.238.517-.048.965-.31 1.326-.67l1.42-1.412c.96-.953.69-2.588-.538-3.255l-1.91-1.039c-.806-.437-1.786-.309-2.416.317z"
                  ></path>
                </svg>
                <span className="ms-2 white-text">
                  {(setting && setting[0]?.footer?.footer_number) || ""}
                  <EditButton
                    dataKey="footer_number"
                    pageName="footer"
                    inputType="text"
                  />
                </span>
              </li>
            </ul>
          </div>
          <div className="col-sm-12 col-md-6 col-xl-2">
            <p className="yellow-text ms-4">enlaces rápidos</p>
            <ul className="footer-list">
              <li className="my-3">
                <a href="/" className=" white-text">
                  Hogar
                </a>
              </li>
              <li className="my-3">
                <HashLink smooth to="/#about-us" className=" white-text">
                  Sobre nosotros
                </HashLink>
              </li>
              {!token && (
                <li className="my-3">
                  <span onClick={open} className="cursor-pointer white-text">
                    Inscribirse
                  </span>
                </li>
              )}
              <li className="my-3">
                <HashLink smooth to="/#faqs" className="my-3 white-text">
                  <span className=" white-text">FAQs</span>
                </HashLink>
              </li>
            </ul>
          </div>
          <div className="col-sm-12 col-md-6 col-xl-3">
            <p className="yellow-text ms-4">Síganos</p>
            <ul className="footer-list">
              <li className="my-3">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="15"
                  height="19"
                  fill="none"
                  viewBox="0 0 20 19"
                >
                  <path
                    fill="#FBC926"
                    fillRule="evenodd"
                    d="M0 8.444c0-3.98 0-5.97 1.302-7.207C2.603 0 4.7 0 8.89 0h2.222c4.19 0 6.286 0 7.587 1.237C20 2.473 20 4.464 20 8.444v2.112c0 3.98 0 5.97-1.302 7.207C17.397 19 15.3 19 11.11 19H8.89c-4.19 0-6.286 0-7.587-1.237C0 16.527 0 14.536 0 10.556V8.444zM16.667 4.75c0 .42-.176.823-.489 1.12A1.712 1.712 0 0115 6.333c-.442 0-.866-.166-1.178-.463a1.544 1.544 0 01-.489-1.12c0-.42.176-.823.489-1.12A1.712 1.712 0 0115 3.167c.442 0 .866.166 1.178.463.313.297.489.7.489 1.12zm-4.445 5.806c0 .56-.234 1.096-.65 1.492a2.283 2.283 0 01-1.572.619c-.59 0-1.155-.223-1.571-.619a2.059 2.059 0 01-.651-1.492c0-.56.234-1.097.65-1.493A2.283 2.283 0 0110 8.444c.59 0 1.155.223 1.571.619.417.396.651.933.651 1.493zm2.222 0c0 1.12-.468 2.193-1.301 2.985A4.566 4.566 0 0110 14.778a4.566 4.566 0 01-3.143-1.237 4.118 4.118 0 01-1.301-2.985c0-1.12.468-2.194 1.301-2.986A4.566 4.566 0 0110 6.333c1.179 0 2.31.445 3.143 1.237a4.118 4.118 0 011.301 2.986z"
                    clipRule="evenodd"
                  ></path>
                </svg>
                <Link
                  to={setting[0]?.websiteSetting?.instagram}
                  target="_blank"
                  className="ms-2 white-text"
                >
                  Instagram
                </Link>
              </li>
              <li className="my-3">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="15"
                  height="17"
                  fill="none"
                  viewBox="0 0 22 18"
                >
                  <path
                    fill="#FBC926"
                    d="M22 2.137a9.004 9.004 0 01-2.592.714A4.551 4.551 0 0021.39.338a8.989 8.989 0 01-2.865 1.103 4.489 4.489 0 00-5.427-.903 4.53 4.53 0 00-1.984 2.135 4.568 4.568 0 00-.28 2.91c-1.794-.09-3.55-.56-5.153-1.377a12.83 12.83 0 01-4.15-3.37A4.549 4.549 0 00.92 3.122c0 1.575.797 2.966 2.007 3.78a4.478 4.478 0 01-2.044-.568v.056c0 1.049.36 2.066 1.02 2.878a4.512 4.512 0 002.6 1.577 4.51 4.51 0 01-2.039.078c.288.903.85 1.693 1.606 2.258.756.566 1.67.88 2.612.897A9.005 9.005 0 010 15.958 12.71 12.71 0 006.918 18C15.22 18 19.76 11.078 19.76 5.076c0-.196-.005-.393-.013-.588a9.204 9.204 0 002.25-2.35"
                  ></path>
                </svg>
                <Link
                  to={setting[0]?.websiteSetting?.twitter}
                  target="_blank"
                  className="ms-2 white-text "
                >
                  Twitter
                </Link>
              </li>
              <li className="my-3">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="15"
                  height="17"
                  fill="none"
                  viewBox="0 0 18 20"
                >
                  <path
                    fill="#FBC926"
                    d="M14.963 4.277a4.446 4.446 0 01-.386-.229 5.46 5.46 0 01-.992-.859A4.811 4.811 0 0112.468.838h.004C12.387.329 12.422 0 12.427 0H9.06v13.282c0 .178 0 .354-.008.529l-.003.065c0 .01 0 .02-.002.03v.007a2.956 2.956 0 01-.436 1.34c-.251.404-.596.738-1.003.974a2.769 2.769 0 01-1.394.375c-1.57 0-2.841-1.304-2.841-2.916 0-1.612 1.272-2.916 2.841-2.916.297 0 .592.047.875.14l.004-3.497a6.142 6.142 0 00-2.559.204A6.22 6.22 0 002.27 8.852a6.717 6.717 0 00-1.442 1.813c-.142.25-.679 1.255-.744 2.885-.04.925.232 1.884.362 2.28v.009c.082.233.398 1.03.914 1.7a6.901 6.901 0 001.46 1.404v-.008l.008.008C4.458 20.073 6.267 20 6.267 20c.313-.013 1.361 0 2.552-.575 1.32-.638 2.072-1.589 2.072-1.589a6.628 6.628 0 001.13-1.913 7.292 7.292 0 00.406-2.189V6.687c.041.025.585.392.585.392s.784.512 2.008.846c.878.238 2.06.288 2.06.288v-3.41c-.414.046-1.256-.088-2.117-.526z"
                  ></path>
                </svg>
                <Link
                  to={setting[0]?.websiteSetting?.linkedin}
                  target="_blank"
                  className="ms-2 white-text"
                >
                  Tiktok
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="mt-4 container py-4 copyright-section d-flex justify-content-between align-items-center flex-wrap">
        <p className="mb-0 white-text">
          Copyright © 2023 <span className="yellow-text"> YOURWAY </span>. All
          Rights Reserved.{" "}
          <Link
            to={"https://dotclickllc.com/"}
            target="_blank"
            className="yellow-text"
          >
            DotClick
          </Link>
        </p>
        <span onClick={openTerms} className="mb-0 white-text cursor-pointer">
          Privacy & Policy
        </span>
      </div>
      {/* <GeneralModal
        opened={opened}
        loading={true}
        title={"sign up"}
        Component={currentForm === "login" ? Login : Register}
        openModal={open}
        closeModal={close}
        size={"80%"}
      />
      <GeneralModal
        opened={openedTerms}
        loading={true}
        title={"terms and conditions"}
        Component={TermsAndConditions}
        openModal={openTerms}
        closeModal={closeTerms}
        size={"70%"}
      /> */}
    </div>
  );
};

export default Footer;
