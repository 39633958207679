import React, { useEffect, useState } from "react";
import {
  DashboardLeaderCard,
  DashboardLeaderCardList,
} from "../../components/cards/dashboard/dashboardLeaderCard";
import { Loader, Menu } from "@mantine/core";
import { useShallow } from "zustand/react/shallow";
import { useQuizStore } from "../../zustand/store/QuizStore";
import { useLocation } from "react-router-dom";
import { motion } from "framer-motion";

const LeaderboardManagement: React.FC = () => {
  const location = useLocation();
  const [display, setDisplay] = useState<string>("row");
  const [selectQuiz, setSelectQuiz] = useState<string>("select quiz");
  const [selectQuizId, setSelectQuizId] = useState<string>("");

  const [
    quizArray,
    getQuizs,
    getSingleQuizParticipantsById,
    quizParticipants,
    loading,
  ] = useQuizStore(
    useShallow((state) => [
      state.quiz,
      state.getQuizs,
      state.getSingleQuizParticipantsById,
      state.quizParticipants,
      state.loading,
    ])
  );

  useEffect(() => {
    getQuizs();
  }, []);

  useEffect(() => {
    if (location?.state?.title) {
      setSelectQuizId(location?.state?.id);
      setSelectQuiz(location?.state?.title || "select quiz");
    } else if (quizArray) {
      setSelectQuizId(quizArray[0]?._id || "");
      setSelectQuiz(quizArray[0]?.title || "select quiz");
    }
  }, [quizArray]);

  useEffect(() => {
    if (selectQuizId !== "") {
      getSingleQuizParticipantsById(selectQuizId);
    }
  }, [selectQuizId]);

  return (
    <>
      <div className="m-3">
        <div className="row align-items-center">
          <div className="col-12 col-sm-6 col-md-6 col-lg-6 ">
            <motion.div
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ duration: 1 }}
            >
              <Menu shadow="md" width={250}>
                <Menu.Target>
                  <div className="cursor-pointer px-4 py-1 my-2 select-quiz d-flex justify-content-between align-items-center">
                    <p className="mb-0 text-capitalize selected-quiz-text">
                      {selectQuiz}
                    </p>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="23"
                      height="12"
                      fill="none"
                      viewBox="0 0 23 12"
                    >
                      <path
                        fill="#565656"
                        d="M11.5 12L22.325 0H.675L11.5 12z"
                      ></path>
                    </svg>
                  </div>
                </Menu.Target>

                <Menu.Dropdown>
                  {quizArray?.map((value: any, i: number) => {
                    return (
                      <Menu.Item
                        onClick={() => {
                          setSelectQuiz(value?.title);
                          setSelectQuizId(value?._id);
                        }}
                        key={i}
                        className="text-capitalize"
                      >
                        {value?.title}
                      </Menu.Item>
                    );
                  })}
                </Menu.Dropdown>
              </Menu>
            </motion.div>
          </div>
          {quizParticipants?.length < 1 ? null : (
            <motion.div
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ duration: 1 }}
              className="col-12 col-sm-6 col-md-6 col-lg-6 d-flex justify-content-end"
            >
              <svg
                onClick={() => setDisplay("row")}
                className="me-2 cursor-pointer"
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="20"
                fill="none"
                viewBox="0 0 31 22"
              >
                <path
                  stroke={display === "row" ? "#000" : "#B9B9B9"}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeOpacity="1"
                  strokeWidth="3"
                  d="M9.5 2.001H29M9.5 11.004H29M9.5 20.007H29M2 2.001h.015M2 11.004h.015M2 20.007h.015"
                ></path>
              </svg>
              <svg
                onClick={() => setDisplay("grid")}
                className="cursor-pointer"
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  fill={display === "grid" ? "#000" : "#B9B9B9"}
                  d="M0 0h6v6.002H0V0zM0 9.003h6v6.002H0V9.003zM6 18.006H0v6.002h6v-6.002zM9 0h6v6.002H9V0zM15 9.003H9v6.002h6V9.003zM9 18.006h6v6.002H9v-6.002zM24 0h-6v6.002h6V0zM18 9.003h6v6.002h-6V9.003zM24 18.006h-6v6.002h6v-6.002z"
                ></path>
              </svg>
            </motion.div>
          )}
        </div>

        {quizParticipants?.length < 1 ? (
          <div className="h-100 mt-5 pt-5 d-flex justify-content-center align-items-center">
            <div className="result-not-found">
              <p className="not-found-text mb-0">
                No se han encontrado resultados
              </p>
              <p className="not-found-paragraph">
                ¡Aún no hay participantes en este cuestionario!
              </p>
            </div>
          </div>
        ) : (
          <>
            {loading ? (
              <div
                style={{ margin: "4rem 0" }}
                className="d-flex justify-content-center align-items-center"
              >
                <Loader color="black" />
              </div>
            ) : (
              <>
                {display === "grid" ? (
                  <div className="row">
                    {quizParticipants.map((value: any, i: number) => (
                      <motion.div
                        initial={{ opacity: 0, y: 100 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        transition={{ duration: 1 }}
                        className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3 my-3"
                        key={i}
                      >
                        <DashboardLeaderCard
                          data={value}
                          quizId={selectQuizId}
                        />
                      </motion.div>
                    ))}
                  </div>
                ) : (
                  <div>
                    {quizParticipants?.map((value: any, i: number) => (
                      <motion.div
                        initial={{ opacity: 0, y: 100 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        transition={{ duration: 1 }}
                        key={i}
                      >
                        <DashboardLeaderCardList
                          data={value}
                          quizId={selectQuizId}
                        />
                      </motion.div>
                    ))}
                  </div>
                )}
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default LeaderboardManagement;
//  <>
//    {loading ? (
//      <div className=" h-100 d-flex justify-content-center align-items-center">
//        <Loader color="black" />
//      </div>
//    ) : (
//      <div className="m-3">
//        <div className="row align-items-center">
//          <div className="col-12 col-sm-6 col-md-6 col-lg-6 ">
//            <motion.div
//              initial={{ opacity: 0 }}
//              whileInView={{ opacity: 1 }}
//              transition={{ duration: 1 }}
//            >
//              <Menu shadow="md" width={250}>
//                <Menu.Target>
//                  <div className="cursor-pointer px-4 py-1 my-2 select-quiz d-flex justify-content-between align-items-center">
//                    <p className="mb-0 text-capitalize selected-quiz-text">
//                      {selectQuiz}
//                    </p>
//                    <svg
//                      xmlns="http://www.w3.org/2000/svg"
//                      width="23"
//                      height="12"
//                      fill="none"
//                      viewBox="0 0 23 12"
//                    >
//                      <path
//                        fill="#565656"
//                        d="M11.5 12L22.325 0H.675L11.5 12z"
//                      ></path>
//                    </svg>
//                  </div>
//                </Menu.Target>

//                <Menu.Dropdown>
//                  {quizArray?.map((value: any, i: number) => {
//                    return (
//                      <Menu.Item
//                        onClick={() => {
//                          setSelectQuiz(value?.title);
//                          setSelectQuizId(value?._id);
//                        }}
//                        key={i}
//                        className="text-capitalize"
//                      >
//                        {value?.title}
//                      </Menu.Item>
//                    );
//                  })}
//                </Menu.Dropdown>
//              </Menu>
//            </motion.div>
//          </div>
//          {quizParticipants?.length < 1 ? null : (
//            <motion.div
//              initial={{ opacity: 0 }}
//              whileInView={{ opacity: 1 }}
//              transition={{ duration: 1 }}
//              className="col-12 col-sm-6 col-md-6 col-lg-6 d-flex justify-content-end"
//            >
//              <svg
//                onClick={() => setDisplay("row")}
//                className="me-2 cursor-pointer"
//                xmlns="http://www.w3.org/2000/svg"
//                width="30"
//                height="20"
//                fill="none"
//                viewBox="0 0 31 22"
//              >
//                <path
//                  stroke={display === "row" ? "#000" : "#B9B9B9"}
//                  strokeLinecap="round"
//                  strokeLinejoin="round"
//                  strokeOpacity="1"
//                  strokeWidth="3"
//                  d="M9.5 2.001H29M9.5 11.004H29M9.5 20.007H29M2 2.001h.015M2 11.004h.015M2 20.007h.015"
//                ></path>
//              </svg>
//              <svg
//                onClick={() => setDisplay("grid")}
//                className="cursor-pointer"
//                xmlns="http://www.w3.org/2000/svg"
//                width="20"
//                height="20"
//                fill="none"
//                viewBox="0 0 24 24"
//              >
//                <path
//                  fill={display === "grid" ? "#000" : "#B9B9B9"}
//                  d="M0 0h6v6.002H0V0zM0 9.003h6v6.002H0V9.003zM6 18.006H0v6.002h6v-6.002zM9 0h6v6.002H9V0zM15 9.003H9v6.002h6V9.003zM9 18.006h6v6.002H9v-6.002zM24 0h-6v6.002h6V0zM18 9.003h6v6.002h-6V9.003zM24 18.006h-6v6.002h6v-6.002z"
//                ></path>
//              </svg>
//            </motion.div>
//          )}
//        </div>

//        {quizParticipants?.length < 1 ? (
//          <div className="h-100 mt-5 pt-5 d-flex justify-content-center align-items-center">
//            <div className="result-not-found">
//              <p className="not-found-text mb-0">
//                No se han encontrado resultados
//              </p>
//              <p className="not-found-paragraph">
//                ¡Aún no hay participantes en este cuestionario!
//              </p>
//            </div>
//          </div>
//        ) : (
//          <>
//            {display === "grid" ? (
//              <div className="row">
//                {quizParticipants.map((value: any, i: number) => (
//                  <motion.div
//                    initial={{ opacity: 0, y: 100 }}
//                    whileInView={{ opacity: 1, y: 0 }}
//                    transition={{ duration: 1 }}
//                    className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3 my-3"
//                    key={i}
//                  >
//                    <DashboardLeaderCard data={value} quizId={selectQuizId} />
//                  </motion.div>
//                ))}
//              </div>
//            ) : (
//              <div>
//                {quizParticipants.map((value: any, i: number) => (
//                  <motion.div
//                    initial={{ opacity: 0, y: 100 }}
//                    whileInView={{ opacity: 1, y: 0 }}
//                    transition={{ duration: 1 }}
//                    key={i}
//                  >
//                    <DashboardLeaderCardList
//                      data={value}
//                      quizId={selectQuizId}
//                    />
//                  </motion.div>
//                ))}
//              </div>
//            )}
//          </>
//        )}
//      </div>
//    )}
//  </>;
