import { create } from "zustand";
import custAxios, { attachToken } from "../../configs/axiosConfig";

interface IDashboardStore {
  stats: any;
  unChecked: any;
  quizRank: any;
  userCount: any;
  loading: boolean;
  dashboardStats: () => Promise<any>;
  dashboardUnChecked: () => Promise<any>;
  dashboardQuizRank: () => Promise<any>;
  userCountGraph: () => Promise<any>;
}

export const useDashboardStore = create<IDashboardStore>((set) => ({
  stats: {},
  unChecked: [],
  quizRank: [],
  userCount: [],
  loading: false,
  dashboardStats: async () => {
    try {
      set({
        loading: true,
      });

      attachToken();
      const response = await custAxios.get("/dashboard/stats");

      set({
        loading: false,
        stats: response?.data?.data,
      });
      return response.data;
    } catch (error) {
      set({
        loading: false,
      });
      console.error("Error fetching data:", error);
    }
  },
  dashboardUnChecked: async () => {
    try {
      set({
        loading: true,
      });

      attachToken();
      const response = await custAxios.get("/dashboard/unchecked-submissions");

      set({
        loading: false,
        unChecked: response?.data?.data?.uncheckedSubmissionsList,
      });
      return response.data;
    } catch (error) {
      set({
        loading: false,
      });
      console.error("Error fetching data:", error);
    }
  },
  dashboardQuizRank: async () => {
    try {
      set({
        loading: true,
      });

      attachToken();
      const response = await custAxios.get("/dashboard/quiz-ranks");

      set({
        loading: false,
        quizRank: response?.data?.data?.quizes,
      });
      return response.data;
    } catch (error) {
      set({
        loading: false,
      });
      console.error("Error fetching data:", error);
    }
  },
  userCountGraph: async () => {
    try {
      set({
        loading: true,
      });

      attachToken();
      const response = await custAxios.get("/dashboard/user-counts");

      set({
        loading: false,
        userCount: response?.data?.data?.userCounts,
      });
      return response.data;
    } catch (error) {
      set({
        loading: false,
      });
      console.error("Error fetching data:", error);
    }
  },
}));
